import { fromJS } from 'immutable';

export const initialState = fromJS({
  error: null,
  fetchingDateRangeData: false,
  fetchingClientEntities: true,
  clientEntities: null,
  campaign: {
    'fetchingSent': true,
    'fetchingOpened': true,
    'fetchingClicked': true,
    'fetchingConverted': true,
    'fetchingRevenue': true,
    'sent': null,
    'opened': null,
    'clicked': null,
    'converted': null,
    'revenue': null,
    'earnings': null,
    'fetchingPartnerOfferConverted': false,
    'partnerOfferConverted': null,
    'fetchingPerformanceMetricReport': false,
    'performanceMetricReport': null,
  },
  perkByEntities: {
    'fetchingViewed': true,
    'fetchingClicked': true,
    'fetchingConverted': true,
    'fetchingRevenue': true,
    'fetchingPartnerOfferClicked': true,
    'fetchingEarnings': true,
    'viewed': null,
    'clicked': null,
    'converted': null,
    'revenue': null,
    'earnings': null,
    'partnerOfferClicked': null,
    'fetchingPartnerOfferConverted': false,
    'partnerOfferConverted': null,
    'fetchingPerformanceMetricReport': false,
    'performanceMetricReport': null,
    'fetchingRecommendations': false,
    'recommendations': null,
    'selectedEntities': null,
  },
  ancillaryapi: {
    'fetchingViewed': true,
    'fetchingClicked': true,
    'fetchingConverted': true,
    'fetchingRevenue': true,
    'fetchingPartnerOfferClicked': true,
    'viewed': null,
    'clicked': null,
    'converted': null,
    'revenue': null,
    'earnings': null,
    'fetchingPartnerOfferConverted': false,
    'partnerOfferConverted': null,
    'fetchingPerformanceMetricReport': false,
    'performanceMetricReport': null,
  },
  universal: {
    'fetchingClicked': true,
    'fetchingConverted': true,
    'clicked': null,
    'converted': null,
    'earnings': null,
    'fetchingPartnerOfferConverted': true,
    'partnerOfferConverted': null,
    'fetchingPerformanceMetricReport': false,
    'performanceMetricReport': null,
  },
  unattributed: {
    'fetchingConverted': true,
    'converted': null,
    'earnings': null,
    'fetchingPartnerOfferConverted': true,
    'partnerOfferConverted': null,
    'fetchingPerformanceMetricReport': false,
    'performanceMetricReport': null,
  },
  perk: {
    'fetchingViewed': true,
    'fetchingClicked': true,
    'fetchingConverted': true,
    'fetchingRevenue': true,
    'fetchingPartnerOfferClicked': true,
    'fetchingEarnings': true,
    'viewed': null,
    'clicked': null,
    'converted': null,
    'earnings': null,
    'revenue': null,
    'partnerOfferClicked': null,
    'fetchingPartnerOfferConverted': false,
    'partnerOfferConverted': null,
    'fetchingPerformanceMetricReport': false,
    'performanceMetricReport': null,
    'fetchingRecommendations': false,
    'recommendations': null,
  },
});
