import * as types from './types';
import { fromJS } from 'immutable';
import { initialState } from './initialState';


const reducer = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_LP_TEMPLATES_API_CALL_REQUEST:
    return state.setIn(['fetching'], true)
      .setIn(['error'], null);
  case types.GET_LP_TEMPLATES_API_CALL_SUCCESS:
    const { data } = action;
    const total = data && data.length;
    return state.setIn(['fetching'], false)
      .setIn(['error'], false)
      .setIn(['templates', 'total'], total)
      .setIn(['templates', 'items'], fromJS(data));
  case types.GET_LP_TEMPLATES_API_CALL_FAILURE:
    return state.setIn(['fetching'], false)
      .setIn(['error'], fromJS(action.error));
  case types.DELETE_LP_TEMPLATE_API_CALL_REQUEST:
    return state.setIn(['fetching'], true);
  case types.DELETE_LP_TEMPLATE_API_CALL_SUCCESS:
    return state.updateIn(['templates', 'total'], total => total - 1)
      .updateIn(['templates', 'items'], items => {
        const clonedTemplates = items.toJS();
        const updatedTemplates = clonedTemplates.reduce((acc, ele) => {
          if (ele.slug !== action.templateSlug) {
            acc.push(ele);
          }
          return acc;
        }, []);
        return fromJS(updatedTemplates);
      })
      .setIn(['fetching'], false);
  case types.DELETE_LP_TEMPLATE_API_CALL_FAILURE:
    return state.setIn(['fetching'], false)
      .setIn(['deleteError'], action.error);

  case types.UPLOAD_LP_TEMPLATE_API_CALL_REQUEST:
    return state.setIn(['fetching'], true)
      .setIn(['createAndUpdateError'], null);
  case types.UPLOAD_LP_TEMPLATE_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['createAndUpdateError'], null);
  case types.UPLOAD_LP_TEMPLATE_API_CALL_FAILURE:
    return state.setIn(['fetching'], false)
      .setIn(['createAndUpdateError'], action.error);


  case types.CREATE_LP_TEMPLATE_API_CALL_REQUEST:
    return state.setIn(['fetching'], true);
  case types.CREATE_LP_TEMPLATE_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .updateIn(['templates', 'total'], total => total + 1);
  case types.CREATE_LP_TEMPLATE_API_CALL_FAILURE:
    return state.setIn(['fetching'], false)
      .setIn(['error'], action.error);

  default:
    return state;
  }
};

// Action Creators
export const getTemplatesRequest = () => ({ type: types.GET_LP_TEMPLATES_API_CALL_REQUEST });
export const uploadTemplateRequest =
  (data, templateData, callBack) => ({ type: types.UPLOAD_LP_TEMPLATE_API_CALL_REQUEST, data, templateData, callBack });
export const deleteTemplateRequest =
  (templateSlug) => ({ type: types.DELETE_LP_TEMPLATE_API_CALL_REQUEST, templateSlug });

export default reducer;
