import axios from 'axios';
import { takeEvery, call, put, select } from 'redux-saga/effects';
import * as types from './types';
import offerUrls from './offerUrls';
import { patchUpdateOfferRequest } from './reducer';

export function* createOfferWatcherSaga() {
  yield takeEvery(types.CREATE_OFFER_API_CALL_REQUEST, workerSaga);
}

export function postData(data, user) {

  const url = offerUrls('postData', user);

  return axios.post(url, data)
    .then(val => val)
    .catch(err => err.response);
}

export function* workerSaga(action) {
  try {
    const { data: createData, saveAndQuit } = action;
    const state = yield select();
    const user = state.login.toJS().user;
    const activeStep = state.offers.toJS().activeStep;

    const response = yield call(postData, createData, user);

    if (response && response.status >= 200 && response.status < 202) {
      const { data } = response;
      if (saveAndQuit) {
        // TODO can we do this in some better way ?
        data['saveAndQuit'] = true;
      }
      yield put({ type: types.CREATE_OFFER_API_CALL_SUCCESS, data });

      if (createData && createData.channels && createData.channels[0] && createData.channels[0].template) {
        yield put(patchUpdateOfferRequest({...createData, slug: data.slug},!saveAndQuit, saveAndQuit));
      } else {
        yield put({ type: types.UPDATE_ACTIVE_STEP, activeStep: activeStep + 1 });
        yield put({ type: types.UPDATE_STEPS, activeStep });
      }
    } else {
      yield put({ type: types.CREATE_OFFER_API_CALL_FAILURE, error: response && response.data });
    }
  } catch (error) {
    console.log('Error ', error);
    yield put({ type: types.CREATE_OFFER_API_CALL_FAILURE, 'error': error && error.data});
  }
}
