import React from 'react';
import { Form } from 'react-bootstrap';

import { checkAndReturnErrorMessage } from '../../common/utils';

import { Props } from './flowTypes';

const FormField = (props: Props) => {
  const {
    formClassName,
    label,
    controlId,
    fieldClassName,
    type,
    name,
    value,
    placeholder,
    onFieldsInput,
    onBlur,
    error,
    disabled,
    style,
    as,
    required,
    maxlength
  } = props;

  return (
    <Form.Group controlId={controlId}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        className={fieldClassName}
        style={style}
        required={required}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onFieldsInput}
        onBlur={onBlur || (() => null)}
        autoComplete="off"
        disabled={disabled}
        as={as}
        maxLength={maxlength}
        rows={3}
      />
      {error && (
        <span
          className={`${formClassName}-name-error`}
          style={{'color': 'red'}}
        >
          {checkAndReturnErrorMessage(error, name)}
        </span>
      )}
    </Form.Group>
  );
};

export default FormField;
