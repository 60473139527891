import { PRM_PRIVILEGE } from '../../common/constants';
import config from '../../config/app_config';
import { getClient } from '../../common/utils';

export default function (urlType, user) {
  const userPrivilege = user && user.privilege;
  const client = getClient();
  const appRootURl = config.API_ROOT_URL;
  switch (urlType) {
  case 'postData':
  case 'deleteOffer':
  case 'fetchData':
  case 'patchData':
    if (!userPrivilege) {
      return `${appRootURl}/v1/offer/${client}/offer/`;
    }
    return userPrivilege === PRM_PRIVILEGE ?
      `${appRootURl}/v1/offer/${client}/offer/` :
      `${appRootURl}/v1/offer/${client}/campaign/`;
  case 'playPauseOffer':
    return userPrivilege === PRM_PRIVILEGE ?
      `${appRootURl}/v1/offer/${client}/play-pause-offer/` :
      `${appRootURl}/v1/offer/${client}/play-pause-campaign/`;
  default:
    return undefined;
  }
}
