import { fromJS } from 'immutable';

export const initialPassword = {
  oldpassword: '',
  newpassword: '',
  confirmpassword: '',
};

const initialState = fromJS({
  loading: false,
  error: null,
  password: initialPassword,
  users: null
});

export default initialState;
