// @flow

import * as React from 'react';
import './Spinner.scss';
import LogoIcon from '../../images/logo.png';
type Props = {
  left: string,
  top: string,
  fontSize: string,
}
export const Spinner = (props: Props) => {
  const { left, top, fontSize } = props;
  const style = {
    left,
    top,
    fontSize
  };

  return (
    <div className="spinner-component" style={style}>
      <img
        alt="logoicon"
        src={LogoIcon}
        className="spinner-logo"
      />
    </div>
  );
};



export default Spinner;
