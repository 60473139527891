import axios from 'axios';
import { takeEvery, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { storeSession } from '../../common/utils';


export function* loginWatcherSaga() {
  yield takeEvery(types.LOGIN_API_CALL_REQUEST, workerSaga);
}

export function fetchData(user) {
  const url = `${config.API_ROOT_URL}/v1/auth/login/`;

  return axios.post(url, user)
    .then(val => {
      const { user, client } = val.data;
      const { id, ...information } = user;
      const dataToTrack = {
          userName: information.name,
          userEmail: information.email,
          client,
      }
      window.rudderanalytics.identify(id, dataToTrack);
      window.rudderanalytics.track("Client user signed in", dataToTrack);
      // we gonna use display_change_password field to figure out if user signed in for the first time.
        // it's the only field to rely on for now. Maybe we should add last_login: Date | null in the future
      if (user.display_change_password) {
          window.rudderanalytics.track("Client user signed up", dataToTrack);
      }
      return val;
    })
    .catch(err => console.log('login failed ', err));
}

export function* workerSaga(action) {
  try {
    const { user, history } = action;
    const response = yield call(fetchData, user);

    if (response && response.status === 200) {
      const { data } = response;
      yield put({
        type: types.LOGIN_API_CALL_SUCCESS,
        data: data.user
      });

      yield call(storeSession, response.data);
      yield call(history.push, '/dashboard');
    } else {
      yield put({ type: types.LOGIN_API_CALL_FAILURE, error: true});
    }
  } catch (error) {
    yield put({ type: types.LOGIN_API_CALL_FAILURE, error });
  }
}
