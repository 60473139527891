import axios from 'axios';
// import https from 'https';
import { takeEvery, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { getClient, nonCachedURL } from '../../common/utils';

export function* createLPTemplateWatcherSaga() {
  yield takeEvery(types.CREATE_LP_TEMPLATE_API_CALL_REQUEST, workerSaga);
}

export function postThumbnail(filename, presignedurl, htmlurl) {
  try {
    return axios.post(config.HTMLToImagEndPoint, {
      'presignedurl': presignedurl,
      'filename': filename,
      'htmlurl': htmlurl
    })
      .then(val => {
        return val;
      })
      .catch(error => {
        console.log(error);
      });
  } catch (err) {
    console.log('Error is ', err);
  }
}

export function createData(data) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/client/${client}/templates/`;

  return axios.post(url, data)
    .then(val => val)
    .catch(err => err.response);
}

export function patchData(data) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/client/${client}/templates/${data.slug}/`;

  const bodyData = {...data};
  delete bodyData.slug;

  return axios.patch(url, bodyData)
    .then(val => val)
    .catch(err => err.response);
}

export function* workerSaga(action) {
  try {
    const { data, callBack } = action;
    const templateData = {...data};
    const isEdit = templateData.isEdit;
    const thumbnailURL = templateData.thumbnail_url;
    const thumbnailKey = templateData.thumbnail_key;
    delete templateData.isEdit;
    delete templateData.thumbnail_key; // Patch Thumbnail URL after complettion of postThumbnail
    delete templateData.thumbnail_url; // Patch Thumbnail URL after complettion of postThumbnail

    let response;
    if (isEdit) {
      response = yield call(patchData, templateData);
    } else {
      response = yield call(createData, templateData);
    }

    if (response && response.status >= 200 && response.status < 202) {
      const respData = response.data;
      const targetHTMLURL = nonCachedURL(`${data.original_html_url || respData.original_html_url}`);
      const postThumbnailResponse = yield call(
        postThumbnail, respData.name, thumbnailURL, targetHTMLURL);

      if (postThumbnailResponse && postThumbnailResponse.status >= 200 && postThumbnailResponse.status < 202) {
        // Make Patch Call
        const thumbnailResponse = yield call(patchData, {
          slug: respData.slug,
          thumbnail_key: thumbnailKey
        });
        callBack(thumbnailResponse.data);
      }
      yield put({ type: types.CREATE_LP_TEMPLATE_API_CALL_SUCCESS, data });
    } else {
      yield put({ type: types.CREATE_LP_TEMPLATE_API_CALL_FAILURE, error: response && response.data });
    }
  } catch (error) {
    yield put({ type: types.CREATE_LP_TEMPLATE_API_CALL_FAILURE, 'error': error && error.data});
  }
}
