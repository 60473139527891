import axios from 'axios';
import { takeEvery, call, put, select } from 'redux-saga/effects';
import * as types from './types';
import offerUrls from './offerUrls';

export function* playPauseOfferWatcherSaga() {
  yield takeEvery(types.PLAY_PAUSE_OFFER_API_CALL_REQUEST, workerSaga);
}

export function fetchData(data, slug, user) {

  const url = `${offerUrls('playPauseOffer', user)}${slug}/`;

  return axios.patch(url, data)
    .then(val => val)
    .catch(err => err.response);
}

export function* workerSaga(action) {
  try {
    const { data, slug } = action;
    const state = yield select();
    const user = state.login.toJS().user;
    const response = yield call(fetchData, data, slug, user);

    if (response && response.status === 200) {
      const { data } = response;
      yield put({ type: types.PLAY_PAUSE_OFFER_API_CALL_SUCCESS, data, slug });

    } else {
      yield put({ type: types.PLAY_PAUSE_OFFER_API_CALL_FAILURE, error: response && response.data});
    }
  } catch (error) {
    yield put({ 'type': types.PLAY_PAUSE_OFFER_API_CALL_FAILURE, 'error': error && error.data });
  }
}
