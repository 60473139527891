import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';
import * as types from './types';
import config from '../../config/app_config';
import { SMALL_PAGINATION } from './../../common/constants';
import { getClient } from '../../common/utils';
import { ToastsStore } from 'react-toasts';
import { BM_DELETED_SUCCESSFULLY } from 'common/i18n';

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* deleteBuyinMomentWatcherSaga() {
  yield takeEvery(types.DELETE_BUYING_MOMENTS_API_CALL_REQUEST, workerSaga);
}

export function deleteBuyingMoment(id) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/offer/${client}/buying-moment/${id}/`;

  return axios.delete(url, {})
    .then(res => res)
    .catch(err => err.response);
}

// worker saga: makes the api call when watcher saga sees the action
export function* workerSaga(action) {

  try {
    const { id } = action;
    const response = yield call(deleteBuyingMoment, id);

    if (response && response.status === 204) {
      yield put({ type: types.DELETE_BUYING_MOMENTS_API_CALL_SUCCESS, id });

      ToastsStore.success(BM_DELETED_SUCCESSFULLY);

      yield put({ type: types.GET_BUYING_MOMENTS_API_CALL_REQUEST, page: 1, size: SMALL_PAGINATION });
    } else {
      yield put({ type: types.DELETE_BUYING_MOMENTS_API_CALL_FAILURE, error: true });
    }

  } catch (error) {
    yield put({ type: types.DELETE_BUYING_MOMENTS_API_CALL_FAILURE });
  }
}
