// @flow

import React, {useState} from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import TopHeader from '../../components/TopHeader';
import SideNav from '../../components/SideNav/SideNav';
import PrivatePage from '../../components/PrivatePage/PrivatePage';
import RightSideDrawer from '../../components/RightSideDrawer/RightSideDrawer';

import Login from '../Login/login';
import Terms from '../Terms';
import ClientPerkPage from '../ClientPerkPage';
import { updateSideDrawerState } from './reducer';
import { updateUser } from '../Login/reducer';
import { requestInterceptor, responseInterceptor } from '../../common/interceptors';

import { CreateBuyingMoments, CreateCustomerSegments, SendToTest } from './loadable';

import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './app.scss';
import {useRudderstack} from "../../common/hooks/useRudderstack";

const Routes = (props) => {
  useRudderstack();

  return <>
    <Switch>
    <Route path="/" exact={true} component={Login}/>
    <Route path="/login" exact={true} component={Login}/>
    <Route path="/forgot-password" exact={true} render={(props) => (
        <Login
            {...props}
            view={'forgot-password'}
        />
    )}/>
    <Route path="/reset-password/:token" exact={true} render={(props) => (
        <Login
            {...props}
            view={'reset-password'}
        />
    )}/>
    <Route path="/terms" exact={true} component={Terms} />
    <Route path="/perk-collections/:clientslug" exact={true} component={ClientPerkPage} />

    <MainApp {...props}/>
  </Switch>
    </>
}


type Props = {
  app: {
    sideDrawerState: boolean,
  },
  user: any,
  setUser: (data: any) => void,
}
class App extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    requestInterceptor();
    responseInterceptor();
  }

  render () {
    const { props } = this;
    return (
      <Router>
        <Routes {...props} />
      </Router>
    );
  }

  componentDidMount() {
    const { user, setUser } = this.props;

    const userData = localStorage.getItem('user');
    // Check if user privilege is defined or not
    if (userData) {
      const parsedUserData = userData && JSON.parse(userData);
      setUser({
        ...user,
        ...parsedUserData,
      });

    }
  }
}

const MainApp = (props) => {
  const [isNavbarExpanded, setNavbarExpanded] = useState(true);
  const PADDING = isNavbarExpanded ? '280px' : '64px';

  return (
    <div className="wrapper">
      <SideNav isNavbarExpanded={isNavbarExpanded} onToggleNavbar={() => setNavbarExpanded(!isNavbarExpanded)} />
      <ToastsContainer
        className='custom-toast'
        position={ToastsContainerPosition.TOP_LEFT}
        store={ToastsStore}
        lightBackground
      />
      <div id="content" style={{ paddingLeft: PADDING }}>
        <TopHeader />
        <Switch>
          <PrivatePage />
        </Switch>
      </div>
      {
        props.app.sideDrawerState &&
          <RightSideDrawer open={props.app.sideDrawerState}>
            {renderDrawerContent()}
          </RightSideDrawer>
      }
    </div>
  );
};


const renderDrawerContent = () => {
  const url = window.location.pathname;
  if (url.indexOf('/buying-moments') > -1) {
    return (<CreateBuyingMoments />);
  } else if (url.indexOf('/customer-segments') > -1) {
    return (<CreateCustomerSegments />);
  } else if (url === '/offers/create-offer') {
    return (<SendToTest />);
  }
};

///////////////////////////////////////////////////////////////////////
//  REDUX CONNECTION
///////////////////////////////////////////////////////////////////////
function mapStateToProps(state) {
  const { login, app } = state;
  const loginToJS = login.toJS();
  const appToJS = app.toJS();

  return {
    user: loginToJS.user,
    app: appToJS,
  };
}

export default connect(mapStateToProps, {
  updateSideDrawerState,
  setUser: updateUser,
})(App);
