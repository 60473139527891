const constants = { // eslint-disable-next-line
  EMAIL_REGEX: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  EXTRA_SMALL_PAGINATION: 5,
  SMALL_PAGINATION: 10,
  INFINITE: 1000,
  MOBILE_NO_REGEX: /^[+0-9\b]+$/,
  OFFER_VALUE_REGEX: /^[0-9]*\.?[0-9]*$/, // eslint-disable-next-line
  SMS_PREVIEW_HREF_REGEX: /((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g,
  DATE_FORMAT: 'MMM DD, YYYY',
  PRM_PRIVILEGE: 'PRM',
  DESCRIPTION_MAX_LENGTH: 250,
  NAME_MAX_LENGTH: 50,
  SMS_BODY_MAX_LENGTH: 320,
  API_DATE_FORMAT: 'MM-DD-YYYY',
  API_RES_DATE_FORMAT: 'YYYY-MM-DD',
  ANCILLARY_API_DOCUMENTATION_LINK:
    'https://docs.paylode.com/docs/pde-stoplight/paylode-openapi.yaml',
  DEMO_CLIENT_SLUG: 'demo',
  PAYLODE_DOMAIN: ['paylode', 'quovantis', 'pasha'],
  PREVIEW_CAMPAIGN: 'Preview Campaign',
  METRICS_API_DATE_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  API_DATE_FORMAT_IN_HOURS: 'YYYY-MM-DD H',
  // tableau dashboard names
  BOOST_PERFORMANCE_DASHBOARD: 'BOOST_PERFORMANCE'
};
module.exports = constants;
