import { fromJS } from 'immutable';

export const initialState = fromJS({
  error: null,
  fetching: false,
  adding: false,
  removing: false,
  selectedTheme: null,
  reArranging: false,
  selectedEntity: {
    index: -1,
    entity: null,
  },
  selectedEntityStyle: null,
  selectedEntityConfig: null,
  entities: null,
  offers: [],
  fonts: [],
  fontsName: [],
});
