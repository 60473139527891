import { takeEvery, call, put } from 'redux-saga/effects';

import { fetchMetricsData } from './service';
import * as types from './types';
import { ERROR_GET_METRICS } from '../../common/i18n';

export function* getMetricstWatcherSaga() {
  yield takeEvery(types.GET_METRICS_CALL_REQUEST, workerSaga);
}

export function* workerSaga({ userPrivilege, entityType, metricType, selectedDateRange, selectedEntities }) {
  try {
    const response = yield call(fetchMetricsData, userPrivilege, entityType, metricType, selectedDateRange,
      selectedEntities);

    if (response && response.status === 200) {
      const { data } = response;

      yield put({ type: types.GET_METRICS_CALL_SUCCESS, data, entityType, metricType});

    } else {
      yield put({ type: types.GET_METRICS_CALL_FAILURE, entityType, metricType, error: ERROR_GET_METRICS });
    }
  } catch (error) {
    yield put({ type: types.GET_METRICS_CALL_FAILURE, entityType, metricType, error });
  }
}
