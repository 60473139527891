import {
 PASSWORD_LENGTH_ERROR, MISMATCH_PASSWORD
} from '../../common/i18n';
import { PAYLODE_DOMAIN } from '../../common/constants'

export const isFormValid = (oldpassword, newpassword, confirmpassword) => {
  if (oldpassword && newpassword && confirmpassword) {
    return true;
  }
  return false;
};

export const isFormValidBeforeUpdate = (oldpassword, newpassword, confirmpassword) => {
  if (oldpassword && oldpassword.length < 6) {
    return {
      'valid': false,
      'error': {
        'old_password': [PASSWORD_LENGTH_ERROR]
      }
    };
  }
  if (newpassword && newpassword.length < 6) {
    return {
      'valid': false,
      'error': {
        'new_password': [PASSWORD_LENGTH_ERROR]
      }
    };
  }
  if (newpassword !== confirmpassword) {
    return {
      'valid': false,
      'error': {
        'confirm_password': [MISMATCH_PASSWORD],
      }
    };
  }
  return {
    'valid': true,
  };
};

// function to hide paylode users
export const hidePaylodeUsers = clientUserObj => {
    return !PAYLODE_DOMAIN.some(domain => clientUserObj.user?.email?.includes(domain))
}

