import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { getClient } from '../../common/utils';

export function* updatePerkPageStyleWatcherSaga() {
  yield takeLatest(types.UPDATE_PERK_PAGE_STYLE_REQUEST, workerSaga);
}

export function patchData(entitySlug, data) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/offer/${client}/entity/${entitySlug}/perkspagestyle/`;

  return axios.patch(url, data)
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga(action) {
  try {
    const { entitySlug, data, postRequest } = action;
    const response = yield call(patchData, entitySlug, data);

    if (response && response.status === 200) {
      postRequest();
    } else {
      yield put({ type: types.UPDATE_PERK_PAGE_STYLE_FAILURE, error: 'Failed to update perk page styles.'});
    }
  } catch (error) {
    yield put({ type: types.UPDATE_PERK_PAGE_STYLE_FAILURE, error });
  }
}

