import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';

export function* getAttributesWatcherSaga() {
  yield takeLatest(types.GET_ATTRIBUTES_API_CALL_REQUEST, workerSaga);
}

export function fetchData() {
  const url = `${config.API_ROOT_URL}/v1/utils/channel-fields/`;

  return axios.get(url, {})
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga() {
  try {
    const response = yield call(fetchData);

    if (response && response.status === 200) {
      const { data } = response;
      yield put({ type: types.GET_ATTRIBUTES_API_CALL_SUCCESS, data });

    } else {
      yield put({ type: types.GET_ATTRIBUTES_API_CALL_FAILURE, error: 'error occured during get attributes' });
    }
  } catch (error) {
    yield put({ type: types.GET_ATTRIBUTES_API_CALL_FAILURE, error });
  }
}
