import React from 'react';

import { Props } from './flowTypes';
import config from '../../config/app_config';

import './style.scss';

class ClientPerkPage extends React.PureComponent<Props> {
  render() {
    const {
      match: {
        params: {
          clientslug,
        }
      }
    } = this.props;

    return (
      <div
        id='paylode-root'
        data-slug={clientslug}
        style={{
          'margin': 'auto'
        }}
      >
      </div>
    );
  }

  componentDidMount() {
    const script = document.createElement('script');
    const link = document.createElement('link');

    script.id = 'paylode-perks-script';
    script.src = `${config.PERK_PAGE_ROOT_URL}/paylodePerks.js?v=" + Date.now() + "`;
    document.body.appendChild(script);

    link.id = 'paylode-perks-style';
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = `${config.PERK_PAGE_ROOT_URL}/paylodePerks.css?v=" + Date.now() + "`;
    document.body.appendChild(link);

    window.addEventListener('message', this.messageListener);
  }

  componentWillUnmount() {
    document.getElementById('paylode-perks-script').remove();
    document.getElementById('paylode-perks-style').remove();

    window.removeEventListener(this.messageListener);
  }

  ////////////////////////////
  ////// Event handler ///////
  ////////////////////////////
  messageListener = (event) => {
    // console.log('iframe message => ', event);
    if (event.data === 'getPaylodePerkHeight') {
      // Random count to check and update contentHeight upto 6 seconds
      const COUNT = 3;
      let index = 1;

      // Waiting till 6 seconds to check height was bad experience
      // Running check at every 2 second for better UX
      while (index <= COUNT) {
        setTimeout(() => {
          const contentHeight = window.document.body.scrollHeight;
          window.parent.postMessage(contentHeight, '*');
        }, 2000 * index);
        index += 1;
      }
    }
  }
}

export default ClientPerkPage;
