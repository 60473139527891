import axios from 'axios';
import { redirectToLogin, handleError, getToken } from '../common/utils';

export const requestInterceptor = () => {
  // Axios request interceptor
  axios.interceptors.request.use(config => {
    const { url } = config;
    if (
      url.indexOf('/login/') > -1 ||
      url.indexOf('/reset-password-token/') > -1 ||
      url.indexOf('/reset-password/') > -1
    ) {
      return config;
    }
    config.headers.Authorization = `Token ${getToken()}`;
    return config;
  });
};

export const responseInterceptor = () => {
  // Axios response interceptor
  axios.interceptors.response.use(
    response => response,
    error => {
      if ( error && error.response && error.response.status === 401) {
        const { url } = error.response.config;
        if (
          url.indexOf('/login/') === -1 &&
          url.indexOf('/reset-password/') === -1
        ) {
          redirectToLogin();
        }
      } else if (error && error.response && error.response.status >= 400 && error.response.status <= 404) {
        handleError(error.response.data);
      }
      return Promise.reject({...error});
    },
  );
};
