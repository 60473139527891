import { fromJS } from 'immutable';

export const initialState = fromJS({
  error: null,
  fetching: false,
  reArranging: false,
  apiDetails: {
    end_point: null,
    request_body: null,
    response_body: null,
    example_response_body: null,
  },
  apiKey: null,
  adding: false,
  removing: false,
  offers: []
});
