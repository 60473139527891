import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { getClient } from '../../common/utils';

export function* updatePerkPageConfigWatcherSaga() {
  yield takeLatest(types.UPDATE_PERK_PAGE_CONFIG_REQUEST, workerSaga);
}

export function patchData(entitySlug, data) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/client/${client}/entity/${entitySlug}/perkpageconfiguration/`;

  return axios.patch(url, data)
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga(action) {
  try {
    const { entitySlug, data, postRequest, getStyleRequest } = action;
    const response = yield call(patchData, entitySlug, data);

    if (response && response.status === 200) {
      yield put({ type: types.UPDATE_PERK_PAGE_CONFIG_SUCCESS });
      postRequest();
      if (getStyleRequest)
        yield put(getStyleRequest(entitySlug));
    } else {
      yield put({ type: types.UPDATE_PERK_PAGE_CONFIG_FAILURE, error: 'Failed to update the configuration.'});
    }
  } catch (error) {
    yield put({ type: types.UPDATE_PERK_PAGE_CONFIG_FAILURE, error });
  }
}
