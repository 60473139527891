import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';

import * as types from './types';
import config from '../../config/app_config';
import { getClient } from '../../common/utils';

export function* getAncillaryAPIDetailsWatcherSaga() {
  yield takeLatest(types.GET_ANCILLARY_API_DETAILS, workerSaga);
}

export function fetchData(searchParams) {
  const client = getClient();
  const apiType = 'perkservice';
  const url = `${config.API_ROOT_URL}/v1/client/${client}/fetch_client_api_details/${apiType}/`;

  const params = {
    ...searchParams,
  };

  return axios.get(url, { params })
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga() {
  const response = yield call(fetchData);
  if (response && response.status === 200) {
    const { data } = response;
    yield put({ type: types.GET_ANCILLARY_API_DETAILS_SUCCESS, data });
  } else {
    yield put({ type: types.GET_ANCILLARY_API_DETAILS_ERROR,
      error: 'Error occurred while getting Ancillary API Details'});
  }
}
