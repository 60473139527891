import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { ERROR_UPDATE_THEME } from '../../common/i18n';
import { getClient } from '../../common/utils';

export function* updateThemeWatcherSaga() {
  yield takeLatest(types.UPDATE_THEME_CALL_REQUEST, workerSaga);
}

export function fetchData(entitySlug, updatedData) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/offer/${client}/entity/${entitySlug}/perkspagestyle/`;

  return axios.patch(url, updatedData)
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga(action) {
  try {
    const { entitySlug, updatedData, postRequest } = action;
    const response = yield call(fetchData, entitySlug, updatedData);

    if (response && response.status === 200) {
      yield put({ type: types.UPDATE_THEME_CALL_SUCCESS, data: updatedData.perk_theme});
      yield put({ type: types.GET_PERKS_CALL_REQUEST, entitySlug });
      postRequest();
    } else {
      yield put({ type: types.UPDATE_THEME_CALL_FAILURE, error: ERROR_UPDATE_THEME});
    }
  } catch (error) {
    yield put({ type: types.UPDATE_THEME_CALL_FAILURE, error });
  }
}