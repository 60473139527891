export const UPDATE_USER = 'UPDATE_USER';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const LOGIN_API_CALL_REQUEST = 'LOGIN_API_CALL_REQUEST';
export const LOGIN_API_CALL_SUCCESS = 'LOGIN_API_CALL_SUCCESS';
export const LOGIN_API_CALL_FAILURE = 'LOGIN_API_CALL_FAILURE';

export const FORGOT_PWD_API_CALL_REQUEST = 'FORGOT_PWD_API_CALL_REQUEST';
export const FORGOT_PWD_API_CALL_SUCCESS = 'FORGOT_PWD_API_CALL_SUCCESS';
export const FORGOT_PWD_API_CALL_FAILURE = 'FORGOT_PWD_API_CALL_FAILURE';

export const RESET_PWD_API_CALL_REQUEST = 'RESET_PWD_API_CALL_REQUEST';
export const RESET_PWD_API_CALL_SUCCESS = 'RESET_PWD_API_CALL_SUCCESS';
export const RESET_PWD_API_CALL_FAILURE = 'RESET_PWD_API_CALL_FAILURE';

export const RESET_PWD_REQ_RESET = 'RESET_PWD_REQ_RESET';

export const REQUEST_DEMO_INVITATION = 'REQUEST_DEMO_INVITATION';

export const RESET_LOGIN_ERROR = 'RESET_LOGIN_ERROR';
