import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';

export function* getThemeWatcherSaga() {
  yield takeLatest(types.GET_THEME_CALL_REQUEST, workerSaga);
}

export function fetchData(entitySlug) {
  const url = `${config.PERK_PAGE_API_URL}/v1/perkpageconfiguration/?slug=${entitySlug}`;

  return fetch(url)
    .then(response => response.json())
    .catch(err => {
      console.log('Error fetching theme', err);
    });
}

export function* workerSaga(action) {
  try {
    const { entitySlug } = action;
    const response = yield call(fetchData, entitySlug);
    if (response) {
      const perkTheme = response.perk_theme;
      yield put({ type: types.GET_THEME_CALL_SUCCESS, data: perkTheme});
    } else {
      yield put({ type: types.GET_THEME_CALL_FAILURE});
    }
  } catch (error) {
    yield put({ type: types.GET_THEME_CALL_FAILURE, error });
  }
}
