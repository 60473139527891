import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';

import * as types from './types';
import config from './../config/app_config';
import { getClient } from './../common/utils';

export function* getOfferBrandsWatcherSaga() {
  yield takeLatest(types.GET_OFFER_BRANDS, workerSaga);
}

export function fetchData() {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/offer/${client}/brands/`;

  return axios.get(url, {})
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga() {
  const response = yield call(fetchData);

  if (response && response.status === 200) {
    const { data } = response;
    yield put({ type: types.GET_OFFER_BRANDS_SUCCESS, data });
  }
}
