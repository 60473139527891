import { NAVIGATION_PERMISSION_MAPPING } from './constants';
import store from '../../store';
import { getClient } from 'common/utils';
import { DEMO_CLIENT_SLUG } from 'common/constants';
// function to check if user has permission to do a navigation
export const checkNavigationPermission = (item) => {
  let navigation = localStorage.getItem('navigation');
  navigation = navigation && JSON.parse(navigation);
  if (navigation) {
    if (navigation.length === 0)
      return true;
    return navigation.some(navItem => NAVIGATION_PERMISSION_MAPPING[item].includes(navItem));
  } else {
    return true;
  }
};

// function to check if client has permission to view conversion
export const checkPermission = (configuration_name) => {
  let appState = store.getState().app.toJS();
  let appConfiguration = appState.appConfiguration?.configuration;
  if (appConfiguration) {
    return appConfiguration[configuration_name];
  } else {
    return false;
  }
};


export const checkConversionPermission = () => {
  return checkPermission('show_conversion');
};

export const checkBoostMetricsPermission = () => {
  return checkPermission('is_boost_metrics_enabled')
}

export const checkRebatePermission = () => {
  return checkPermission('show_rebates');
};

export const checkEarningPermission = () => {
  return checkPermission('show_earnings');
};

export const checkPerformanceReportPermission = () => {
  return checkPermission('is_performance_report_enabled');
}

export const checkEarningPermissionAndDemoClient = () => {
  // If demo then do not show earning legend in dashbaord
  const client = getClient();
  if (client === DEMO_CLIENT_SLUG) {
    return false;
  }
  return checkPermission('show_earnings');
};
