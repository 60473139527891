import { fromJS } from 'immutable';
import { cloneDeep } from '../../common/utils';
import { TEMPLATE_TYPE, STEP1, SPECIFIC_DATE } from './constants';

export const defaultOffer = {
  name: '',
  value: '',
  unit: 'constant',
  description: '',
  isOfferTimeLimited: false,
  start_date: '',
  end_date: '',
  consumer_segment: '',
  current_step: STEP1,
  slug: '',
  landing_page: {
    name: '',
    html_key: '',
    json_key: '',
    thumbnail_key: '',
    template_type: TEMPLATE_TYPE
  },
  offers: [],
  channels: [
    {
      name: 'SMS',
      reminder: 0,
      displayContentArea: false,
      content: {
        body: '',
      }
    },
  ],
  buying_moment: [{
    reminder: 0,
    duration: 0,
    duration_unit: 'Week',
    occurence: 'before',
    date: '',
    day: '',
    event: '',
  }],
  metrics: {
    'sent': null,
    'clicked': null,
    'converted': null,
  },
  revenue: null,
  locations: [],
  industries: [],
  kpis: [],
  selectedChannel: 'SMS',
  smsBody: '',
  selectedBuyingMoment: SPECIFIC_DATE,
  isPublished: false,
  saveAndQuit: false,
  lastSavedOfferValue: '',
};

export const defaultSteps = [
  { title: 'Which Offer?', completed: false },
  { title: 'Who will Receive?', completed: false },
  { title: 'When & What to Send?', completed: false },
  // { title: "Where to Send?", completed: false },
  // { title: "When to Send?", completed: false },
  { title: 'Review & Publish', completed: false }
];

export const initialState = fromJS({
  createAndUpdateError: null,
  deleteError: false,
  fetching: false,
  error: false,
  offers: {
    items: [],
    total: 0,
    pages: {},
    activePage: 1,
    countFetching: false,
    searchResultPages: {},
  },
  selectedOffer: cloneDeep(defaultOffer),
  offerPreview: null,
  steps: cloneDeep(defaultSteps),
  activeStep: 1,
  templates: {
    items: [],
    total: 0,
  },
  channels: [],
  customerCount: 0,
  attributes: {
    items: [],
    total: 0
  }
});
