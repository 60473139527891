import React from 'react';
import {Tooltip} from 'primereact/tooltip';

import {Props} from './flowTypes';
import './style.scss';

const LabelWithTooltip = (props: Props) => {
  const {
    tooltipText,
    data,
    tooltipLabel,
    tooltipPosition,
    className,
  } = props;

  return (
    <div className="label-with-tooltip">
      <Tooltip
        className="custom-tooltip"
        target={`.tooltipClass${className ? `-${className}` : ''}`} position={tooltipPosition}/>
      <div className="label-with-tooltip-value">
        {data && <div style={{paddingBottom: '8px'}}>{data}</div>}
        <span
          className={`tooltipClass${className ? `-${className}` : ''} ${className}`}
          data-pr-tooltip={tooltipText}
        > {tooltipLabel} </span>
      </div>
    </div>
  );
};

export default LabelWithTooltip;
