import Logout from '../Logout/logout';
import {
  Dashboard,
  BuyingMoments,
  CustomerSegments,
  Offers,
  CreateOffer,
  Settings,
  LandingPages,
  OfferDescription,
  Marketplace,
  MarketplaceDescription,
  Perks,
  AncillaryAPIDetails,
} from './loadable';
import {
  ANCILLARY_API,
  BUYING_MOMENT, CAMPAIGN_DESCRIPTION, CAMPAIGNS,
  CREATE_CAMPAIGN,
  CUSTOMER_SEGMENT,
  DASHBOARD, LOGOUT,
  MARKETPLACE, OFFER_DESCRIPTION, OFFERS,
  PERKS, SETTINGS
} from '../../common/i18n';

export const PRIVATE_ROUTES_ARM = [
  { header: 'Dashboard', subtitle: 'Track and optimize performance', path: '/dashboard', component: Dashboard, label: DASHBOARD },
  { header: 'Buying Moments', subtitle: 'Buying Moments enable you to send offers to customers at the perfect moment in their journey. You can define them here.', path: '/buying-moments', component: BuyingMoments, label: BUYING_MOMENT },
  { header: 'Marketplace', subtitle: 'Browse the Paylode marketplace of perks', path: '/marketplace', component: Marketplace, label: MARKETPLACE },
  { header: 'All Customer Segments', subtitle: 'Paylode enables you to specify which groups of customers should receive specific offers. You can fine-tune them here.', path: '/customer-segments', component: CustomerSegments, label: CUSTOMER_SEGMENT },
  { header: 'Create Campaign', path: '/offers/create-offer', component: CreateOffer, label: CREATE_CAMPAIGN },
  { header: 'Campaign Description', path: '/offers/:offerid', component: OfferDescription,
    label: CAMPAIGN_DESCRIPTION },
  { header: 'Offer Description', path: '/marketplace/:offerid', component: MarketplaceDescription,
    label: OFFER_DESCRIPTION },
  { header: 'All Campaigns', subtitle: 'See and manage campaigns', path: '/offers', component: Offers, label: CAMPAIGNS },
  { header: 'Offers', path: '/landing-pages', component: LandingPages, label: OFFERS },
  { header: 'Perk Collections', subtitle: 'See, manage, and get integration details for perk collections', path: '/perk-collections', component: Perks, label: PERKS },
  { header: 'Ancillary API', subtitle: 'Manage perks and reference Ancillary API implementation details', path: '/ancillary-api-details', component: AncillaryAPIDetails, label: ANCILLARY_API },
  { header: 'Settings', subtitle: 'Manage your profile and account settings', path: '/settings', component: Settings, label: SETTINGS },
  { header: '', path: '/logout', component: Logout, label: LOGOUT },
];

export const PRIVATE_ROUTES_PRM = [
  { header: 'All Offers', path: '/offers', component: Offers, icon: '', label: OFFERS },
  { header: 'Create Offer', path: '/offers/create-offer', component: CreateOffer, label: CREATE_CAMPAIGN },
  { header: 'Offer Description', path: '/offers/:offerid', component: OfferDescription, label: CAMPAIGN_DESCRIPTION },
  { header: 'Performance', path: '/performance', component: Dashboard, icon: '', label: DASHBOARD },
  { header: 'Perk Collections', subtitle: 'See, manage, and get integration details for perk collections', path: '/perk-collections', component: Perks, label: PERKS },
  { header: 'Settings', path: '/settings', component: Settings, label: SETTINGS },
  { header: '', path: '/logout', component: Logout, label: LOGOUT },
];
