import React from 'react';
import { connect } from 'react-redux';

import { Props, State } from './flowType';
import {HELP_CENTER} from '../../common/i18n';
import './Style.scss';

class TopHeader extends React.PureComponent<Props, State> {
  constructor (props) {
    super(props);
  }

  render () {
    const { currentHeader, currentSubtitle } = this.props;
    return (
        <div className="top-header">
          <div
              className="top-header-title"
          >
            <div className="top-header-pagetitle">
              {currentHeader}
            </div>
            <div className="top-header-right">
              <a
                  className="help-center"
                  href="https://paylode.atlassian.net/wiki/spaces/PAKB/overview"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                {HELP_CENTER}
              </a>
            </div>
          </div>
          {currentSubtitle && <div className="top-header-subtitle">
            {currentSubtitle}
          </div>}
        </div>

    );
  }
}

function mapStateToProps(state) {
  const { app } = state;
  const appToJS = app.toJS();
  return {
    currentHeader: appToJS.currentHeader,
    currentSubtitle: appToJS.currentSubtitle,
  };
}

export default connect(mapStateToProps, null)(TopHeader);
