import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { getClient } from '../../common/utils';

export function* getCustomerCountPerChannelWatcherSaga() {
  yield takeLatest(types.GET_CUSTOMER_COUNT_PER_CHANNEL_API_CALL_REQUEST, workerSaga);
}

export function fetchData(slug, channel) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/consumer/${client}/consumer-count/${slug}/${channel}/`;

  return axios.get(url)
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga({slug, channel}) {

  try {
    const response = yield call(fetchData, slug, channel);

    if (response && response.status === 200) {
      const { data } = response;

      yield put({ type: types.GET_CUSTOMER_COUNT_PER_CHANNEL_API_CALL_SUCCESS, data });
    } else {
      yield put({ type: types.GET_CUSTOMER_COUNT_PER_CHANNEL_API_CALL_FAILURE, error: 'error occured during get customer segments count' });
    }
  } catch (error) {
    yield put({ type: types.GET_CUSTOMER_COUNT_PER_CHANNEL_API_CALL_FAILURE, error });
  }
}
