import { fromJS } from 'immutable';

export const initialState = fromJS({
  createAndUpdateError: null,
  deleteError: null,
  error: null,
  fetching: false,
  templates: {
    items: [],
    total: 0,
  },
});
