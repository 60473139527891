import axios from 'axios';
import { takeEvery, call, put } from 'redux-saga/effects';
import * as types from '../types';
import config from '../../../config/app_config';


export function* forgotPwdRequestWatcherSaga() {
  yield takeEvery(types.FORGOT_PWD_API_CALL_REQUEST, workerSaga);
}

export function postData(user) {
  const url = `${config.API_ROOT_URL}/v1/reset-password-token/`;

  return axios.post(url, user)
    .then(val => val)
    .catch(err => err.response);
}

export function* workerSaga(action) {
  try {
    const { user } = action;
    const response = yield call(postData, {
      'email': user.email,
    });

    if (response && response.status === 200) {
      yield put({
        type: types.FORGOT_PWD_API_CALL_SUCCESS,
      });

    } else {
      yield put({ type: types.FORGOT_PWD_API_CALL_FAILURE, error: response.data });
    }
  } catch (error) {
    yield put({ type: types.FORGOT_PWD_API_CALL_FAILURE, error });
  }
}
