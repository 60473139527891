import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';
import { ToastsStore } from 'react-toasts';

import * as types from './types';
import config from '../../config/app_config';
import { getClient } from '../../common/utils';
import { CS_DELETED_SUCCESSFULLY } from '../../common/i18n';

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* deleteCustomerSegmentWatcherSaga() {
  yield takeEvery(types.DELETE_CUSTOMER_SEGMENTS_API_CALL_REQUEST, workerSaga);
}

export function deleteCustomerSegment(slug) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/consumer/${client}/consumer-segment/${slug}/`;

  return axios.delete(url, {})
    .then(res => res)
    .catch(err => err.response);
}

// worker saga: makes the api call when watcher saga sees the action
export function* workerSaga(action) {
  try {
    const { slug } = action;
    const response = yield call(deleteCustomerSegment, slug);

    if (response && response.status === 204) {
      yield put({ type: types.DELETE_CUSTOMER_SEGMENTS_API_CALL_SUCCESS, slug });
      ToastsStore.success(CS_DELETED_SUCCESSFULLY);
      yield put({ type: types.GET_CUSTOMER_SEGMENTS_API_CALL_REQUEST, page: 1 });
    } else {
      yield put({ type: types.DELETE_CUSTOMER_SEGMENTS_API_CALL_FAILURE, error: true });
    }

  } catch (error) {
    yield put({ type: types.DELETE_CUSTOMER_SEGMENTS_API_CALL_FAILURE });
  }
}
