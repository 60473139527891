/**
 * Create buying moment constants
 */
export const ATTRIBUTE_TYPES = ['String', 'Number', 'Date'];

export const SPECIFIC_DAY = 'On Customer Dates';
export const SPECIFIC_EVENT = 'On Actions';

export const BUYING_MOMENT_OPTIONS = [
  SPECIFIC_DAY,
  SPECIFIC_EVENT,
];
