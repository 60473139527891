import { checkNavigationPermission } from '../../components/SideNav/helpers';
import { PRIVATE_ROUTES_ARM, PRIVATE_ROUTES_PRM } from './routes';

// get routes for ARM's after checking navigation permission
export function getARMPrivateRoutes() {
  const routes = [];
  PRIVATE_ROUTES_ARM.forEach(route => {
    if (checkNavigationPermission(route.label))
      routes.push(route);
  });
  return routes;
}

// get routes for PRM's (now all routes are open for PRM)
export function getPRMPrivateRoutes() {
  return PRIVATE_ROUTES_PRM;
}
