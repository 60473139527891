import React from 'react';
export const ERROR_UPDATE_OFFER_VALUE_NONZERO_TO_ZERO = 'Offer value is required';
export const ERROR_UPDATE_OFFER_DURATION = 'Offer start and end date is required for limited offer.';
export const ERROR_UPDATE_OFFER_DURATION_VALIDATION = 'Offer end date should be greater than or equal to start date';

export const LANDING_PAGE_TITLE = 'Landing Pages';
export const LADNING_PAGE_SEARCH_PLACEHOLDER= 'Search by landingpage name..';
export const LANDING_PAGE_SUBTITLE = 'Choose from 2 templates or create your own landing page';
export const CREATE_LANDING_PAGE_BUTTON_LABEL = '+ Create Landing Page';
export const ERROR_GET_TEMPLATE = 'Error on fetching templates';
export const ERROR_GET_MP_OFFERS = 'Error on fetching offers';
export const ERROR_GET_MP_OFFERS_LOCATIONS = 'Error on fetching offers locations';
export const ERROR_DELETE_TEMPLATE = 'Error on deleting template';

export const ERROR_NAME_REQUIRED = 'Name is required';
export const ERROR_URL_REQUIRED = 'URL is required';
export const ERROR_LANDING_PAGE_REQUIRED = 'Landing page is required';

export const ERROR_GET_METRICS = 'Metrics call failed';
export const ERROR_GET_REVENUE_FAILED = 'Revenue call failed';

export const OFFER_DELETED_SUCCESSFULLY = 'Offer deleted successfully';
export const CAMPAIGN_DELETED_SUCCESSFULLY = 'Campaign deleted successfully';
export const BM_DELETED_SUCCESSFULLY = 'Buying moment deleted successfully';
export const CS_DELETED_SUCCESSFULLY = 'Customer segment deleted successfully';
export const LP_DELETED_SUCCESSFULLY = 'Offer deleted successfully';

export const INVALID_MOMENT_VALUE = 'Select a valid moment';
export const MOMENTS_NOT_IN_ORDER = 'Moments are not in chronological order.';

export const OFFER_VALUE_TOOLTIP = 'Value that this offer generates for your company. Example - CPA fee, Revenue Share';

// Offers
export const OFFER_SEARCH_PLACEHOLDER= 'Search by offer name';
export const OFFER_SUBTITLE = 'Choose from 2 templates or create your own offer';
export const ADD_OFFER_URL = 'Add Offer URL';
export const EMPTY_OFFER = 'Start Creating Your Offer';
export const OFFER_NAME = 'Offer Name';
export const EMPTY_OFFER_DESCRIPTION = 'Use this page to setup your offer. You\'ll be able to choose who recieve them, when and through which channels to drive maximum conversion.';
export const CREATE_OFFER_TEXT = '+ Create Offer';
export const CREATE_OFFER_LABEL = 'Create Offer';
export const EMPTY_OFFER_DESC_PRM = 'From here you can start creating your Offer. Few more lines about offer creation flow';
export const EMPTY_OFFER_PRM = 'You are in Offers Creation Section';
export const OFFER_TEMPLATE = 'Offer Template';
export const SELECT_OFFER_TEMPLATE = 'Select offer template';
export const DELETE_OFFER = 'Delete Offer';
export const DELETE_OFFER_ALERT = 'Are you sure you want to delete offer?';
export const OFFER_TEMPLATE_REQUIRED = 'Offer template is required';
export const PAUSE_OFFER = 'Pause Offer?';
export const DESCRIBE_OFFER = 'Describe your offer here';
export const ADD_OFFER_DETAIL = 'Add Offer Detail';
export const NEXT = 'Next';
export const OFFER_NAME_PLACEHOLDER = 'Eg. Lemonade Insurance Offer';
export const DOLLAR_VALUE = 'Dollar Value ($)';
export const PERCENTAGE_VALUE = 'Percentage of Revenue (%)';
export const PERCENT_PLACEHOLDER = 'Eg. 10%';
export const DOLLAR_PLACEHOLDER = 'Eg. $10.00';
export const PREVIEW = 'Preview';
export const REMOVE = 'Remove';
export const PUBLISH = 'Publish';
export const SAVE_DRAFT = 'Save as Draft';
export const CANCEL = 'Cancel';
export const DESCRIPTION_PLACEHOLDER = 'Eg. This offer will get 25% discount to members when they sign up';
export const WHAT_TO_SEND = 'What to Send?';
export const DELETE_CAMPAIGN = 'Delete Campaign';
export const DELETE_CAMPAIGN_ALERT = 'Are you sure you want to delete campaign?';

export const CHOOSE_OFFERS = 'Choose Offer(s)';
export const STEP1_CAMPAIGN_LABEL_HELP_TEXT = 'Name your campaign and select the offer(s) to be sent . You will be able to order how these offers are presented in step 3.';


// Campaign
export const SEARCH_CAMPAIGN = 'Search by campaign name';
export const CAMPAIGN_NAME_PLACEHOLDER = 'Campaign Name';
export const CAMPAIGN_NAME_HELP_TEXT = 'This name is for your use only and will not be shown to recipients.';
export const PUBLISH_CAMPAIGN = 'Publish Campaign';
export const REVIEW_CAMPAIGN = 'Review & Publish Campaign';
export const CAMPAIGN_MESSAGE = 'Create Campaign Message';
export const CREATE_CAMPAIGN_TEXT = '+ Create Campaign';
export const CHANNEL_CAMPAIGN_REVIEW_TITLE = 'Channel & Campaign Message';
export const SELECT_BUYING_MOMENT = 'Select Your Buying Moment And Add Messages';
export const SELECT_BUYING_MOMENT_TYPE = 'When should this offer be sent?';
export const WHEN_TO_SEND = 'When to send message';
export const FIRST_REMINDER_LINK_NOT_CLICKED = 'If link not clicked, send first reminder';
export const SECOND_REMINDER_LINK_NOT_CLICKED = 'If link not clicked, send last reminder';
export const ADD_MESSAGE_TO_SEND = '+ Add Message to send';
export const ADD_SMS_MESSAGE = 'Add SMS Message';
export const REMINDER_NOTE = '*Reminders are only sent if the customer hasn\'t clicked the link in previous messages.';
export const ADD_REMINDER = 'Add Reminder';
export const DONE = 'Done';
export const PAYLODE_MARKETPLACE = 'Paylode Marketplace';
export const MY_OFFERS = 'My Offers';
export const NAME_CAMPAIGN = 'Name this campaign';
export const OFFERS_SELECTED = 'offer(s) selected';


// Customer Segment
export const CustomerSegmentNotSelectedError = 'Enter value for selected filter';
export const CustomerSegmentFilterInvalidRange = 'Invalid range!';
export const SAVE_SEGMENT = 'Save Segment';
export const CREATE_SEGMENT = 'Create Customer Segment';
export const NAME = 'Name';
export const CUSTOMERS = 'Customers';
export const USED_IN = 'Used in';
export const FILTERS = 'Filters';
export const EMPTY_CUSTOMER_SEGMENT = 'Paylode enables you to specify which groups of customers should recieve specific offers. You can fine tune them here.';
export const UPLOAD_DATA_FILE = 'Upload Data File';
export const UPLOAD_DATA = 'Upload Data';


// Labels
export const TotalRevenue = 'Total Revenue';
export const SMS = 'SMS';
export const EMAIL = 'Email';
export const EMAIL_HEADER = 'EMAIL';
export const RequestDemo = 'Book Demo';

// Buying Moment
export const CustomerDate = 'customerdate';
export const CustomerAction = 'customeraction';
export const PlaceholderDateType = 'Eg. Move in day, Move out day';
export const PlaceholderActionType = 'Eg. Application submitted, Ride completed, Check-In';
export const InvalidType = 'Select valid buying moment type';

// Market Place
export const CreateCampaignLabel = 'Create Campaign';
export const ViewOfferDetailsLabel = 'View Details';
export const MarketPlaceLabel = 'marketplace';

//Sidenav
export const DASHBOARD = 'Dashboard';
export const OFFERS = 'Offers';
export const CUSTOMER_SEGMENT = 'Customer segments';
export const BUYING_MOMENT = 'Buying moments';
export const CAMPAIGNS = 'Campaigns';
export const CREATE_CAMPAIGN = 'Create Campaign';
export const CAMPAIGN_DESCRIPTION = 'Campaign Description';
export const OFFER_DESCRIPTION = 'Offer Description';
export const MARKETPLACE = 'Marketplace';
export const PERKS = 'Perk collections';
export const BOOST_CAMPAIGNS = 'Boost campaigns';
export const ANCILLARY_API = 'Ancillary API';
export const SETTINGS = 'Settings';
export const ADMIN = 'Admin';
export const SUPPORT = 'Support';
export const LOGOUT = 'Logout';
export const PERFORMANCE = 'Performance';

// T And C
export const T_AND_C = 'Terms and Conditions for Partner Offer Usage';

// Settings
export const FIRST_NAME = 'First Name';
export const LAST_NAME = 'Last Name';
export const OLD_PASSWORD = 'Old Password';
export const NEW_PASSWORD = 'New Password';
export const CONFIRM_PASSWORD = 'Confirm Password';
export const UPDATE = 'Update';
export const ENTER_PASSWORD = 'Enter Password';
export const PASSWORD_LENGTH_ERROR = 'Password should have atleast 6 characters.';
export const MISMATCH_PASSWORD = 'Confirm password does not match with new password.';
export const PASSWORD_CHANGED = 'Password changed successfully.';
export const ADD_TEAM_MEMBER = 'Add Team Member';
export const TEAM = 'Team';


// Forgot Password
export const FORGOT_PASSWORD = 'Forgot Password?';
export const REQUEST_RESET_LINK = 'Request Reset Link';
export const REQUESTING = 'Requesting...';
export const LOGIN = 'Login';
export const WELCOME_TO_PAYLODE = 'Welcome to Paylode';
export const CONFIRM = 'Confirm';
export const EXPIRED_TOKEN = 'Password reset token is invalid or expired.';
export const RESET_FAILED = 'Failed';
export const RESET_PASSED = 'Passed';
export const FORGOT_PASSWORD_CLASS = 'forgot-password';

export const SOMETHING_WRONG = 'Something went wrong';

// Ancillary API Details Page
export const INTRODUCTION = 'Introduction';
export const IMPLEMENTATION_DETAILS = 'Implementation Details';
export const ADDITIONAL_NOTES = 'Additional Notes';
export const API_INTRODUCTION = 'Paylode Perks API provides you with the raw data behind our Partner offers.';
export const API_USAGE = 'You can wrap this data in your own HTML and present it wherever you see fit' +
  '(within a checkout flow, in carousels, etc).';
export const ADDITIONAL_NOTES_POINTS = [
  '1. Friendly reminder to use the “unique_url” in all links to this partner –– this is the link that attributes ' +
  'conversions to you.',
  '2. Kickback_value is the amount of money you receive from the Partner when a purchase happens.',
  '3. Kickback_value_unit refers to whether the kickback_value is a dollar amount (‘constant’) or a % of the ' +
  'purchase amount (‘variable’).',
  '4. The optional body parameter in the API is a placeholder for future functionality where clients can send ' +
  'information to Paylode, and Paylode can return offers related to that information.',
  'For example, you’ll be able to specify a specific city, and we can return all offers available in that city.'
];
export const COPIED = 'Copied to clipboard';

// Perk Details Page
export const JAVASCRIPT = 'JavaScript';
export const IFRAME = 'iFrame';
export const REACT = 'React';
export const API = "API";
export const QR_CODE = "QR code";
export const HOSTED_PAGE = 'Hosted page'
export const PERK_PAGE_INTRODUCTION = 'The Paylode Perks Page lets you showcase a collection Partner offers in an ' +
  'organized way.';

export const PERK_PAGE_USAGE = 'You can host this Perks Page code snippet on any web page you’d like –– ' +
  'it is mobile and web responsive.';

export const PERK_PAGE_PREVIEW = 'Perk Page Preview';
export const SELECT_PERK_PAGE = 'Select a Perks Page';

// Navigation permissions
export const CAMPAIGN_PERMISSION = 'campaign';
export const PERKS_PERMISSION = 'perkpage';
export const ANCILLARY_PERMISSION = 'perkservice';
export const ALL_PERMISSION = [CAMPAIGN_PERMISSION, PERKS_PERMISSION, ANCILLARY_PERMISSION];

// Perks page nav tab
export const EDIT_PERKS_KEY = 'editPerks';
export const VIEW_CODE_KEY = 'viewCode';
export const EDIT_PERKS = 'Edit Perks';
export const VIEW_CODE = 'View Code';

// Perks themes
export const CLASSIC = 'Classic';
export const CLASSIC_VALUE = 'classic';
export const MODERN = 'Modern';
export const MODERN_VALUE = 'modern';
export const ERROR_UPDATE_THEME = 'Error updating themes';
export const ERROR_GET_THEME = 'Error fetching themes';
export const THEME_MODAL_HEADER = 'Switch Theme';

// Perk Page Form
export const PERK_PAGE_ATTRIBUTES = 'Page Attributes';
export const PERK_PAGE_OFFER_CARD_ATTRIBUTES = 'Offer Card Attributes';
export const PERK_PAGE_FONTS = 'Fonts';
export const SUBSCRIBE_DESCRIPTION_PLACEHOLDER =
  'Enter your contact information to receive new offers via text or email.';
export const SUBSCRIBE_TITLE_PLACEHOLDER = 'Get offers via text or email';
export const SUBSCRIBE_DESCRIPTION_LABEL = 'Subscribe to Offers Description';
export const SUBSCRIBE_TITLE_LABEL = 'Subscribe to Offers Title';
export const SUBSCRIBE_CTA_PLACEHOLDER = 'Subscribe to Offers';
export const SUBSCRIBE_CTA_LABEL = 'Subscribe to Offers CTA';
export const SURVEY_DESCRIPTION_LABEL = 'Quality Rating Description';
export const SURVEY_DESCRIPTION_PLACEHOLDER = 'Enter description';
export const REQUEST_PERK_PLACEHOLDER = 'Brands, services, etc.';
export const REQUEST_PERK_LABEL = 'Request a Perk Placeholder';
export const REQUEST_PERK_DESCRIPTION_PLACEHOLDER = 'Enter description';
export const REQUEST_PERK_DESCRIPTION_LABEL = 'Request a Perk Description';
export const SEARCH_PLACEHOLDER = 'Brands, services, etc.';
export const SEARCH_LABEL = 'Search Bar Placeholder';
export const PAGE_TITLE_LABEL = 'Page Title';
export const PAGE_DESCRIPTION_LABEL = 'Page Description';
export const PAGE_TITLE_PLACEHOLDER = 'Page Title';
export const PAGE_DESCRIPTION_PLACEHOLDER = 'Enter Description';
export const DISCLAIMER_LABEL = 'Page Disclaimer Text';
export const DISCLAIMER_PLACEHOLDER = 'Enter text';

// API documentation
export const FIND_OUR_DOCUMENTATION = 'You can find our ';
export const API_DOCUMENTATION_HERE = 'API documentation here';


// ChangeFirsTtimePassword
export const WELCOME = 'Welcome to Paylode!';
export const ONBOARD = 'Glad to have you onboard! Please select a new password, or ';
export const SKIP_NOW = 'skip for now.'
export const LATER_TEXT = 'You can change this later in the settings.';

export const HELP_CENTER = 'Help Center';
export const API_PAGE_LABEL = 'Summary';
export const API_PAGE_TEXT = <span>Access perks in this perk collection via API<br/>
    View <a href="https://app.swaggerhub.com/apis/paylode.com/perk-collections-spec">API docs</a> for detailed information.</span>
export const API_PAGE_KEY_LABEL = 'API key'
export const API_PAGE_COLLECTION_ID_LABEL = 'Perk collection ID'
export const HOSTED_PAGE_LABEL = 'Summary'
export const HOSTED_PAGE_TEXT = 'Use this option to show your perks on a page that’s hosted by Paylode.\n' +
    'Think of it as a simple perks page that’s ready to use anytime with zero coding.\n' +
    'It’s a perfect fit for situations like:\n' +
    '\u2022 Let your users browse perks without having to deep link into your mobile app\n' +
    '\u2022 Show a particular selection of perks centered around a particular event and/or holiday (such as Back to school perks)\n' +
    '\u2022 Enable perks experiences for cases that don’t allow embeds and/or api integrations (such as showing perks to someone after they book a meeting with you via Calendly)'
export const HOSTED_PAGE_URL_LABEL = 'Link to hosted page'