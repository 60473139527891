import { takeEvery, call, put } from 'redux-saga/effects';

import * as types from './types';
import { ERROR_GET_METRICS } from '../../common/i18n';
import { fetchPerformanceMetricsData } from './service';

export function* getPerformanceMetricsWatcherSaga() {
  yield takeEvery(types.GET_PERFORMANCE_METRICS_REQUEST, workerSaga);
}

export function* workerSaga({ entityType, metricType, selectedDateRange, selectedEntities }) {
  try {
    const response =
      yield call(fetchPerformanceMetricsData, entityType, metricType, selectedDateRange, selectedEntities);

    if (response && response.status === 200) {
      const { data } = response;

      yield put({ type: types.GET_PERFORMANCE_METRICS_SUCCESS, data, entityType, metricType});

    } else {
      yield put({ type: types.GET_PERFORMANCE_METRICS_FAILURE, entityType, metricType, error: ERROR_GET_METRICS });
    }
  } catch (error) {
    yield put({ type: types.GET_PERFORMANCE_METRICS_FAILURE, entityType, metricType, error });
  }
}
