import axios from 'axios';
import { take, call, put, fork } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { getClient } from '../../common/utils';


// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* individualCustomerSegmentCountWatcherSaga() {
  while(true) {
    const action = yield take(types.INDIVIDUAL_CUSTOMER_SEGMENT_COUNT_API_CALL_REQUEST);
    const { slugs } = action;
    if (slugs.length === 0) {
      yield put({ type: types.INDIVIDUAL_CUSTOMER_SEGMENT_COUNT_API_CALL_SUCCESS });
    }
    for (let i = 0; i < slugs.length; i += 1) {
      yield fork(workerSaga, slugs[i]);
    }
  }
}

export function fetchData(slug) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/consumer/${client}/consumer-count/${slug}/`;

  return axios.get(url)
    .then(val => val)
    .catch(err => err);
}


export function* workerSaga(slug) {
  try {
    const response = yield call(fetchData, slug);

    if (response) {
      const data = { slug, count: response.data };
      yield put({ type: types.INDIVIDUAL_CUSTOMER_SEGMENT_COUNT_API_CALL_SUCCESS, data });
    } else {
      yield put({
        type: types.INDIVIDUAL_CUSTOMER_SEGMENT_COUNT_API_CALL_FAILURE,
        error: 'error getting individual customer segment count'
      });
    }
  } catch (error) {
    console.log('Error ', error);
    yield put({ type: types.INDIVIDUAL_CUSTOMER_SEGMENT_COUNT_API_CALL_FAILURE, error });
  }
}

