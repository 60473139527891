export const GET_PERKS_CALL_REQUEST = 'GET_PERKS_CALL_REQUEST';
export const GET_PERKS_CALL_SUCCESS = 'GET_PERKS_CALL_SUCCESS';
export const GET_PERKS_CALL_FAILURE = 'GET_PERKS_CALL_FAILURE';

export const CREATE_PERKS_CALL_REQUEST = 'CREATE_PERKS_CALL_REQUEST';
export const CREATE_PERKS_CALL_SUCCESS = 'CREATE_PERKS_CALL_SUCCESS';
export const CREATE_PERKS_CALL_FAILURE = 'CREATE_PERKS_CALL_FAILURE';

export const DELETE_PERKS_CALL_REQUEST = 'DELETE_PERKS_CALL_REQUEST';
export const DELETE_PERKS_CALL_SUCCESS = 'DELETE_PERKS_CALL_SUCCESS';
export const DELETE_PERKS_CALL_FAILURE = 'DELETE_PERKS_CALL_FAILURE';

export const UPDATE_PERKS_CALL_REQUEST = 'UPDATE_PERKS_CALL_REQUEST';
export const UPDATE_PERKS_CALL_SUCCESS = 'UPDATE_PERKS_CALL_SUCCESS';
export const UPDATE_PERKS_CALL_FAILURE = 'UPDATE_PERKS_CALL_FAILURE';
export const REARRANGE_PERKS_CALL_REQUEST = 'REARRANGE_PERKS_CALL_REQUEST';

export const GET_CLIENT_ENTITIES_REQUEST = 'GET_CLIENT_ENTITIES_REQUEST';
export const GET_CLIENT_ENTITIES_SUCCESS = 'GET_CLIENT_ENTITIES_SUCCESS';
export const GET_CLIENT_ENTITIES_FAILED = 'GET_CLIENT_ENTITIES_FAILED';

export const UPDATE_THEME_CALL_REQUEST = 'UPDATE_THEME_CALL_REQUEST';
export const UPDATE_THEME_CALL_SUCCESS = 'UPDATE_THEME_CALL_SUCCESS';
export const UPDATE_THEME_CALL_FAILURE = 'UPDATE_THEME_CALL_FAILURE';

export const GET_THEME_CALL_REQUEST = 'GET_THEME_CALL_REQUEST';
export const GET_THEME_CALL_SUCCESS = 'GET_THEME_CALL_SUCCESS';
export const GET_THEME_CALL_FAILURE = 'GET_THEME_CALL_FAILURE';

export const SET_SELECTED_ENTITY = 'SET_SELECTED_ENTITY';

export const RESET_PERK_STATE = 'RESET_PERK_STATE';

export const GET_PERK_PAGE_STYLE_REQUEST = 'GET_PERK_PAGE_STYLE_REQUEST';
export const GET_PERK_PAGE_STYLE_SUCCESS = 'GET_PERK_PAGE_STYLE_SUCCESS';
export const GET_PERK_PAGE_STYLE_FAILURE = 'GET_PERK_PAGE_STYLE_FAILURE';

export const UPDATE_PERK_PAGE_STYLE_REQUEST = 'UPDATE_PERK_PAGE_STYLE_REQUEST';
export const UPDATE_PERK_PAGE_STYLE_SUCCESS = 'UPDATE_PERK_PAGE_STYLE_SUCCESS';
export const UPDATE_PERK_PAGE_STYLE_FAILURE = 'UPDATE_PERK_PAGE_STYLE_FAILURE';

export const GET_PERK_PAGE_CONFIG_REQUEST = 'GET_PERK_PAGE_CONFIG_REQUEST';
export const GET_PERK_PAGE_CONFIG_SUCCESS = 'GET_PERK_PAGE_CONFIG_SUCCESS';
export const GET_PERK_PAGE_CONFIG_FAILURE = 'GET_PERK_PAGE_CONFIG_FAILURE';

export const UPDATE_PERK_PAGE_CONFIG_REQUEST = 'UPDATE_PERK_PAGE_CONFIG_REQUEST';
export const UPDATE_PERK_PAGE_CONFIG_SUCCESS = 'UPDATE_PERK_PAGE_CONFIG_SUCCESS';
export const UPDATE_PERK_PAGE_CONFIG_FAILURE = 'UPDATE_PERK_PAGE_CONFIG_FAILURE';

export const GET_FONTS_REQUEST = 'GET_FONTS_REQUEST';
export const GET_FONTS_SUCCESS = 'GET_FONTS_SUCCESS';
export const GET_FONTS_FAILURE = 'GET_FONTS_FAILURE';


