import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { getClient } from '../../common/utils';
import { TEMPLATE_TYPE } from './constants';
import { ERROR_GET_TEMPLATE } from '../../common/i18n';

export function* getLandingPageTemplatesWatcherSaga() {
  yield takeLatest(types.GET_LP_TEMPLATES_API_CALL_REQUEST, workerSaga);
}

export function fetchData(template_type) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/client/${client}/templates/?template_type=${template_type}`;

  return axios.get(url, {})
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga() {
  try {
    const response = yield call(fetchData, TEMPLATE_TYPE);

    if (response && response.status === 200) {
      const { data } = response;

      yield put({ type: types.GET_LP_TEMPLATES_API_CALL_SUCCESS, data });

    } else {
      yield put({ type: types.GET_LP_TEMPLATES_API_CALL_FAILURE, error: ERROR_GET_TEMPLATE});
    }
  } catch (error) {
    yield put({ type: types.GET_LP_TEMPLATES_API_CALL_FAILURE, error });
  }
}
