import axios from 'axios';
import { takeEvery, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { getClient } from '../../common/utils';
import { DEMO_CLIENT_SLUG } from '../../common/constants';
import { ERROR_GET_REVENUE_FAILED } from '../../common/i18n';

export function* getPartnerOfferClickedWatcherSaga() {
  yield takeEvery(types.GET_PARTNER_OFFER_CLICKED_REQUEST, workerSaga);
}

export function fetchData(entityType, selectedDateRange) {
  const dateRangeQueryParams = `&start_datetime=${selectedDateRange.startDate}&end_datetime=${selectedDateRange.endDate}`;
  const client = getClient();
  let url;
  if (client === DEMO_CLIENT_SLUG) {
    url = `${config.API_ROOT_URL}/v1/offer/${client}/demo-perkpage-offer-metrics/?metric_type=offer-clicked${dateRangeQueryParams}`;
  } else {
    url = `${config.API_ROOT_URL}/v1/offer/${client}/perkpage-offer-metrics/?metric_type=offer-clicked${dateRangeQueryParams}`;
  }

  return axios.get(url, {})
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga({ entityType, selectedDateRange }) {
  try {
    const response = yield call(fetchData, entityType, selectedDateRange);
    if (response && response.status === 200) {
      const { data } = response;
      yield put({
        type: types.GET_PARTNER_OFFER_CLICKED_SUCESS,
        data,
        entityType,
        metricType: 'partnerOfferClicked'
      });
    } else {
      yield put({
        type: types.GET_PARTNER_OFFER_CLICKED_FAILURE,
        entityType,
        metricType: 'partnerOfferClicked',
        error: ERROR_GET_REVENUE_FAILED
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_PARTNER_OFFER_CLICKED_FAILURE,
      entityType,
      metricType: 'partnerOfferClicked',
      error
    });
  }
}
