export const UPDATE_SELECTED_CUSTOMER_SEGMENT = 'UPDATE_SELECTED_CUSTOMER_SEGMENT';

export const RESET_SELECTED_CUSTOMER_SEGMENT_TO_DEFAULT = 'RESET_SELECTED_CUSTOMER_SEGMENT_TO_DEFAULT';

export const GET_CUSTOMER_SEGMENTS_API_CALL_REQUEST = 'GET_CUSTOMER_SEGMENTS_API_CALL_REQUEST';
export const GET_CUSTOMER_SEGMENTS_API_CALL_SUCCESS = 'GET_CUSTOMER_SEGMENTS_API_CALL_SUCCESS';
export const GET_CUSTOMER_SEGMENTS_API_CALL_FAILURE = 'GET_CUSTOMER_SEGMENTS_API_CALL_FAILURE';
export const GET_CUSTOMER_SEGMENTS_API_CALL_CANCELLED = 'GET_CUSTOMER_SEGMENTS_API_CALL_CANCELLED';

export const UPDATE_CUSTOMER_SEGMENTS_LIST = 'UPDATE_CUSTOMER_SEGMENTS_LIST';


export const CREATE_CUSTOMER_SEGMENTS_API_CALL_REQUEST = 'CREATE_CUSTOMER_SEGMENTS_API_CALL_REQUEST';
export const CREATE_CUSTOMER_SEGMENTS_API_CALL_SUCCESS = 'CREATE_CUSTOMER_SEGMENTS_API_CALL_SUCCESS';
export const CREATE_CUSTOMER_SEGMENTS_API_CALL_FAILURE = 'CREATE_CUSTOMER_SEGMENTS_API_CALL_FAILURE';

export const UPDATE_CUSTOMER_SEGMENTS_API_CALL_REQUEST = 'UPDATE_CUSTOMER_SEGMENTS_API_CALL_REQUEST';
export const UPDATE_CUSTOMER_SEGMENTS_API_CALL_SUCCESS = 'UPDATE_CUSTOMER_SEGMENTS_API_CALL_SUCCESS';
export const UPDATE_CUSTOMER_SEGMENTS_API_CALL_FAILURE = 'UPDATE_CUSTOMER_SEGMENTS_API_CALL_FAILURE';

export const GET_CUSTOMER_SEGMENTS_FILTERS_API_CALL_REQUEST = 'GET_CUSTOMER_SEGMENTS_FILTERS_API_CALL_REQUEST';
export const GET_CUSTOMER_SEGMENTS_FILTERS_API_CALL_SUCCESS = 'GET_CUSTOMER_SEGMENTS_FILTERS_API_CALL_SUCCESS';
export const GET_CUSTOMER_SEGMENTS_FILTERS_API_CALL_FAILURE = 'GET_CUSTOMER_SEGMENTS_FILTERS_API_CALL_FAILURE';

export const UPDATE_CUSTOMER_SEGMENTS_FILTERS = 'UPDATE_CUSTOMER_SEGMENTS_FILTERS';

export const RESET_CUSTOMER_SEGMENTS = 'RESET_CUSTOMER_SEGMENTS';


export const GET_CUSTOMER_SEGMENTS_COUNT_API_CALL_REQUEST = 'GET_CUSTOMER_SEGMENTS_COUNT_API_CALL_REQUEST';
export const GET_CUSTOMER_SEGMENTS_COUNT_API_CALL_SUCCESS = 'GET_CUSTOMER_SEGMENTS_COUNT_API_CALL_SUCCESS';
export const GET_CUSTOMER_SEGMENTS_COUNT_API_CALL_FAILURE = 'GET_CUSTOMER_SEGMENTS_COUNT_API_CALL_FAILURE';

export const INDIVIDUAL_CUSTOMER_SEGMENT_COUNT_API_CALL_REQUEST = 'INDIVIDUAL_CUSTOMER_SEGMENT_COUNT_API_CALL_REQUEST';
export const INDIVIDUAL_CUSTOMER_SEGMENT_COUNT_API_CALL_SUCCESS = 'INDIVIDUAL_CUSTOMER_SEGMENT_COUNT_API_CALL_SUCCESS';
export const INDIVIDUAL_CUSTOMER_SEGMENT_COUNT_API_CALL_FAILURE = 'INDIVIDUAL_CUSTOMER_SEGMENT_COUNT_API_CALL_FAILURE';

export const SEARCH_CUSTOMER_SEGMENTS_API_CALL_REQUEST = 'SEARCH_CUSTOMER_SEGMENTS_API_CALL_REQUEST';
export const SEARCH_CUSTOMER_SEGMENTS_API_CALL_SUCCESS = 'SEARCH_CUSTOMER_SEGMENTS_API_CALL_SUCCESS';
export const SEARCH_CUSTOMER_SEGMENTS_API_CALL_FAILURE = 'SEARCH_CUSTOMER_SEGMENTS_API_CALL_FAILURE';
export const SEARCH_CUSTOMER_SEGMENTS_API_CALL_CANCELLED = 'SEARCH_CUSTOMER_SEGMENTS_API_CALL_CANCELLED';

export const RESET_CUSTOMER_SEGMENTS_ERROR = 'RESET_CUSTOMER_SEGMENTS_ERROR';

export const DELETE_CUSTOMER_SEGMENTS_API_CALL_REQUEST = 'DELETE_CUSTOMER_SEGMENTS_API_CALL_REQUEST';
export const DELETE_CUSTOMER_SEGMENTS_API_CALL_SUCCESS = 'DELETE_CUSTOMER_SEGMENTS_API_CALL_SUCCESS';
export const DELETE_CUSTOMER_SEGMENTS_API_CALL_FAILURE = 'DELETE_CUSTOMER_SEGMENTS_API_CALL_FAILURE';

export const GET_CLIENT_INTEGRATION_CONFIGURATION = 'GET_CLIENT_INTEGRATION_CONFIGURATION';
export const GET_CLIENT_INTEGRATION_CONFIGURATION_SUCCESS = 'GET_CLIENT_INTEGRATION_CONFIGURATION_SUCCESS';

export const UPLOAD_CONSUMER_DATA_FILE_REQUEST = 'UPLOAD_CONSUMER_DATA_FILE_REQUEST';
export const UPLOAD_CONSUMER_DATA_FILE_SUCCESS = 'UPLOAD_CONSUMER_DATA_FILE_SUCCESS';
export const UPLOAD_CONSUMER_DATA_FILE_FAILURE = 'UPLOAD_CONSUMER_DATA_FILE_FAILURE';
