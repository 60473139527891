import axios from 'axios';
import { takeEvery, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { getClient } from '../../common/utils';

export function* deletePerkWatcherSaga() {
  yield takeEvery(types.DELETE_PERKS_CALL_REQUEST, workerSaga);
}

export function deleteData(entitySlug, offer) {
  const client = getClient();
  // TODO user perks/:id rather then offer to delete perk
  const url = `${config.API_ROOT_URL}/v1/offer/${client}/entity/${entitySlug}/perks/${offer.slug}/`;

  return axios.delete(url)
    .then(val => val)
    .catch(err => err.response);
}

export function* workerSaga(action) {
  try {
    const { entitySlug, offer, postRequest } = action;
    const response = yield call(deleteData, entitySlug, offer);

    if (response && response.status === 204) {
      yield put({ type: types.DELETE_PERKS_CALL_SUCCESS });
      yield put({ type: types.GET_PERKS_CALL_REQUEST, entitySlug });
      postRequest();
    } else {
      yield put({ type: types.DELETE_PERKS_CALL_FAILURE, error: response && response.data });
    }
  } catch (error) {
    yield put({ type: types.DELETE_PERKS_CALL_FAILURE, 'error': error && error.data});
  }
}
