export const UPDATE_SELECTED_BUYING_MOMENT = 'UPDATE_SELECTED_BUYING_MOMENT';

export const RESET_BUYING_MOMENT_TO_DEFAULT = 'RESET_BUYING_MOMENT_TO_DEFAULT';
export const RESET_BUYING_MOMENTS = 'RESET_BUYING_MOMENTS';

export const GET_BUYING_MOMENTS_API_CALL_REQUEST = 'GET_BUYING_MOMENTS_API_CALL_REQUEST';
export const GET_BUYING_MOMENTS_API_CALL_SUCCESS = 'GET_BUYING_MOMENTS_API_CALL_SUCCESS';
export const GET_BUYING_MOMENTS_API_CALL_FAILURE = 'GET_BUYING_MOMENTS_API_CALL_FAILURE';
export const GET_BUYING_MOMENTS_API_CALL_CANCELLED = 'GET_BUYING_MOMENTS_API_CALL_CANCELLED';

export const GET_ALL_BUYING_MOMENTS_API_CALL_SUCCESS = 'GET_ALL_BUYING_MOMENTS_API_CALL_SUCCESS';

export const UPDATE_BUYING_MOMENTS_LIST = 'UPDATE_BUYING_MOMENTS_LIST';


export const CREATE_BUYING_MOMENTS_API_CALL_REQUEST = 'CREATE_BUYING_MOMENTS_API_CALL_REQUEST';
export const CREATE_BUYING_MOMENTS_API_CALL_SUCCESS = 'CREATE_BUYING_MOMENTS_API_CALL_SUCCESS';
export const CREATE_BUYING_MOMENTS_API_CALL_FAILURE = 'CREATE_BUYING_MOMENTS_API_CALL_FAILURE';

export const UPDATE_BUYING_MOMENTS_API_CALL_REQUEST = 'UPDATE_BUYING_MOMENTS_API_CALL_REQUEST';
export const UPDATE_BUYING_MOMENTS_API_CALL_SUCCESS = 'UPDATE_BUYING_MOMENTS_API_CALL_SUCCESS';
export const UPDATE_BUYING_MOMENTS_API_CALL_FAILURE = 'UPDATE_BUYING_MOMENTS_API_CALL_FAILURE';

export const RESET_BUYING_MOMENT_ERROR = 'RESET_BUYING_MOMENT_ERROR';


export const DELETE_BUYING_MOMENTS_API_CALL_REQUEST = 'DELETE_BUYING_MOMENTS_API_CALL_REQUEST';
export const DELETE_BUYING_MOMENTS_API_CALL_SUCCESS = 'DELETE_BUYING_MOMENTS_API_CALL_SUCCESS';
export const DELETE_BUYING_MOMENTS_API_CALL_FAILURE = 'DELETE_BUYING_MOMENTS_API_CALL_FAILURE';

export const GET_BUYING_MOMENT_DAYS_REQUEST = 'GET_BUYING_MOMENT_DAYS_REQUEST';
export const GET_BUYING_MOMENT_DAYS_SUCCESS = 'GET_BUYING_MOMENT_DAYS_SUCCESS';
export const GET_BUYING_MOMENT_DAYS_FAILURE = 'GET_BUYING_MOMENT_DAYS_FAILURE';

export const GET_BUYING_MOMENT_ACTIONS_REQUEST = 'GET_BUYING_MOMENT_ACTIONS_REQUEST';
export const GET_BUYING_MOMENT_ACTIONS_SUCCESS = 'GET_BUYING_MOMENT_ACTIONS_SUCCESS';
export const GET_BUYING_MOMENT_ACTIONS_FAILURE = 'GET_BUYING_MOMENT_ACTIONS_FAILURE';
