import { takeEvery, call, put, select } from 'redux-saga/effects';
import { ToastsStore } from 'react-toasts';
import axios from 'axios';
import {
  PRM_PRIVILEGE,
} from '../../common/constants';
import {
  OFFER_DELETED_SUCCESSFULLY, CAMPAIGN_DELETED_SUCCESSFULLY,
} from './../../common/i18n';

import * as types from './types';
import offerUrls from './offerUrls';

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* deleteOfferWatcherSaga() {
  yield takeEvery(types.DELETE_OFFER_API_CALL_REQUEST, workerSaga);
}

export function deleteOffer(slug, user) {

  const url = `${offerUrls('deleteOffer', user)}${slug}/`;

  return axios.delete(url, {})
    .then(res => res)
    .catch(err => err.response);
}

// worker saga: makes the api call when watcher saga sees the action
export function* workerSaga(action) {
  try {
    const { slug, searchText } = action;
    const state = yield select();
    const user = state.login.toJS().user;
    const response = yield call(deleteOffer, slug, user);

    if (response && response.status === 204) {
      yield put({ type: types.DELETE_OFFER_API_CALL_SUCCESS, slug });
      ToastsStore.success(user.privilege === PRM_PRIVILEGE ?
        OFFER_DELETED_SUCCESSFULLY : CAMPAIGN_DELETED_SUCCESSFULLY);
      if (searchText) {
        yield put({ type: types.SEARCH_OFFERS_API_CALL_REQUEST, page: 1, search: searchText });
      } else {
        yield put({ type: types.GET_OFFERS_API_CALL_REQUEST, page: 1 });
      }
    } else {
      yield put({ type: types.DELETE_OFFER_API_CALL_FAILURE, error: true });
    }

  } catch (error) {
    yield put({ type: types.DELETE_OFFER_API_CALL_FAILURE });
  }
}
