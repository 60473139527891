import axios from 'axios';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import {getClient, getClientData, prepareMarketplaceFiltersForRequest} from '../../common/utils';
import { ERROR_GET_MP_OFFERS } from '../../common/i18n';
import { INFINITE } from '../../common/constants';
import { prepareSearchQuery } from './helper';

export function* getMarketPlaceOffersWatcherSaga() {
  yield takeLatest(types.GET_MP_OFFERS_CALL_REQUEST, workerSaga);
}

export function fetchData(searchParams) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/offer/${client}/marketplace/`;

  const params = {
    page_size: INFINITE,
    ...searchParams,
  };

  return axios.get(url, { params })
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga({ locations, industries, brands, showPrivate, search }) {
  const {
    updatedFilter
  } = yield select(state => state.marketplaceoffers.toJS());

  const {
    industries: industryOptions,
      brands: brandOptions,
      locations: locationOptions
  } = yield select(state => state.app.toJS());

  const filtersForRequest = prepareMarketplaceFiltersForRequest(
      { locationOptions, industryOptions, brandOptions },
      { locations, industries, brands },
      true
  )

  const clientData = getClientData();

  if (updatedFilter === 'search') {
    window.rudderanalytics.track("Search submitted", {
      client_id: clientData.client__id,
      search_query: search,
      filters_applied: {
        ...filtersForRequest,
        showPrivate
      }
    })
  }

  try {
    const searchParams = prepareSearchQuery(locations, industries, brands, showPrivate, search);

    const response = yield call(fetchData, searchParams);

    if (response && response.status === 200) {
      const { data } = response;

      if (updatedFilter === 'search') {
        window.rudderanalytics.track("Search results displayed", {
          client_id: clientData.client__id,
          search_query: search,
          total_initial_result_count: data.count,
          filters_applied: {
            ...filtersForRequest,
            showPrivate
          }
        });
      }

      yield put({ type: types.GET_MP_OFFERS_CALL_SUCCESS, data });

    } else {
      yield put({ type: types.GET_MP_OFFERS_CALL_FAILURE, error: ERROR_GET_MP_OFFERS});
    }
  } catch (error) {
    yield put({ type: types.GET_MP_OFFERS_CALL_FAILURE, error });
  }
}
