import { fromJS } from 'immutable';

const initialState = fromJS({
  resetpasswordlinksent: false,
  resetpasswordlinkvalid: false,
  fetching: false,
  error: null,
  user: {
    email: '',
    password: '',
    privilege: undefined,
    id: '',
    mobile: '',
    profile_image: '',
    name: '',
    display_change_password: false,
  },
});

export default initialState;
