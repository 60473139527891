/**
 * Create buying moment constants
 */
export const MENU_ITEMS = {
  'Create Copy': 'icon-copy',
  'Delete': 'icon-delete',
};

export const TEMPLATE_TYPE = 'landing-page';

export const STEP1 = 'step 1';

export const STEP2 = 'step 2';

export const STEP3 = 'step 3';

export const STEP4 = 'step 4';

export const STEP5 = 'step 5';

export const STEP6 = 'step 6';

export const MESSAGE_LIMIT = 160;

export const MAX_PHONE_LENGTH = 17;

export const SPECIFIC_DATE = 'On Calendar Dates';
export const SPECIFIC_DAY = 'On Customer Dates';
export const SPECIFIC_EVENT = 'On Actions';

export const BUYING_MOMENT_OPTIONS = [
  SPECIFIC_DATE,
  SPECIFIC_DAY,
  SPECIFIC_EVENT,
];

// export const DURATION_TYPES= ['Minute', 'Hour', 'Day', 'Week', 'Month', 'Year'];
export const DURATION_TYPES= ['Hour', 'Day', 'Week', 'Month', 'Year'];

export const OCCURENCE = ['Before', 'After'];

export const CUSTOMER_DATE_OCCURENCE = ['Before & Within', 'Before', 'After'];

export const DAY = ['Birthday', 'Anniversary'];

export const SELECT_LANDING_PAGE_LABEL = 'Select Landing Page';


export const MomentShorthand = {
  'hour': 'h',
  'day': 'd',
  'week': 'w',
  'month': 'M',
  'year': 'y'
};

export const VALUE_TYPE = 'variable';
export const ORIGIN_MARKETPLACE = 'marketplace';

export const BM_OCCURRENCE_DB_UI_MAPPING = {
  'before&within': 'Before & Within',
  'after': 'After',
  'before': 'Before',
};

export const BM_OCCURRENCE_UI_MAPPING = {
  'before&within': 'before & within',
  'after': 'after',
  'before': 'before',
};

export const BM_OCCURRENCE_DB_MAPPING = {
  'Before & Within': 'before&within',
  'After': 'after',
  'Before': 'before',
};
