import * as types from './types';
import { fromJS } from 'immutable';
import { initialState, defaultBuyingMoment } from './initialState';
import { cloneDeep, resetPagination } from '../../common/utils';


const reducer = (state = initialState, action) => {
  const activePage = state.getIn(['buyingMoments', 'activePage']);
  switch (action.type) {
  case types.UPDATE_SELECTED_BUYING_MOMENT:
    return state.setIn(['selectedBuyingMoment'], action.buyingMoment);
  case types.RESET_BUYING_MOMENTS:
    return state.setIn(['buyingMoments'], fromJS({
      items: [],
      total: 0,
      pages: {},
      activePage: 1,
    }));
  case types.RESET_BUYING_MOMENT_TO_DEFAULT:
    return state.setIn(['selectedBuyingMoment'], defaultBuyingMoment);

  case types.GET_BUYING_MOMENTS_API_CALL_REQUEST:
    return state.setIn(['fetching'], true)
      .setIn(['error'], false);
  case types.GET_BUYING_MOMENTS_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['error'], false)
      .updateIn(['buyingMoments', 'items'], items => {
        const clonedResults = items.toJS();
        return fromJS([ ...clonedResults, ...action.data.results ]);
      })
      .setIn(['buyingMoments', 'total'], action.data.count)
      .updateIn(['buyingMoments', 'pages'], pages => {
        const clonedPages = pages.toJS();
        clonedPages[action.data.page] = action.data.results;
        return fromJS(clonedPages);
      });
  case types.GET_ALL_BUYING_MOMENTS_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['error'], false)
      .setIn(['buyingMoments', 'items'], fromJS(action.data.results))
      .setIn(['buyingMoments', 'total'], action.data.count);
  case types.GET_BUYING_MOMENTS_API_CALL_FAILURE:
    return state.setIn(['fetching'], false)
      .setIn(['error'], action.error);
  case types.GET_BUYING_MOMENTS_API_CALL_CANCELLED:
    return state.setIn(['fetching'], false)
      .setIn(['error'], false);

  case types.UPDATE_BUYING_MOMENTS_LIST:
    return state.setIn(['buyingMoments', 'activePage'], action.activePage);

  case types.CREATE_BUYING_MOMENTS_API_CALL_REQUEST:
  case types.UPDATE_BUYING_MOMENTS_API_CALL_REQUEST:
    return state.setIn(['fetching'], true)
      .setIn(['createAndUpdateError'], false);
  case types.CREATE_BUYING_MOMENTS_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['createAndUpdateError'], false)
      .updateIn(['buyingMoments', 'total'], total => total + 1 )
      .updateIn(['buyingMoments'], buyingMoments => {
        const clonedBuyingMoments = cloneDeep(buyingMoments.toJS());
        resetPagination(clonedBuyingMoments, action.data, false, 'buyingmoments');
        return fromJS(clonedBuyingMoments);
      });
  case types.UPDATE_BUYING_MOMENTS_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['createAndUpdateError'], false)
      .updateIn(['buyingMoments', 'pages'], pages => {
        const clonePages = pages.toJS();
        // TODO , why active page was not reset after resetting pages
        if (clonePages && clonePages[activePage]) {
          const updatedPage = clonePages[activePage].map(ele => {
            if (ele.id === action.oldBMId) {
              ele = action.data;
            }
            return ele;
          });
          clonePages[activePage] = updatedPage;
        }
        return fromJS(clonePages);
      })
      .updateIn(['buyingMoments', 'items'], items => {
        const cloneItems = items.toJS();
        const updatedItems = cloneItems.map(ele => {
          if (ele.id === action.oldBMId) {
            ele = action.data;
          }
          return ele;
        });
        return fromJS(updatedItems);
      });
  case types.CREATE_BUYING_MOMENTS_API_CALL_FAILURE:
  case types.UPDATE_BUYING_MOMENTS_API_CALL_FAILURE:
    return state.setIn(['fetching'], false)
      .setIn(['createAndUpdateError'], action.error);

  case types.RESET_BUYING_MOMENT_ERROR:
    return state.setIn(['createAndUpdateError'], false);

  case types.DELETE_BUYING_MOMENTS_API_CALL_REQUEST:
    return state.setIn(['fetching'], true)
      .setIn(['deleteError'], false);
  case types.DELETE_BUYING_MOMENTS_API_CALL_SUCCESS:
    const deletedId = action.id;
    return state.setIn(['fetching'], false)
      .setIn(['deleteError'], false)
      .updateIn(['buyingMoments', 'total'], total => total - 1 )
      .updateIn(['buyingMoments'], buyingMoments => {
        const clonedBuyingMoments = buyingMoments.toJS();
        resetPagination(clonedBuyingMoments, deletedId, true, 'buyingmoments');
        return fromJS(clonedBuyingMoments);
      });
  case types.DELETE_BUYING_MOMENTS_API_CALL_FAILURE:
    return state.setIn(['fetching'], false)
      .setIn(['deleteError'], action.error);
  case types.GET_BUYING_MOMENT_DAYS_SUCCESS:
    return state.setIn(['days'], action.data);
  case types.GET_BUYING_MOMENT_ACTIONS_SUCCESS:
    return state.setIn(['actions'], action.data);
  case types.GET_BUYING_MOMENT_ACTIONS_FAILURE:
    return state.setIn(['actions'], []);

  default:
    return state;
  }
};

// ACTION CREATORS
export const updateSelectedBuyingMoment = (buyingMoment, updateCache) => ({ type: types.UPDATE_SELECTED_BUYING_MOMENT, buyingMoment, updateCache });
export const resetBuyingMoments = () => ({ type: types.RESET_BUYING_MOMENTS });
export const resetBuyingMomentToDefault = () => ({ type: types.RESET_BUYING_MOMENT_TO_DEFAULT });
export const getBuyingMomentsRequest = (page, size, search) => ({ type: types.GET_BUYING_MOMENTS_API_CALL_REQUEST, page, size, search});
export const updateBuyingMomentsList = (activePage) => ({ type: types.UPDATE_BUYING_MOMENTS_LIST, activePage });
export const createBuyingMomentsRequest = (data, history) => ({ type: types.CREATE_BUYING_MOMENTS_API_CALL_REQUEST, data, history});
export const patchBuyingMomentRequest = (data) => ({ type: types.UPDATE_BUYING_MOMENTS_API_CALL_REQUEST, data });

export const resetBuyingMomentError = () => ({ type: types.RESET_BUYING_MOMENT_ERROR });
export const deleteBuyingMomentsRequest = (id) => ({ type: types.DELETE_BUYING_MOMENTS_API_CALL_REQUEST, id });

export const getBuyingMomentDays = () => ({ type: types.GET_BUYING_MOMENT_DAYS_REQUEST });
export const getBuyingMomentActions = () => ({ type: types.GET_BUYING_MOMENT_ACTIONS_REQUEST });

export const setBuyingMomentCreateError = (error) => ({ type: types.CREATE_BUYING_MOMENTS_API_CALL_FAILURE, error });

export default reducer;
