import React from 'react';
import { Form, Button, Alert } from 'react-bootstrap';

import Link from '../../../../components/Link/Link';

import {
  RequestDemo,
  FORGOT_PASSWORD,
  RESET_PASSED,
  RESET_FAILED,
  EXPIRED_TOKEN,
} from '../../../../common/i18n';
import GreenTickIcon from '../../../../images/green-tick.png';

import './style.scss';
import {FormField} from "../../../../components/FormField.new";

class LoginComponent extends React.PureComponent<Props, State> {
  render() {
    const {
      passwordResetStatus,
      history,
      user,
      fetching,
      error,
      isEmailValid,
      handleSubmit,
      onLoginFieldsInput,
      validateEmail,
    } = this.props;

    return(
      <>
        {error && (<Alert variant="danger">
          <span className="icon-error"></span>&nbsp;&nbsp;<b>Oops!</b> you entered invalid credentials
        </Alert>)}
        {
          !error && passwordResetStatus === RESET_PASSED && (
            <div className="password-reset-suucess">
              <img className="green-tick-icon" src={GreenTickIcon} alt="green-tick-icon" />
                &nbsp;&nbsp;Password reset successfully.
            </div>
          )
        }
        {
          !error && passwordResetStatus === RESET_FAILED && (<Alert variant="danger">
            <span className="icon-error"></span>&nbsp;&nbsp;{EXPIRED_TOKEN}
          </Alert>)
        }
        <div className="login-heading">
          <h1 className="login-heading-title">Sign In</h1>
          <p className="login-heading-subtitle">Welcome! Please enter your details to sign in.</p>
        </div>
        <Form className="login-form-wrapper">
          <FormField
              controlId="formBasicEmail"
              label="Email"
              name="email"
              type="email"
              placeholder="Enter your email"
              value={user.email}
              onChange={onLoginFieldsInput}
              onBlur={validateEmail}
              error={!isEmailValid ? "Invalid email" : undefined}
          />
          <FormField
              controlId="formBasicPassword"
              label="Password"
              name="password"
              placeholder="Enter Password"
              value={user.password}
              type="password"
              onChange={onLoginFieldsInput}
          />

          <Button
            variant="primary"
            type="submit"
            block
            onClick={handleSubmit}
            className="login-btn"
            disabled={!isEmailValid || fetching}>
            {fetching ? 'Loading…' : 'Sign In'}
          </Button>

          <Link
              className="forgot-password-link"
              variant='primary-link'
              onClick={() => history.push('/forgot-password')}
          >
            {FORGOT_PASSWORD}
          </Link>
        </Form>
          <div className="request-invitation-wrapper">
            <span className="request-invitation-wrapper-noaccount">
              Don't have an account?
            </span>
            <a
                href="https://paylode.com/get-started/book-a-demo"
                target="_blank"
                className="request-invitation-wrapper-demo"
            >
              {RequestDemo}
            </a>
          </div>
      </>
    );
  }
}

export default LoginComponent;
