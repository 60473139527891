import * as types from './types';
import { fromJS } from 'immutable';
import { initialState } from './initialState';
import { CLASSIC_VALUE } from '../../common/i18n';


const reducer = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
  case types.GET_PERKS_CALL_REQUEST:
    return state.setIn(['fetching'], true)
      .setIn(['error'], null);
  case types.CREATE_PERKS_CALL_REQUEST:
    return state.setIn(['adding'], true);
  case types.DELETE_PERKS_CALL_REQUEST:
    return state.setIn(['removing'], true);
  case types.GET_PERKS_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['error'], false)
      .setIn(['adding'], false)
      .setIn(['removing'], false)
      .setIn(['offers'], fromJS(data.results));
  case types.GET_PERKS_CALL_FAILURE:
    return state.setIn(['fetching'], false)
      .setIn(['adding'], false)
      .setIn(['removing'], false)
      .setIn(['error'], fromJS(action.error));
  case types.GET_CLIENT_ENTITIES_SUCCESS:
    return state.setIn(['entities'], fromJS(data))
      .setIn(['selectedEntity', 'index'], data.length > 0 ? 0 : -1)
      .setIn(['selectedEntity', 'entity'], data.length > 0 ? data[0] : null);
  case types.SET_SELECTED_ENTITY:
    return state.setIn(['selectedEntity', 'index'], action.entityIndex)
      .setIn(['selectedEntity', 'entity'], action.entity);
  case types.UPDATE_THEME_CALL_SUCCESS:
    return state.setIn(['selectedTheme'], fromJS(data));
  case types.CREATE_PERKS_CALL_SUCCESS:
    const selectedTheme = state?.toJS()?.selectedTheme;
    return state.setIn(['selectedTheme'], selectedTheme ? selectedTheme : CLASSIC_VALUE);
  case types.GET_THEME_CALL_SUCCESS:
    return state.setIn(['selectedTheme'], fromJS(data));
  case types.GET_THEME_CALL_FAILURE:
    return state.setIn(['selectedTheme'], undefined);
  case types.GET_PERK_PAGE_STYLE_SUCCESS:
  case types.UPDATE_PERK_PAGE_STYLE_SUCCESS:
    return state.setIn(['selectedEntityStyle'], fromJS(data));
  case types.GET_PERK_PAGE_STYLE_FAILURE:
  case types.UPDATE_PERK_PAGE_STYLE_FAILURE:
    return state.setIn(['selectedEntityStyle'], null);
  case types.GET_FONTS_SUCCESS:
    return state.setIn(['fonts'], fromJS(data))
      .setIn(['fontsName'], fromJS(data.map(e => e.name)));
  case types.GET_PERK_PAGE_CONFIG_SUCCESS:
  case types.UPDATE_PERK_PAGE_CONFIG_REQUEST:
    return state.setIn(['selectedEntityConfig'], fromJS(data));
  case types.GET_PERK_PAGE_CONFIG_FAILURE:
  case types.UPDATE_PERK_PAGE_CONFIG_FAILURE:
    return state.setIn(['selectedEntityConfig'], null);
  case types.REARRANGE_PERKS_CALL_REQUEST:
    return state.setIn(['offers'], fromJS(data))
      .setIn(['reArranging'], true)
      .setIn(['error'], null);
  case types.UPDATE_PERKS_CALL_SUCCESS:
    return state.setIn(['reArranging'], false);
  case types.RESET_PERK_STATE:
    state = initialState;
    return state;
  default:
    return state;
  }
};

// Action Creators
export const getPerksRequest = (entitySlug) => ({ type: types.GET_PERKS_CALL_REQUEST, entitySlug });
export const createPerksRequest = (entitySlug, data, postRequest) => ({ type: types.CREATE_PERKS_CALL_REQUEST, entitySlug, data, postRequest });
export const deletePerksRequest = (entitySlug, offer, postRequest) => ({ type: types.DELETE_PERKS_CALL_REQUEST, entitySlug, offer, postRequest });
export const updatePerksRequest =
  (entitySlug, data, postRequest) => ({ type: types.UPDATE_PERKS_CALL_REQUEST, entitySlug, data, postRequest });
export const reArrangePerksData = (data) => ({ type: types.REARRANGE_PERKS_CALL_REQUEST, data });
export const getClientEntitiesRequest = () => ({ type: types.GET_CLIENT_ENTITIES_REQUEST });
export const setSelectedEntity = (entityIndex, entity) => ({ type: types.SET_SELECTED_ENTITY, entityIndex, entity });
export const resetPerkState = () => ({ type: types.RESET_PERK_STATE });
export const updateThemeRequest = (updatedData, entitySlug, postRequest) => ({ type: types.UPDATE_THEME_CALL_REQUEST,
  entitySlug, updatedData, postRequest });
export const getThemeRequest = (entitySlug) => ({ type: types.GET_THEME_CALL_REQUEST, entitySlug });
export const getPerkPageStyleRequest = (entitySlug) => ({ type: types.GET_PERK_PAGE_STYLE_REQUEST, entitySlug });
export const updatePerkPageStyle = (entityPerkPageStyle) => ({ type: types.UPDATE_PERK_PAGE_STYLE_SUCCESS, data: entityPerkPageStyle });
export const updatePerkPageStyleRequest = (entitySlug, entityPerkPageStyle, postRequest) => ({ type: types.UPDATE_PERK_PAGE_STYLE_REQUEST, data: entityPerkPageStyle, entitySlug, postRequest });
export const getPerkPageConfigRequest = (entitySlug) => ({ type: types.GET_PERK_PAGE_CONFIG_REQUEST, entitySlug });
export const updatePerkPageConfigRequest = (entitySlug, entityPerkPageConfig, postRequest, getStyleRequest) => ({ type: types.UPDATE_PERK_PAGE_CONFIG_REQUEST, data: entityPerkPageConfig, entitySlug, postRequest, getStyleRequest });
export const getFontsRequest = () => ({ type: types.GET_FONTS_REQUEST });

export default reducer;
