import * as types from './types';
import { fromJS } from 'immutable';
import { initialState, defaultOffer, defaultSteps } from './initialState';
import { getNextStep } from '../../common/utils';
import { STEP1 } from './constants';


const reducer = (state = initialState, action) => {
  switch (action.type) {

  //////////////////////////////////////////
  /////////////INITIAL REQUEST CALLS////////
  case types.GET_OFFERS_API_CALL_REQUEST:
  case types.SEARCH_OFFERS_API_CALL_REQUEST:
  case types.DELETE_TEMPLATES_API_CALL_REQUEST:
  case types.GET_TEMPLATES_API_CALL_REQUEST:
  case types.GET_CHANNELS_API_CALL_REQUEST:
  case types.GET_CUSTOMER_COUNT_PER_CHANNEL_API_CALL_REQUEST:
  case types.CREATE_TEMPLATE_API_CALL_REQUEST:
  case types.GET_ATTRIBUTES_API_CALL_REQUEST:
  case types.GET_OFFER_BY_SLUG_API_CALL_REQUEST:
  case types.GET_OFFER_METRICS_CALL_REQUEST:
  case types.GET_OFFER_REVENUE_CALL_REQUEST:
    return state.setIn(['fetching'], true)
      .setIn(['error'], false);

  case types.CREATE_OFFER_API_CALL_REQUEST:
  case types.PLAY_PAUSE_OFFER_API_CALL_REQUEST:
  case types.PATCH_UPDATE_OFFER_API_CALL_REQUEST:
  case types.UPLOAD_TEMPLATE_API_CALL_REQUEST:
    return state.setIn(['fetching'], true)
      .setIn(['createAndUpdateError'], null);

  case types.DELETE_OFFER_API_CALL_REQUEST:
    return state.setIn(['fetching'], true)
      .setIn(['deleteError'], false);


  //////////////////////////////////////////
  /////////////FAILURE CALLS////////////////
  case types.GET_OFFERS_API_CALL_FAILURE:
  case types.SEARCH_OFFERS_API_CALL_FAILURE:
  case types.DELETE_TEMPLATES_API_CALL_FAILURE:
  case types.GET_TEMPLATES_API_CALL_FAILURE:
  case types.CREATE_TEMPLATE_API_CALL_FAILURE:
  case types.GET_OFFER_BY_SLUG_API_CALL_FAILURE:
  case types.GET_ATTRIBUTES_API_CALL_FAILURE:
  case types.GET_CHANNELS_API_CALL_FAILURE:
  case types.GET_CUSTOMER_COUNT_PER_CHANNEL_API_CALL_FAILURE:
    return state.setIn(['fetching'], false)
      .setIn(['error'], action.error);

  case types.UPLOAD_TEMPLATE_API_CALL_FAILURE:
  case types.CREATE_OFFER_API_CALL_FAILURE:
  case types.PLAY_PAUSE_OFFER_API_CALL_FAILURE:
  case types.PATCH_UPDATE_OFFER_API_CALL_FAILURE: //TODO Update offer in offer list for redux update
    return state.setIn(['fetching'], false)
      .setIn(['createAndUpdateError'], action.error);

  case types.DELETE_OFFER_API_CALL_FAILURE:
    return state.setIn(['fetching'], false)
      .setIn(['deleteError'], action.error);

  case types.GET_OFFER_METRICS_CALL_FAILURE:
    return state.setIn(['fetching'], false)
      .setIn(['selectedOffer', 'metrics'], null)
      .setIn(['error'], action.error);
  case types.GET_OFFER_REVENUE_CALL_FAILURE:
    return state.setIn(['fetching'], false)
      .setIn(['selectedOffer', 'revenue'], null)
      .setIn(['error'], action.error);

  //////////////////////////////////////////
  /////////////OTHER CALLS////////////////
  case types.UPDATE_SELECTED_OFFER:
    return state.updateIn(['selectedOffer'], selectedOffer => {
      const clonedSelectedOffer = selectedOffer.toJS();
      const { metrics, revenue } = clonedSelectedOffer;
      if (action.updateSelectedOfferFields) {
        for (const key of action.updateSelectedOfferFields) {
          clonedSelectedOffer[key] = action.Offer[key];
        }
        return fromJS({
          ...clonedSelectedOffer,
          metrics,
          revenue,
        });
      }
      return fromJS({
        ...clonedSelectedOffer,
        ...action.Offer,
        metrics,
        revenue,
      });
    });

  case types.GET_OFFERS_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['error'], false)
      .updateIn(['offers', 'items'], items => {
        const clonedResults = items.length === 0 ? items : items.toJS();
        return fromJS({ ...clonedResults, ...action.data.results });
      })
      .setIn(['offers', 'total'], action.data.count)
      .updateIn(['offers', 'pages'], pages => {
        const clonedPages = Object.keys(pages).length === 0 ? pages : pages.toJS();
        clonedPages[action.data.page] = action.data.results;
        return fromJS(clonedPages);
      });

  case types.GET_OFFERS_API_CALL_CANCELLED:
  case types.GET_CHANNELS_API_CALL_CANCELLED:
    return state.setIn(['fetching'], false)
      .setIn(['error'], false);

  case types.UPDATE_OFFERS_LIST:
    return state.setIn(['offers', 'activePage'], action.activePage);

  case types.RESET_SELECTED_OFFER_TO_DEFAULT:
    return state.setIn(['selectedOffer'], fromJS(action.offer));

  case types.CREATE_OFFER_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['createAndUpdateError'], null)
      .setIn(['offers'], fromJS({
        items: [],
        total: 0,
        pages: {},
        activePage: 1,
        countFetching: false,
        searchResultPages: {}
      }))
      .updateIn(['selectedOffer'], selectedOffer => {
        const clonedSelectedOffer = selectedOffer.toJS();
        clonedSelectedOffer.slug = action && action.data && action.data.slug;
        clonedSelectedOffer.saveAndQuit = action && action.data && action.data.saveAndQuit;
        const nextStep = getNextStep(clonedSelectedOffer.current_step);
        clonedSelectedOffer.current_step = nextStep;
        return fromJS(clonedSelectedOffer);
      });

  case types.RESET_OFFERS:
    const offers = fromJS({
      items: [],
      total: 0,
      pages: {},
      activePage: 1,
      countFetching: false,
      searchResultPages: {},
    });
    return state.setIn(['offers'], offers);

  case types.SEARCH_OFFERS_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['error'], false)
      .setIn(['offers', 'total'], action.data.count)
      .updateIn(['offers', 'searchResultPages'], pages => {
        const clonedPages = pages.toJS();
        clonedPages[action.data.page] = action.data.results;
        return fromJS(clonedPages);
      });

  case types.SEARCH_OFFERS_API_CALL_CANCELLED:
    return state.setIn(['fetching'], false)
      .setIn(['error'], false);

  case types.RESET_CREATE_UPDATE_OFFER_ERROR:
    return state.setIn(['createAndUpdateError'], null);

  // Manage stepper
  case types.UPDATE_ACTIVE_STEP:
    return state.setIn(['activeStep'], action.activeStep)
      .updateIn(['selectedOffer'], selectedOffer => {
        const clonedSelectedOffer = selectedOffer.toJS();
        clonedSelectedOffer.current_step = `step ${action.activeStep}`;
        return fromJS(clonedSelectedOffer);
      });

  case types.UPDATE_STEPS:
    return state.updateIn(['steps'], steps => {
      const clonedSteps = steps.toJS();
      const index = action.activeStep - 1;
      clonedSteps[index].completed = true;
      return fromJS(clonedSteps);
    });

  case types.RESET_STEPS:
    return state.setIn(['steps'], fromJS(action.steps));

  case types.PLAY_PAUSE_OFFER_API_CALL_SUCCESS:
    const activePage = state.getIn(['offers', 'activePage']);
    return state.setIn(['fetching'], false)
      .setIn(['createAndUpdateError'], null)
      .updateIn(['offers', 'pages'], pages => {
        const clonePages = pages.toJS();
        // TODO , why active page was not reset after resetting pages
        if (clonePages && clonePages[activePage]) {
          const updatedPage = clonePages[activePage].map(ele => {
            if (ele.slug === action.slug) {
              ele.is_active = action.data.is_active;
            }
            return ele;
          });
          clonePages[activePage] = updatedPage;
        }
        return fromJS(clonePages);
      })
      .updateIn(['offers', 'searchResultPages'], pages => {
        const clonePages = pages.toJS();
        // TODO , why active page was not reset after resetting pages
        if (clonePages && clonePages[activePage]) {
          const updatedPage = clonePages[activePage].map(ele => {
            if (ele.slug === action.slug) {
              ele.is_active = action.data.is_active;
            }
            return ele;
          });
          clonePages[activePage] = updatedPage;
        }
        return fromJS(clonePages);
      })
      .updateIn(['selectedOffer'], selectedOffer => {
        const clonedSelectedOffer = selectedOffer.toJS();
        clonedSelectedOffer.isActive = action.data.is_active;
        return fromJS(clonedSelectedOffer);
      });


  case types.DELETE_TEMPLATES_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .updateIn(['templates', 'total'], total => total - 1)
      .updateIn(['templates', 'items'], items => {
        const clonedTemplates = items.toJS();
        const updatedTemplates = clonedTemplates.reduce((acc, ele) => {
          if (ele.slug !== action.templateSlug) {
            acc.push(ele);
          }
          return acc;
        }, []);
        return fromJS(updatedTemplates);
      });

  case types.PATCH_UPDATE_OFFER_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['createAndUpdateError'], null)
      .setIn(['offers'], fromJS({
        items: [],
        total: 0,
        pages: {},
        activePage: 1,
        countFetching: false,
        searchResultPages: {}
      }))
      .updateIn(['selectedOffer'], selectedOffer => {
        const clonedSelectedOffer = selectedOffer.toJS();
        if (clonedSelectedOffer.current_step === STEP1) {
          clonedSelectedOffer.lastSavedOfferValue = clonedSelectedOffer.value;
          if (action.data.slug && clonedSelectedOffer.slug !== action.data.slug) {
            clonedSelectedOffer.slug = action.data.slug;
          }
        }
        if (action.data && action.data.is_published) {
          clonedSelectedOffer.isPublished = true;
        }
        if (action.data && action.data.saveAndQuit) {
          clonedSelectedOffer.saveAndQuit = true;
        }
        if (action.data && action.data.channels) {
          clonedSelectedOffer.channels = clonedSelectedOffer.channels.map(channel => {
            const actionChannel = action.data.channels.find(ele => ele.reminder === channel.reminder)
            if (actionChannel) {
              channel = actionChannel;
              channel.displayContentArea = false;
            }
            return channel;
          });
        }
        return fromJS(clonedSelectedOffer);
      });

  case types.GET_TEMPLATES_API_CALL_SUCCESS:
    const { data } = action;
    const total = data && data.length;
    return state.setIn(['fetching'], false)
      .setIn(['error'], false)
      .setIn(['templates', 'total'], total)
      .setIn(['templates', 'items'], fromJS(data));

  case types.UPLOAD_TEMPLATE_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['createAndUpdateError'], null)
      .setIn(['selectedOffer', 'landing_page', 'name'], action.landing_page.name)
      .setIn(['selectedOffer', 'landing_page', 'html_key'], action.landing_page.html_key)
      .setIn(['selectedOffer', 'landing_page', 'json_key'], action.landing_page.json_key);

  case types.GET_CHANNELS_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['error'], false)
      .setIn(['channels'], action.data);

  case types.GET_CUSTOMER_COUNT_PER_CHANNEL_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['error'], false)
      .setIn(['customerCount'], action.data);

  case types.DELETE_OFFER_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['deleteError'], false)
      // .updateIn(['offers', 'total'], total => total - 1 )
      // .updateIn(['offers'], offers => {
      //   const clonedOffers = offers.toJS()
      //   resetPagination(clonedOffers, deletedSlug, true, 'offers');
      //   return fromJS(clonedOffers);
      // });
      .setIn(['offers'], fromJS({
        items: [],
        total: 0,
        pages: {},
        activePage: 1,
        countFetching: false,
        searchResultPages: {}
      }));

  case types.GET_ATTRIBUTES_API_CALL_SUCCESS:
    const { data: attributes } = action;
    const totalAttributes = attributes && attributes.length;
    return state.setIn(['fetching'], false)
      .setIn(['error'], false)
      .setIn(['attributes', 'total'], totalAttributes)
      .setIn(['attributes', 'items'], attributes);

  case types.GET_OFFER_BY_SLUG_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['error'], false)
      .setIn(['offerPreview'], action.data);

  case types.SET_OFFER_ERROR:
    return state.setIn(['createAndUpdateError'], action.error);

  case types.CREATE_TEMPLATE_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false);

  case types.GET_OFFER_METRICS_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['selectedOffer', 'metrics', action.metricType], fromJS(action.data));
  case types.GET_OFFER_REVENUE_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['selectedOffer', 'revenue'], fromJS(action.data));

  default:
    return state;
  }
};

// ACTION CREATORS
export const updateSelectedOffer = (Offer, updateCache) =>
  ({ type: types.UPDATE_SELECTED_OFFER, Offer, updateCache });
export const resetSelectedOfferToDefault = () =>
  ({ type: types.RESET_SELECTED_OFFER_TO_DEFAULT, offer: defaultOffer });
export const getOffersRequest = (page) =>
  ({ type: types.GET_OFFERS_API_CALL_REQUEST, page});
export const updateOffersList = (activePage) =>
  ({ type: types.UPDATE_OFFERS_LIST, activePage });
export const createOfferRequest = (data, saveAndQuit) =>
  ({ type: types.CREATE_OFFER_API_CALL_REQUEST, data, saveAndQuit });
export const searchOffersRequest = (page, search) =>
  ({ type: types.SEARCH_OFFERS_API_CALL_REQUEST, page, search });
export const resetOffers = () =>
  ({ type: types.RESET_OFFERS });
export const getOffersCountRequest = () =>
  ({ type: types.GET_OFFERS_COUNT_API_CALL_REQUEST });
export const resetCreateUpdateOfferError = () =>
  ({ type: types.RESET_CREATE_UPDATE_OFFER_ERROR });
export const patchUpdateOfferRequest = (data, updateStep, saveAndQuit, callback) =>
  ({
    type: types.PATCH_UPDATE_OFFER_API_CALL_REQUEST,
    data, updateStep, saveAndQuit, callback
  });

export const deleteTemplateRequest = (templateSlug) =>
  ({ type: types.DELETE_TEMPLATES_API_CALL_REQUEST, templateSlug });
export const getTemplatesRequest = (setRecentToSelectedOffer) =>
  ({ type: types.GET_TEMPLATES_API_CALL_REQUEST, setRecentToSelectedOffer });
export const uploadTemplateRequest = (data) =>
  ({ type: types.UPLOAD_TEMPLATE_API_CALL_REQUEST, data });

export const getChannelsRequest = () =>
  ({ type: types.GET_CHANNELS_API_CALL_REQUEST });

export const getCustomerCountPerChannel = (slug, channel) =>
  ({
    type: types.GET_CUSTOMER_COUNT_PER_CHANNEL_API_CALL_REQUEST, slug, channel
  });


// Offer specific actions
export const playPauseOfferRequest = (data, slug) =>
  ({ type: types.PLAY_PAUSE_OFFER_API_CALL_REQUEST, data, slug });
export const getOfferBySlugRequest = (slug, consumerSegmentSlug) =>
  ({
    type: types.GET_OFFER_BY_SLUG_API_CALL_REQUEST, slug, consumerSegmentSlug
  });
export const getSelectedOfferMetrics = (slug, entityType, metricType) =>
  ({ type: types.GET_OFFER_METRICS_CALL_REQUEST, slug, entityType, metricType });
export const getSelectedOfferRevenue = (slug, entityType) =>
  ({ type: types.GET_OFFER_REVENUE_CALL_REQUEST, slug, entityType });

export const setOfferError = (error) => ({ type: types.SET_OFFER_ERROR, error});


// Manage stepper
export const updateActiveStep = (activeStep) =>
  ({ type: types.UPDATE_ACTIVE_STEP, activeStep });
export const updateSteps = (activeStep) =>
  ({ type: types.UPDATE_STEPS, activeStep });
export const resetSteps = () =>
  ({ type: types.RESET_STEPS, steps: defaultSteps });
export const deleteOfferRequest = (slug, searchText) =>
  ({ type: types.DELETE_OFFER_API_CALL_REQUEST, slug, searchText });
export const getAttributesRequest = () =>
  ({ type: types.GET_ATTRIBUTES_API_CALL_REQUEST });

export default reducer;
