// @flow

import React, { SyntheticEvent } from 'react';
import { Container } from 'react-bootstrap';

import LoginComponent from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import { connect } from 'react-redux';

import './login.scss';
import {
  updateUser,
  loginRequest,
  resetLoginError,
  requestDemoInvitation,
  forgotPasswordRequest,
  resetPasswordResetRequest,
  resetPasswordSubmitRequest,
} from './reducer';
import {
  setUserPassword,
  setPasswordError,
} from '../Settings/reducer';

import {
  RESET_PASSED,
  RESET_FAILED,
} from '../../common/i18n';
import {
  cloneDeep,
  isUserLoggedIn,
  redirectToDefault,
} from '../../common/utils';
import {
  isFormValidBeforeUpdate,
} from '../Settings/utils';
import * as constants from '../../common/constants';

import { Props, State } from './flowTypes';
import logo from '../../images/complete-logo.png';

export class Login extends React.PureComponent<Props, State> {
  state = {
    passwordResetStatus: undefined,
    isEmailValid: true,
    requestInvitationEmail: '',
    isInvitationEmailValid: false,
    showRequestEmailSection: false,
    invitationSubmitSuccess: false,
    backgroundImage: null,
  }

  componentDidMount() {
    this.setState({
      backgroundImage: require(`../../images/login-page-images/${Math.floor(Math.random() * 20 + 1)}.png`)
    });
  }

  render() {
    const { user } = this.props;
    return isUserLoggedIn() ?
      redirectToDefault(user && user.privilege) : this.renderContent();
  }

  ///////////////////////////////////////////////////////////////////////
  //  RENDER METHODS
  ///////////////////////////////////////////////////////////////////////
  renderContent = () => {
    const { view } = this.props;
    return (
      <div className="login-wrapper">
        <div
            style={{backgroundImage: `radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0) 0%, rgba(234, 236, 245, 0) 100%),
              linear-gradient(209.36deg, #FDEFEA 0%, rgba(255, 255, 255, 0) 100%), url(${this.state.backgroundImage})`}}
            className="login-left-part"
            id="login-left-part"
        >
          <div className="login-logo-container">
            <a href="/">
              <img
                alt="logo"
                src={logo}
                className="login-logo"
              />
            </a>
          </div>
          <div className="login-left-part-main-content">
            <span>🚀</span>
            <p>Get ready to drive outcomes</p>
            <h3>A powerful perks platform that inspires action, engagement, and retention</h3>
            <nav>
              <ul>
                <li><a href="https://paylode.com/platform" target="_blank">Features</a></li>
                <li><a href="https://paylode.com/resources/use-cases" target="_blank">Use cases</a></li>
                <li><a href="https://paylode.com/resources/articles" target="_blank">Articles & insights</a></li>
                <li><a href="https://paylode.com/company/contact" target="_blank">Contact</a></li>
              </ul>
            </nav>
          </div>
          <div className="login-left-part-bottom">
            ©{new Date().getFullYear()} Paylode
          </div>
        </div>
        <div className="login-right-part">
          <div className="login-right-part-logo-mobile">
            <img
              alt="logo"
              src={logo}
              className="login-logo"
            />
          </div>
          <Container className="login-container">
            {view === 'forgot-password' &&
              this.renderForgotPasswordContent()}
            {view === 'reset-password' &&
              this.renderResetPassword()}
            {!view &&
              this.renderLoginContent()}
          </Container>
        </div>
      </div>
    );
  }

  renderResetPassword = () => {
    const {
      history,
      newpassword,
      confirmpassword,
      setUserPassword,
      resetPasswordError,
      setPasswordError,
      match: {
        params: {
          token,
        }
      }
    } = this.props;

    const {
      passwordResetStatus
    } = this.state;

    return (
      <ResetPassword
        token={token}
        history={history}
        newpassword={newpassword}
        confirmpassword={confirmpassword}
        setUserPassword={setUserPassword}
        resetPasswordError={resetPasswordError}
        passwordResetStatus={passwordResetStatus}
        setPasswordError={setPasswordError}
        onFormSubmit={this.handleSubmitResetPassword}
        setParentState={this.setParentState}
      />
    );
  }

  renderForgotPasswordContent = () => {
    const {
      history,
      user,
      error,
      fetching,
      resetPasswordResetRequest,
      resetpasswordlinksent,
    } = this.props;
    const {
      isEmailValid
    } = this.state;
    return (
      <ForgotPassword
        loading={fetching}
        history={history}
        user={user}
        error={error}
        isEmailValid={isEmailValid}
        onLoginFieldsInput={this.onLoginFieldsInput}
        validateEmail={this.validateEmail}
        onSubmit={this.handleForgotPasswordSubmit}
        resetpasswordlinksent={resetpasswordlinksent}
        resetPasswordResetRequest={resetPasswordResetRequest}
      />
    );
  }

  renderLoginContent = () => {
    const { history, user, fetching, error } = this.props;
    const {
      passwordResetStatus,
      isEmailValid,
      requestInvitationEmail,
      isInvitationEmailValid,
      showRequestEmailSection,
      invitationSubmitSuccess,
    } = this.state;
    return (
      <LoginComponent
        passwordResetStatus={passwordResetStatus}
        history={history}
        user={user}
        fetching={fetching}
        error={error}
        isEmailValid={isEmailValid}
        requestInvitationEmail={requestInvitationEmail}
        isInvitationEmailValid={isInvitationEmailValid}
        showRequestEmailSection={showRequestEmailSection}
        invitationSubmitSuccess={invitationSubmitSuccess}
        handleSubmit={this.handleSubmit}
        handleInvitationRequest={this.handleInvitationRequest}
        onLoginFieldsInput={this.onLoginFieldsInput}
        validateEmail={this.validateEmail}
        setParentState={this.setParentState}
      />
    );
  }

  ///////////////////////////////////////////////////////////////////////
  //  EVENT HANDLERS
  ///////////////////////////////////////////////////////////////////////
  setParentState = (stateName, stateValue) => {
    this.setState({
      [stateName]: stateValue,
    });
  }

  handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const { user, loginRequest, history } = this.props;
    loginRequest(user, history);
  }

  handleForgotPasswordSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const { user, forgotPasswordRequest } = this.props;
    this.setParentState('passwordResetStatus', undefined);
    forgotPasswordRequest(user);
  }

  handleSubmitResetPassword = (e: SyntheticEvent) => {
    const {
      match: {
        params: {
          token,
        }
      },
      newpassword,
      confirmpassword,
      setPasswordError,
      resetPasswordSubmitRequest,
    } = this.props;
    e.preventDefault();

    const formValidationObject = isFormValidBeforeUpdate(
      undefined,
      newpassword,
      confirmpassword,
    );

    if (!formValidationObject.valid) {
      setPasswordError(formValidationObject.error);
    } else {
      resetPasswordSubmitRequest(token, newpassword, this.resetPasswordCallback);
    }
  }

  resetPasswordCallback = (type) => {
    const {
      history
    } = this.props;
    switch (type) {
    case 'SUCCESS':
      history.push('/login');
      this.setParentState('passwordResetStatus', RESET_PASSED);
      break;
    case 'ERROR':
      this.setParentState('passwordResetStatus', RESET_FAILED);
      break;
    default:
      break;
    }
  }

  handleInvitationRequest = (e: SyntheticEvent<any>) => {
    const { requestDemoInvitation } = this.props;
    const { requestInvitationEmail } = this.state;
    e.preventDefault();
    requestDemoInvitation(requestInvitationEmail);
    this.setParentState('invitationSubmitSuccess', true);
  }

  onLoginFieldsInput = (e: SyntheticEvent<any>) => {
    const clonedUser = cloneDeep(this.props.user);
    this.props.resetLoginError();
    const { name, value} = e.currentTarget;
    clonedUser[name] = value;
    this.props.updateUser(clonedUser);
  }

  validateEmail = (e: SyntheticEvent<any>) => {
    e.preventDefault();
    const value = e.currentTarget.value.trim();
    const isEmailValid = constants.EMAIL_REGEX.test(value);

    // $FlowFixMe
    if (e.target.name === 'requestEmail') {
      this.setParentState('isInvitationEmailValid', isEmailValid);
    } else {
      this.setParentState('isEmailValid', isEmailValid);
    }
  }

}


///////////////////////////////////////////////////////////////////////
//  REDUX CONNECTION
///////////////////////////////////////////////////////////////////////
function mapStateToProps(state) {
  const { login, setting } = state;
  const {
    password: { newpassword, confirmpassword },
    error: resetPasswordError,
    loading: resetpasswordlaoding
  } = setting.toJS();
  const loginToJS = login.toJS();

  return {
    user: loginToJS.user,
    fetching: loginToJS.fetching,
    error: loginToJS.error,
    newpassword,
    confirmpassword,
    resetpasswordlaoding,
    resetPasswordError,
    resetpasswordlinksent: loginToJS.resetpasswordlinksent,
  };
}

// don't need mapDispatchToProps b/c we are using action creators
export default connect(
  mapStateToProps,
  {
    updateUser,
    loginRequest,
    resetLoginError,
    requestDemoInvitation,
    forgotPasswordRequest,
    resetPasswordResetRequest,
    resetPasswordSubmitRequest,
    setUserPassword,
    setPasswordError,
  }
)(Login);

