import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { loginWatcherSaga } from './features/Login/sagas-login';
import { forgotPwdRequestWatcherSaga } from './features/Login/sagas/forgotPwdRequest';
import { resetPwdRequestWatcherSaga } from './features/Login/sagas/resetPwdRequest';
import { changePasswordWatcherSaga } from './features/Settings/sagas-change-password';
import { updateNewPasswordWatcherSaga } from './features/Settings/sagas-update-newpassword';
import { updateUserWatcherSaga } from './features/Login/sagas-update-user';
import { requestDemoWatcherSaga } from './features/Login/sagas-request-demo';
import { getBuyingMomentsWatcherSaga } from './features/BuyingMoments/sagas-get-buying-moments';
import { getBuyingMomentsDaysWatcherSaga } from './features/BuyingMoments/sagas-get-buying-moment-days';
import { getBuyingMomentsActionsWatcherSaga } from './features/BuyingMoments/sagas-get-buying-moment-actions';
import { createBuyingMomentsWatcherSaga } from './features/BuyingMoments/sagas-create-buying-moments';
import { patchBuyingMomentsWatcherSaga } from './features/BuyingMoments/sagas-patch-buying-moments';
import { getCustomerSegmentsWatcherSaga } from './features/CustomerSegments/sagas-get-customer-segments';
import { createCustomerSegmentsWatcherSaga } from './features/CustomerSegments/sagas-create-customer-segments';
import { updateCustomerSegmentsWatcherSaga } from './features/CustomerSegments/sagas-patch-customer-segments';
import { getCustomerSegmentsFiltersWatcherSaga } from './features/CustomerSegments/sagas-get-customer-segments-filters';
import { getCustomerSegmentsCountWatcherSaga } from './features/CustomerSegments/sagas-get-customer-segments-count';
import { individualCustomerSegmentCountWatcherSaga } from
  './features/CustomerSegments/sagas-individual-customer-segment-count';
import { searchCustomerSegmentsWatcherSaga } from './features/CustomerSegments/sagas-search-customer-segments';
import { getOffersWatcherSaga } from './features/Offers/sagas-get-offers';
import { searchOffersWatcherSaga } from './features/Offers/sagas-search-offers';
import { createOfferWatcherSaga } from './features/Offers/sagas-create-offer';
import { patchUpdateOfferWatcherSaga } from './features/Offers/sagas-patch-update-offer';
import { playPauseOfferWatcherSaga } from './features/Offers/sagas-play-pause-offer';
import { deleteTemplatesWatcherSaga } from './features/Offers/sagas-delete-template';
import { getTemplatesWatcherSaga } from './features/Offers/sagas-get-templates';
import { deleteBuyinMomentWatcherSaga } from './features/BuyingMoments/sagas-delete-buying-moments';
import { uploadTemplateOfferWatcherSaga } from './features/Offers/sagas-upload-template-offer';
import { getChannelsWatcherSaga } from './features/Offers/sagas-get-channels';
import { getCustomerCountPerChannelWatcherSaga } from './features/Offers/sagas-get-customer-count-per-channel';
import { deleteCustomerSegmentWatcherSaga } from './features/CustomerSegments/sagas-delete-customer-segments';
import { deleteOfferWatcherSaga } from './features/Offers/sagas-delete-offer';
import { getAttributesWatcherSaga } from './features/Offers/sagas-get-attributes';
import { getOfferBySlugWatcherSaga } from './features/Offers/sagas-get-offer-by-slug';
import { createTemplateWatcherSaga } from './features/Offers/sagas-create-template';

import { getLandingPageTemplatesWatcherSaga } from './features/LandingPages/sagas-get-templates';
import { deleteLandingPageTemplatesWatcherSaga } from './features/LandingPages/sagas-delete-template';
import { uploadLPTemplateOfferWatcherSaga } from './features/LandingPages/sagas-upload-template-offer';
import { createLPTemplateWatcherSaga } from './features/LandingPages/sagas-create-template';

import { getMetricstWatcherSaga } from './features/Dashboard/sagas-get-metrics';
import { getConversionMetricsWatcherSaga } from './features/Dashboard/sagas-get-offer-conversion-metrics';
import { getPerformanceMetricsWatcherSaga } from './features/Dashboard/sagas-get-performance-metrics';
import { getRevenueWatcherSaga } from './features/Dashboard/sagas-get-revenue';
import { getPartnerOfferClickedWatcherSaga } from './features/Dashboard/sagas-get-offer-clicked';
import { getPerkPageRecommendationsWatcherSaga } from './features/Dashboard/sagas-get-perkpage-recommendations';
import { getOfferRevenuetWatcherSaga } from './features/Offers/sagas-get-offer-revenue';
import { getOfferMetricstWatcherSaga } from './features/Offers/sagas-get-offer-metrics';

import { getMarketPlaceOffersWatcherSaga } from './features/Marketplace/sagas-get-mp-offers' ;
import { getMarketplaceOfferBySlugWatcherSaga } from './features/Marketplace/sagas-get-mp-offer-by-slug';
import { getMarketPlaceOffersLocationsWatcherSaga } from './features/Marketplace/sagas-get-mp-offer-locations';

import { getOfferIndustriesWatcherSaga } from './reducers/sagas-get-industries';
import { getOfferMetricsWatcherSaga } from './reducers/sagas-get-metrics';
import { getOfferLocationWatcherSaga } from './reducers/sagas-get-locations';

import { getAncillaryAPIDetailsWatcherSaga } from './features/AncillaryAPIPage/sagas-get-ancillary-api-details';

import { getPerksWatcherSaga } from './features/Perks/sagas-get-perks';
import { createPerkWatcherSaga } from './features/Perks/sagas-create-perk';
import { deletePerkWatcherSaga } from './features/Perks/sagas-delete-perk';
import { updatePerkWatcherSaga } from './features/Perks/sagas-update-perk';
import { getClientEntitiesWatcherSaga } from './features/Perks/sagas-get-entities';
import { updateThemeWatcherSaga } from './features/Perks/sagas-update-theme';
import { getThemeWatcherSaga } from './features/Perks/sagas-get-theme';
import { getPerkPageStyleWatcherSaga } from './features/Perks/sagas-get-perkpage-style';
import { getFontsWatcherSaga }  from './features/Perks/sagas-get-fonts';
import { getPerkPageConfigWatcherSaga } from './features/Perks/sagas-get-perkpage-config';
import { updatePerkPageConfigWatcherSaga } from './features/Perks/sagas-update-perkpage-config';
import { updatePerkPageStyleWatcherSaga } from './features/Perks/sagas-update-perkpage-style';

import rootReducer from './rootReducer';

import { env } from './config/app_config';
import {getPerkServiceWatcherSaga} from './features/AncillaryAPIPage/sagas-get-perkservice';
import {createPerkServiceWatcherSaga} from './features/AncillaryAPIPage/sagas-create-perkservice';
import {deletePerkServiceWatcherSaga} from './features/AncillaryAPIPage/sagas-delete-perkservice';
import {updatePerkServiceWatcherSaga} from './features/AncillaryAPIPage/sagas-update-perkservice';

import { getClientUsersWatcherSaga } from './features/Settings/sagas/getClientUsers';
import { addClientUsersWatcherSaga } from './features/Settings/sagas/addClientUser';
import { getOfferBrandsWatcherSaga } from './reducers/sagas-get-brands';
import { getClientConfigurationWatcherSaga } from './features/CustomerSegments/sagas-get-client-configuration';
import { uploadConsumerDataFileWatcherSaga } from './features/CustomerSegments/sagas-upload-data-file';

import { getAppConfigurationWatcherSaga } from './features/App/sagas-get-app-configuration';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

let store = createStore(
  rootReducer,
  {},
  composeEnhancers(
    applyMiddleware(sagaMiddleware),
  )
);

if (env.REACT_APP_ENV === 'production' ) {
  store = createStore(
    rootReducer,
    {},
    applyMiddleware(sagaMiddleware),
  );
}

sagaMiddleware.run(loginWatcherSaga);
sagaMiddleware.run(getBuyingMomentsWatcherSaga);
sagaMiddleware.run(getBuyingMomentsDaysWatcherSaga);
sagaMiddleware.run(createBuyingMomentsWatcherSaga);
sagaMiddleware.run(patchBuyingMomentsWatcherSaga);
sagaMiddleware.run(getCustomerSegmentsWatcherSaga);
sagaMiddleware.run(createCustomerSegmentsWatcherSaga);
sagaMiddleware.run(updateCustomerSegmentsWatcherSaga);
sagaMiddleware.run(getCustomerSegmentsFiltersWatcherSaga);
sagaMiddleware.run(getCustomerSegmentsCountWatcherSaga);
sagaMiddleware.run(individualCustomerSegmentCountWatcherSaga);
sagaMiddleware.run(searchCustomerSegmentsWatcherSaga);
sagaMiddleware.run(getOffersWatcherSaga);
sagaMiddleware.run(searchOffersWatcherSaga);
sagaMiddleware.run(createOfferWatcherSaga);
sagaMiddleware.run(patchUpdateOfferWatcherSaga);
sagaMiddleware.run(getTemplatesWatcherSaga);
sagaMiddleware.run(deleteBuyinMomentWatcherSaga);
sagaMiddleware.run(uploadTemplateOfferWatcherSaga);
sagaMiddleware.run(getChannelsWatcherSaga);
sagaMiddleware.run(getCustomerCountPerChannelWatcherSaga);
sagaMiddleware.run(deleteCustomerSegmentWatcherSaga);
sagaMiddleware.run(deleteOfferWatcherSaga);
sagaMiddleware.run(getAttributesWatcherSaga);
sagaMiddleware.run(getOfferBySlugWatcherSaga);
sagaMiddleware.run(playPauseOfferWatcherSaga);
sagaMiddleware.run(deleteTemplatesWatcherSaga);
sagaMiddleware.run(createTemplateWatcherSaga);
sagaMiddleware.run(getLandingPageTemplatesWatcherSaga);
sagaMiddleware.run(deleteLandingPageTemplatesWatcherSaga);
sagaMiddleware.run(uploadLPTemplateOfferWatcherSaga);
sagaMiddleware.run(createLPTemplateWatcherSaga);
sagaMiddleware.run(getMetricstWatcherSaga);
sagaMiddleware.run(getOfferMetricstWatcherSaga);
sagaMiddleware.run(getOfferRevenuetWatcherSaga);
sagaMiddleware.run(getRevenueWatcherSaga);
sagaMiddleware.run(getPartnerOfferClickedWatcherSaga);
sagaMiddleware.run(requestDemoWatcherSaga);
sagaMiddleware.run(getBuyingMomentsActionsWatcherSaga);
sagaMiddleware.run(getMarketPlaceOffersWatcherSaga);
sagaMiddleware.run(getMarketplaceOfferBySlugWatcherSaga);
sagaMiddleware.run(getOfferLocationWatcherSaga);
sagaMiddleware.run(getOfferMetricsWatcherSaga);
sagaMiddleware.run(getOfferIndustriesWatcherSaga);
sagaMiddleware.run(getOfferBrandsWatcherSaga);
sagaMiddleware.run(changePasswordWatcherSaga);
sagaMiddleware.run(forgotPwdRequestWatcherSaga);
sagaMiddleware.run(resetPwdRequestWatcherSaga);
sagaMiddleware.run(getAncillaryAPIDetailsWatcherSaga);
sagaMiddleware.run(getPerksWatcherSaga);
sagaMiddleware.run(createPerkWatcherSaga);
sagaMiddleware.run(deletePerkWatcherSaga);
sagaMiddleware.run(updatePerkWatcherSaga);
sagaMiddleware.run(getClientEntitiesWatcherSaga);
sagaMiddleware.run(updateThemeWatcherSaga);
sagaMiddleware.run(getThemeWatcherSaga);
sagaMiddleware.run(getPerkPageStyleWatcherSaga);
sagaMiddleware.run(getFontsWatcherSaga);
sagaMiddleware.run(getPerkPageConfigWatcherSaga);
sagaMiddleware.run(updatePerkPageConfigWatcherSaga);
sagaMiddleware.run(updatePerkPageStyleWatcherSaga);
sagaMiddleware.run(getPerkServiceWatcherSaga);
sagaMiddleware.run(createPerkServiceWatcherSaga);
sagaMiddleware.run(deletePerkServiceWatcherSaga);
sagaMiddleware.run(updatePerkServiceWatcherSaga);
sagaMiddleware.run(getClientUsersWatcherSaga);
sagaMiddleware.run(addClientUsersWatcherSaga);
sagaMiddleware.run(updateNewPasswordWatcherSaga);
sagaMiddleware.run(updateUserWatcherSaga);
sagaMiddleware.run(getMarketPlaceOffersLocationsWatcherSaga);
sagaMiddleware.run(getClientConfigurationWatcherSaga);
sagaMiddleware.run(uploadConsumerDataFileWatcherSaga);
sagaMiddleware.run(getConversionMetricsWatcherSaga);
sagaMiddleware.run(getPerformanceMetricsWatcherSaga);
sagaMiddleware.run(getAppConfigurationWatcherSaga);
sagaMiddleware.run(getPerkPageRecommendationsWatcherSaga);

export default store;
