export const GET_ANCILLARY_API_DETAILS = 'GET_ANCILLARY_API_DETAILS';
export const GET_ANCILLARY_API_DETAILS_SUCCESS = 'GET_ANCILLARY_API_DETAILS_SUCCESS';
export const GET_ANCILLARY_API_DETAILS_ERROR = 'GET_ANCILLARY_API_DETAILS_ERROR';

export const GET_PERK_SERVICE_CALL_REQUEST = 'GET_PERK_SERVICE_CALL_REQUEST';
export const GET_PERK_SERVICE_CALL_SUCCESS = 'GET_PERK_SERVICE_CALL_SUCCESS';
export const GET_PERK_SERVICE_CALL_FAILURE = 'GET_PERK_SERVICE_CALL_FAILURE';

export const CREATE_PERK_SERVICE_CALL_REQUEST = 'CREATE_PERK_SERVICE_CALL_REQUEST';
export const CREATE_PERK_SERVICE_CALL_SUCCESS = 'CREATE_PERK_SERVICE_CALL_SUCCESS';
export const CREATE_PERK_SERVICE_CALL_FAILURE = 'CREATE_PERK_SERVICE_CALL_FAILURE';

export const DELETE_PERK_SERVICE_CALL_REQUEST = 'DELETE_PERK_SERVICE_CALL_REQUEST';
export const DELETE_PERK_SERVICE_CALL_SUCCESS = 'DELETE_PERK_SERVICE_CALL_SUCCESS';
export const DELETE_PERK_SERVICE_CALL_FAILURE = 'DELETE_PERK_SERVICE_CALL_FAILURE';

export const UPDATE_PERK_SERVICE_CALL_REQUEST = 'UPDATE_PERK_SERVICE_CALL_REQUEST';
export const UPDATE_PERK_SERVICE_CALL_SUCCESS = 'UPDATE_PERK_SERVICE_CALL_SUCCESS';
export const UPDATE_PERK_SERVICE_CALL_FAILURE = 'UPDATE_PERK_SERVICE_CALL_FAILURE';
export const REARRANGE_PERK_SERVICE_CALL_REQUEST = 'REARRANGE_PERK_SERVICE_CALL_REQUEST';
