export const prepareSearchQuery = (locations, industries, brands, showPrivate, search) => {
  const searchParams = {};
  if (locations && locations.length > 0) {
    searchParams['locations'] = locations.map(e => e.id).join(',');
  }
  if (industries && industries.length > 0) {
    searchParams['industries'] = industries.map(e => e.id).join(',');
  }
  if (brands && brands.length > 0) {
    searchParams['brands'] = brands.map(e => e.name).join(',');
  }

  if (search && search.trim().length) {
    searchParams['search'] = search
  }

  if (showPrivate) {
    searchParams['exclusive_only'] = true
  }
  // if (kpis && kpis.length > 0) {
  //   searchParams['kpis'] = kpis.map(e => e.id).join(',');
  // }

  return searchParams;
};
