import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { getClient } from '../../common/utils';

export function* getBuyingMomentsActionsWatcherSaga() {
  yield takeLatest(types.GET_BUYING_MOMENT_ACTIONS_REQUEST, workerSaga);
}

export function fetchData() {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/offer/${client}/buying-moment/actions/`;

  return axios.get(url)
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga() {
  try {
    const response = yield call(fetchData);
    if (response && response.status === 200) {
      const { data } = response;

      yield put({ type: types.GET_BUYING_MOMENT_ACTIONS_SUCCESS, data });
    } else {
      yield put({ type: types.GET_BUYING_MOMENT_ACTIONS_FAILURE, error: response && response.data });
    }
  } catch (error) {
    yield put({ type: types.GET_BUYING_MOMENT_ACTIONS_FAILURE, 'error': error && error.data });
  }
}
