import React from 'react';
import axios from 'axios';
import { Form, ButtonToolbar, Button, Alert } from 'react-bootstrap';

import Link from '../../../../components/Link/Link';
import PageSubHeading from '../../../../components/PageSubHeading';
import FormField from '../../../../components/FormField';

import {
  WELCOME_TO_PAYLODE,
  CONFIRM,
  EXPIRED_TOKEN,
  RESET_FAILED,
} from '../../../../common/i18n';
import config from '../../../../config/app_config';
import { password } from './config';
import { Props, State } from './flowTypes';
import './style.scss';

class ResetPassword extends React.PureComponent<Props, State> {
  render() {
    const {
      newpassword,
      confirmpassword,
      onFormSubmit,
      history,
      passwordResetStatus,
    } = this.props;
    return (
      <>
        <Form
          className={'reset-password-form'}
          onSubmit={onFormSubmit}
        >
          {
            passwordResetStatus === RESET_FAILED && (<Alert variant="danger">
              <span className="icon-error"></span>&nbsp;&nbsp;{EXPIRED_TOKEN}
            </Alert>)
          }
          <PageSubHeading
            className={'reset-password-form-header'}
            headerText={WELCOME_TO_PAYLODE}
          />
          {this.renderFormFields(password)}
          <ButtonToolbar className="reset-password-form-btn-container">
            <Button
              style={{height: '44px', width: '100%'}}
              variant="primary"
              type="submit"
              className="update-password"
              disabled={!newpassword || !confirmpassword}
              onClick={() => null}
            >
              {CONFIRM}
            </Button>
          </ButtonToolbar>
          <Form.Label>Having trouble?</Form.Label>
          <Link
            variant='secondary-link'
            onClick={() => history.push('/forgot-password')}
          >
            Resend Reset Password Link
          </Link>
        </Form>
      </>
    );
  }

  componentDidMount() {
    const {
      token,
      history,
      setParentState,
    } = this.props;
    const url = `${config.API_ROOT_URL}/v1/reset-password/`;

    const authconfig = {
      headers: {
        Authorization: `Token ${token}`,
      }
    };

    axios.put(url, {}, authconfig)
      .then(val => val)
      .catch(() => {
        setParentState('passwordResetStatus', RESET_FAILED);
        history.push('/login');
      });
  }

  //////////////////////////////////////////
  ////////////RENDER METHODS////////////////
  //////////////////////////////////////////
  renderFormFields = (category) => {
    const { resetPasswordError, setUserPassword, setPasswordError } = this.props;
    return Object.keys(category).map((ele => (
      <FormField
        key={ele}
        formClassName={'reset-password-form'}
        label={category[ele].label}
        controlId={category[ele].controlId}
        fieldClassName={category[ele].fieldClassName}
        type={category[ele].type}
        name={category[ele].name}
        value={this.props[ele]}
        placeholder={category[ele].placeholder}
        required={true}
        onFieldsInput={(event) => {
          setUserPassword(ele, event.target.value);
          if (resetPasswordError) {
            setPasswordError(null);
          }
        }}
        error={resetPasswordError}
        disabled={category[ele].disabled}
      />
    )));
  }
}

export default ResetPassword;
