import axios from 'axios';
import { takeEvery, call, put } from 'redux-saga/effects';

import * as types from './types';
import { getMetricsAPIURL } from './utils';
import { getClient } from '../../common/utils';
import { ERROR_GET_METRICS } from '../../common/i18n';

export function* getOfferMetricstWatcherSaga() {
  yield takeEvery(types.GET_OFFER_METRICS_CALL_REQUEST, workerSaga);
}

export function fetchData(slug, entityType, metricType) {
  const client = getClient();
  const url = getMetricsAPIURL(client, entityType, metricType, slug);

  return axios.get(url, {})
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga(action) {
  try {
    const { slug, entityType, metricType } = action;
    const response = yield call(fetchData, slug, entityType, metricType);

    if (response && response.status === 200) {
      const { data } = response;

      yield put({ type: types.GET_OFFER_METRICS_CALL_SUCCESS, data, metricType });

    } else {
      yield put({ type: types.GET_OFFER_METRICS_CALL_FAILURE, error: ERROR_GET_METRICS });
    }
  } catch (error) {
    yield put({ type: types.GET_OFFER_METRICS_CALL_FAILURE, error });
  }
}
