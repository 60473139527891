import React, { Suspense } from 'react';
import Spinner from '../../components/Spinner/Spinner';
const DashboardComponent = React.lazy(() => import('./../Dashboard/dashboard'));
const BuyingMomentsComponent = React.lazy(() => import('./../BuyingMoments/buyingmoments'));
const CustomerSegmentsComponent = React.lazy(() => import('./../CustomerSegments/customersegments'));
const LandingPageComponent = React.lazy(() => import('./../LandingPages/landingpages'));
const OffersComponent = React.lazy(() => import('./../Offers/offers'));
const OfferDescriptionComponent = React.lazy(() => import('../OfferDescription'));
const CreateOfferComponent = React.lazy(() => import('./../Offers/CreateOffer/createoffer'));
const SettingsComponent = React.lazy(() => import('./../Settings'));
const CreateBuyingMomentsComponent =
  React.lazy(()=> import('./../BuyingMoments/CreateBuyingMoments/createbuyingmoment'));
const CreateCustomerSegmentsComponent = React.lazy(()=> import('./../CustomerSegments/CreateCustomerSegments'));
const SendToTestComponent = React.lazy(() => import('./../Offers/CreateOffer/SendToTest/SendToTest'));
const MarketplaceComponent = React.lazy(() => import('./../Marketplace'));
const MarketplaceDescComponent = React.lazy(() => import('./../MarketplaceOfferDescription'));
const PerksComponent = React.lazy(() => import('./../Perks'));
const AncillaryAPIDetailsComponent = React.lazy(() => import('../AncillaryAPIPage'));

const spinnerWrapper = () => <Spinner left={'50%'} top={'50%'} fontSize={'4rem'} />;

export const Dashboard = (props) => {
  return (
    <Suspense fallback={spinnerWrapper()}>
      <DashboardComponent {...props}/>
    </Suspense>
  );
};

export const BuyingMoments = (props) => {
  return (
    <Suspense fallback={spinnerWrapper()}>
      <BuyingMomentsComponent {...props}/>
    </Suspense>
  );
};

export const CustomerSegments = (props) => {
  return (
    <Suspense fallback={spinnerWrapper()}>
      <CustomerSegmentsComponent {...props}/>
    </Suspense>
  );
};

export const LandingPages = (props) => {
  return (
    <Suspense fallback={spinnerWrapper()}>
      <LandingPageComponent {...props}/>
    </Suspense>
  );
};


export const Offers = (props) => {
  return (
    <Suspense fallback={spinnerWrapper()}>
      <OffersComponent {...props}/>
    </Suspense>
  );
};

export const OfferDescription = (props) => {
  return (
    <Suspense fallback={spinnerWrapper()}>
      <OfferDescriptionComponent {...props}/>
    </Suspense>
  );
};

export const CreateOffer = (props) => {
  return (
    <Suspense fallback={spinnerWrapper()}>
      <CreateOfferComponent {...props}/>
    </Suspense>
  );
};

export const Settings = (props) => {
  return (
    <Suspense fallback={spinnerWrapper()}>
      <SettingsComponent {...props}/>
    </Suspense>
  );
};

export const Marketplace = (props) => {
  return (
    <Suspense fallback={spinnerWrapper()}>
      <MarketplaceComponent {...props}/>
    </Suspense>
  );
};

export const MarketplaceDescription = (props) => {
  return (
    <Suspense fallback={spinnerWrapper()}>
      <MarketplaceDescComponent {...props} />
    </Suspense>
  );
};

export const AncillaryAPIDetails = (props) => {
  return (
    <Suspense fallback={spinnerWrapper()}>
      <AncillaryAPIDetailsComponent {...props}/>
    </Suspense>
  );
};

export const Perks = (props) => {
  return (
    <Suspense fallback={spinnerWrapper()}>
      <PerksComponent {...props} />
    </Suspense>
  );
};

/**
 * Non direct route components
 */
export const CreateBuyingMoments = (props) => {
  return (
    <Suspense fallback={spinnerWrapper()}>
      <CreateBuyingMomentsComponent {...props}/>
    </Suspense>
  );
};

export const CreateCustomerSegments = (props) => {
  return (
    <Suspense fallback={spinnerWrapper()}>
      <CreateCustomerSegmentsComponent {...props}/>
    </Suspense>
  );
};

export const SendToTest = (props) => {
  return (
    <Suspense fallback={spinnerWrapper()}>
      <SendToTestComponent {...props}/>
    </Suspense>
  );
};
