import axios from 'axios';
import { takeEvery, call, put, select } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { getClient, storeUser } from '../../common/utils';

export function* updateUserWatcherSaga() {
  yield takeEvery(types.UPDATE_USER_REQUEST, workerSaga);
}

export function postData(data) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/user/${client}/user/`;

  return axios.post(url, data)
    .then(val => val)
    .catch(error => error.response);
}

export function* workerSaga(action) {
  try {
    const state = yield select();
    const userLoginState = state.login.toJS().user;

    const { data, callback } = action;
    const response = yield call(postData, data);

    if (response && response.status === 202) {
      storeUser(
        JSON.stringify({
          ...userLoginState,
          display_change_password: false,
        })
      );
      yield put({
        type: types.UPDATE_USER,
        user: {
          ...userLoginState,
          display_change_password: false,
        }
      });
    } else {
      yield put({ type: types.UPDATE_USER_FAILURE });
    }
    callback();
  } catch (error) {
    yield put({ type: types.UPDATE_USER_FAILURE });
  }
}
