import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from '../types';
import config from '../../../config/app_config';
import { getClient } from '../../../common/utils';
export function* getClientUsersWatcherSaga() {
  yield takeLatest(types.FETCH_CLIENT_USERS_REQUEST, workerSaga);
}

export function fetchData() {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/client/${client}/users/`;


  return axios.get(url)
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga() {
  try {
    const response = yield call(fetchData);
    if (response && response.status === 200) {
      const { data } = response;
      yield put({ type: types.FETCH_CLIENT_USERS_SUCCESS, data: data.results });
    } else {
      yield put({ type: types.FETCH_CLIENT_USERS_FAILURE});
    }
  } catch (error) {
    yield put({ type: types.FETCH_CLIENT_USERS_FAILURE, error });
  }
}
