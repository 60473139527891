import { useEffect, useMemo } from "react";
import { useLocation } from 'react-router-dom';

const trackPageContext = (pageName: string) => {
    window.rudderanalytics.page("", pageName, {
        path: window.location.pathname,
        referrer: document.referrer,
        title: document.title,
        url: window.location.href,
    });
};

export const useRudderstack = () => {
    const location = useLocation();

    const pathname = useMemo(() => location.pathname, [location.pathname]);

    useEffect(() => {
        trackPageContext(pathname);
    }, [pathname]);
};
