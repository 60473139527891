import axios from 'axios';
import { takeEvery, call } from 'redux-saga/effects';
import * as types from '../types';
import config from '../../../config/app_config';


export function* resetPwdRequestWatcherSaga() {
  yield takeEvery(types.RESET_PWD_API_CALL_REQUEST, workerSaga);
}

export function updateData(data, token) {
  const url = `${config.API_ROOT_URL}/v1/reset-password/`;

  const authconfig = {
    headers: {
      Authorization: `Token ${token}`,
    }
  };

  return axios.put(url, data, authconfig)
    .then(val => val)
    .catch(err => err.response);
}

export function* workerSaga(action) {
  try {
    const { password, token, callback } = action;
    const response = yield call(updateData, {
      'new_password': password,
    }, token);

    if (response && response.status === 200) {
      // yield put({
      //   type: types.RESET_PWD_API_CALL_SUCCESS,
      // });
      callback('SUCCESS');

    } else {
      // yield put({ type: types.RESET_PWD_API_CALL_FAILURE, error: response.data });
      callback('ERROR');
    }
  } catch (error) {
    // yield put({ type: types.RESET_PWD_API_CALL_FAILURE, error });
  }
}
