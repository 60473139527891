import * as globalTypes from '../../globalTypes';
import * as offerTypes from './../../reducers/types';
import * as types from './types';
import { fromJS } from 'immutable';
import { initialState } from './initialState';


const reducer = (state = initialState, action) => {
  switch (action.type) {
  case globalTypes.UPDATE_SIDE_DRAWER_STATE:
    return state.setIn(['sideDrawerState'], action.open);

  case globalTypes.UPDATE_MODAL_STATE:
    return state.setIn(['showModal'], action.open);

  case globalTypes.CLEAR_REDUX_STORE:
    return state;

  case globalTypes.SET_CURRENT_HEADER:
    return state.setIn(['currentHeader'], action.header);
    case globalTypes.SET_CURRENT_SUBTITLE:
      return state.setIn(['currentSubtitle'], action.subtitle);
  case offerTypes.GET_OFFER_INDUSTRIES_SUCCESS:
    return state.setIn(['industries'], action.data);
  case offerTypes.GET_OFFER_LOCATIONS_SUCCESS:
    return state.setIn(['locations'], action.data.results);
  case offerTypes.GET_OFFER_METRICS_SUCCESS:
    return state.setIn(['metrics'], action.data.results);
  case offerTypes.GET_OFFER_BRANDS_SUCCESS:
    return state.setIn(['brands'], action.data.results);

  case types.GET_APP_CONFIGURATION_REQUEST:
    return state.setIn(['appConfiguration', 'fetching'], true)
      .setIn(['appConfiguration', 'configuration'], null);
  case types.GET_APP_CONFIGURATION_SUCCESS:
    return state.setIn(['appConfiguration', 'fetching'], false)
      .setIn(['appConfiguration', 'configuration'], fromJS(action.data));
  case types.GET_APP_CONFIGURATION_FAILED:
    return state.setIn(['appConfiguration', 'fetching'], false)
      .setIn(['appConfiguration', 'configuration'], null);

  default:
    return state;
  }
};

// ACTION CREATORS
export const updateSideDrawerState = (open) => ({ type: globalTypes.UPDATE_SIDE_DRAWER_STATE, open });

export const updateModalState = (open) => ({ type: globalTypes.UPDATE_MODAL_STATE, open });

export const clearReduxStore = () => ({ type: globalTypes.CLEAR_REDUX_STORE });

export const setCurrentHeader = (header) => ({ type: globalTypes.SET_CURRENT_HEADER, header });
export const setCurrentSubtitle = (subtitle) => ({ type: globalTypes.SET_CURRENT_SUBTITLE, subtitle });

export const getAppConfigurationRequest = () => ({ type: types.GET_APP_CONFIGURATION_REQUEST });

export default reducer;
