import axios from 'axios';
import { takeEvery, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { getClient } from '../../common/utils';
import { ERROR_GET_REVENUE_FAILED } from '../../common/i18n';

export function* getOfferRevenuetWatcherSaga() {
  yield takeEvery(types.GET_OFFER_REVENUE_CALL_REQUEST, workerSaga);
}

export function fetchData(offerSlug, type) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/offer/${client}/revenue/${offerSlug}/?type=${type}`;

  return axios.get(url, {})
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga(action) {
  try {
    const { slug, entityType } = action;
    const response = yield call(fetchData, slug, entityType);

    if (response && response.status === 200) {
      const { data } = response;

      yield put({ type: types.GET_OFFER_REVENUE_CALL_SUCCESS, data });

    } else {
      yield put({ type: types.GET_OFFER_REVENUE_CALL_FAILURE, error: ERROR_GET_REVENUE_FAILED });
    }
  } catch (error) {
    yield put({ type: types.GET_OFFER_REVENUE_CALL_FAILURE, error });
  }
}
