import axios from 'axios';
import { takeEvery, call, put, all } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { getClient, getParams } from '../../common/utils';
import { TEMPLATE_TYPE } from './constants';

const OCTET_STREAM = 'application/octet-stream';
const CONTENT_TYPE = 'content-type';

export function* uploadLPTemplateOfferWatcherSaga() {
  yield takeEvery(types.UPLOAD_LP_TEMPLATE_API_CALL_REQUEST, workerSaga);
}

export function getGeneratedUrl(templateName) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/client/${client}/generate_presigned_url/landing-page/?template_name=${templateName}`;

  return axios.get(url)
    .then(val => val)
    .catch(err => err.response);
}

export function postTemplateJSON(url, json) {
  const finalUrl = url.split('?');
  const params = getParams(url);
  delete params[CONTENT_TYPE];

  return fetch(finalUrl[0], {
    method: 'put',
    headers: {...params, 'Content-Type': OCTET_STREAM},
    body: JSON.stringify(json)
  })
    .then(val => val)
    .catch(error => console.log(error));
}

export function postTemplateHTML(url, html) {
  const finalUrl = url.split('?');
  const params = getParams(url);
  delete params[CONTENT_TYPE];

  return fetch(finalUrl[0], {
    method: 'put',
    headers: {...params, 'Content-Type': OCTET_STREAM},
    body: html
  })
    .then(val => val)
    .catch(error => error.response);
}

export function* workerSaga(action) {
  try {
    const { data: uploadTemplateData, templateData, callBack } = action;
    const landing_page = {};
    const { json, html, externalURL, file_name, template_type } = uploadTemplateData;

    landing_page.name = file_name;
    landing_page.template_type = template_type || TEMPLATE_TYPE;

    const genratedUrlResponse = yield call(getGeneratedUrl, file_name);

    if (genratedUrlResponse && genratedUrlResponse.status === 200) {
      const { json_key, json_url, html_key, html_url, thumbnail_key, thumbnail_url } = genratedUrlResponse.data;

      landing_page.thumbnail_url = thumbnail_url; //  Required for postThumbnail on template create

      let createTemplateData = {
        isEdit: templateData ? true : false,
        slug: templateData ? templateData.slug : undefined,
      };

      if (externalURL) {

        createTemplateData = {
          ...createTemplateData,
          ...landing_page,
          html_url: externalURL,
          thumbnail_key: thumbnail_key,
          thumbnail_url: thumbnail_url,
          isExternal: true,
        };

        yield put({ type: types.CREATE_LP_TEMPLATE_API_CALL_REQUEST, data: createTemplateData, callBack });

      } else {

        landing_page.json_key = json_key;
        landing_page.html_key = html_key;
        landing_page.thumbnail_key = thumbnail_key;

        // effects will get executed in parallel
        const [postTemplateJSONResponse, postTemplateHTMLResponse] = yield all([
          call(postTemplateJSON, json_url, json),
          call(postTemplateHTML, html_url, html),
          // call(postThumbnail, thumbnail_url, thumbnail),
        ]);

        if (postTemplateJSONResponse && postTemplateJSONResponse.status === 200
          && postTemplateHTMLResponse && postTemplateHTMLResponse.status === 200
        // && postThumbnailResponse && postThumbnailResponse.status === 200
        ) {
          createTemplateData = {
            ...createTemplateData,
            ...landing_page,
          };

          yield put({ type: types.CREATE_LP_TEMPLATE_API_CALL_REQUEST, data: createTemplateData, callBack });

        } else {
          yield put({ type: types.UPLOAD_LP_TEMPLATE_API_CALL_FAILURE, error: true });
        }
      }

    } else {
      yield put({ type: types.UPLOAD_LP_TEMPLATE_API_CALL_FAILURE, error: true });
    }

  } catch (error) {
    yield put({ type: types.UPLOAD_LP_TEMPLATE_API_CALL_FAILURE, error });
  }
}
