/**
 * Environment specific configuration
 */
export const env = process.env;
const THUMBNAIL_URL = 'https://template.paylode.com/';
const PERKS_PAGE_URL = 'https://production-paylode.s3-us-west-1.amazonaws.com/media/perks_page/perkspage.png';

const dev = {
  ENV: 'dev',
  API_ROOT_URL: 'https://api-staging.pde1.com/api',
  WEBAPP_ROOT_URL: 'http://localhost:3000',
  WEBAPP_V2_ROOT_URL: 'https://app.paylode.com/v2',
  PERK_PAGE_ROOT_URL: 'http://localhost:3000',
  HTMLToImagEndPoint: THUMBNAIL_URL,
  PERKS_PAGE: PERKS_PAGE_URL,
  PERK_PAGE_API_URL: 'https://api.pde1.com/stage',
  PERK_PAGE_STYLE_API: 'https://api.pde1.com/stage',
  RECAP_API: 'https://recap.paylode.tech',
  BOOST_API: 'https://boost.paylode.tech',
  PERKS_CENTERS_API: 'https://perks-center.paylode.tech'
};
const stag = {
  ENV: 'staging',
  API_ROOT_URL: 'https://api-staging.pde1.com/api',
  WEBAPP_ROOT_URL: 'https://app.pde1.com',
  WEBAPP_V2_ROOT_URL: 'https://app.paylode.com/v2',
  PERK_PAGE_ROOT_URL: 'https://www.pde1.com',
  HTMLToImagEndPoint: THUMBNAIL_URL,
  PERKS_PAGE: PERKS_PAGE_URL,
  PERK_PAGE_API_URL: 'https://api.pde1.com/stage',
  PERK_PAGE_STYLE_API: 'https://api.pde1.com/stage',
  RECAP_API: 'https://recap.paylode.tech',
  BOOST_API: 'https://boost.paylode.tech',
  PERKS_CENTERS_API: 'https://perks-center.paylode.tech'
};
const prod = {
  ENV: 'production',
  API_ROOT_URL: 'https://api.paylode.com/api',
  WEBAPP_ROOT_URL: 'https://app.paylode.com',
  WEBAPP_V2_ROOT_URL: 'https://app.paylode.com/v2',
  PERK_PAGE_ROOT_URL: 'https://www.paylode.com',
  HTMLToImagEndPoint: THUMBNAIL_URL,
  PERKS_PAGE: PERKS_PAGE_URL,
  PERK_PAGE_API_URL: 'https://event.paylode.com/api',
  PERK_PAGE_STYLE_API: 'https://perk.paylode.com/api',
  RECAP_API: 'https://recap.paylode.tech',
  BOOST_API: 'https://boost.paylode.tech',
  PERKS_CENTERS_API: 'https://perks-center.paylode.tech'
};

const config =
  env.REACT_APP_ENV === 'production' ? prod : env.REACT_APP_ENV === 'staging' ?
    stag :
    dev;

export default {
  ...config,
};
