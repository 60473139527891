import * as types from './types';
import { fromJS } from 'immutable';
import { initialState } from './initialState';


const reducer = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
  case types.GET_MP_OFFERS_CALL_REQUEST:
  case types.GET_MP_OFFER_BY_SLUG_API_CALL_REQUEST:
  case types.GET_MP_OFFERS_LOCATIONS_REQUEST:
    return state.setIn(['fetching'], true)
      .setIn(['error'], null);
  case types.GET_MP_OFFERS_CALL_SUCCESS: {
    const total = data && data.count;

    return state.setIn(['fetching'], false)
      .setIn(['error'], false)
      .setIn(['marketplaceoffers', 'total'], total)
      .setIn(['marketplaceoffers', 'items'], fromJS(data.results))
        .setIn(['updatedFilter'], null);
  }
  case types.GET_MP_OFFER_BY_SLUG_API_CALL_SUCCESS:
    return state.setIn(['selectedMarketplace'], data);
  case types.GET_MP_OFFERS_LOCATIONS_SUCCESS: {
    const locations = data.results.map(ele => {
      const id = `${ele.country}/${ele.state}/${ele.city || ''}`;
      const name = `${ele.country} / ${ele.state} ${ele.city ? ('/ ' + ele.city) : ''}`.trim();
      return {
        id,
        name,
        displayName: name
      };
    });
    return state.setIn(['allAvailableOffersLocations'], locations);
  }
  case types.GET_MP_OFFERS_CALL_FAILURE:
  case types.GET_MP_OFFER_BY_SLUG_API_CALL_FAILURE:
  case types.GET_MP_OFFERS_LOCATIONS_FAILURE:
    return state.setIn(['fetching'], false)
      .setIn(['error'], fromJS(action.error))
        .setIn(['updatedFilter'], null);
  case types.SET_SELECTED_FILTERS:
    return state
        .setIn([action.key], action.value)
        .setIn(['updatedFilter'], action.key)
  default:
    return state;
  }
};

// Action Creators
export const getMPOffersRequest = (locations, industries, brands, showPrivate, search) => ({
  type: types.GET_MP_OFFERS_CALL_REQUEST,
  locations,
  industries,
  brands,
  showPrivate,
  search
});
export const getMPOfferBySlugRequest = (slug) => ({ type: types.GET_MP_OFFER_BY_SLUG_API_CALL_REQUEST, slug });
export const setSelectedFilters = (key, value) => ({ type: types.SET_SELECTED_FILTERS, key, value });
export const getMPOffersLocationsRequest = (slug) => ({type: types.GET_MP_OFFERS_LOCATIONS_REQUEST, slug});

export default reducer;
