import * as types from './types';
import { fromJS } from 'immutable';
import { initialState, defaultCustomerSegment } from './initialState';
import { manipulateAllFilters, cloneDeep, resetPagination } from '../../common/utils';


const reducer = (state = initialState, action) => {
  const activePage = state.getIn(['customerSegments', 'activePage']);
  switch (action.type) {
  case types.UPDATE_SELECTED_CUSTOMER_SEGMENT:
    return state.setIn(['selectedCustomerSegment'], action.customerSegment);

  case types.GET_CUSTOMER_SEGMENTS_API_CALL_REQUEST:
  case types.SEARCH_CUSTOMER_SEGMENTS_API_CALL_REQUEST:
  case types.GET_CUSTOMER_SEGMENTS_FILTERS_API_CALL_REQUEST:
  case types.GET_CUSTOMER_SEGMENTS_COUNT_API_CALL_REQUEST:
    return state.setIn(['fetching'], true)
      .setIn(['error'], false);
  case types.GET_CUSTOMER_SEGMENTS_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['error'], false)
      .updateIn(['customerSegments', 'items'], items => {
        const clonedResults = items.length === 0 ? items : cloneDeep(items.toJS());
        return fromJS({ ...clonedResults, ...action.data.results });
      })
      .setIn(['customerSegments', 'total'], action.data.count)
      .updateIn(['customerSegments', 'pages'], pages => {
        const clonedPages = pages.toJS();
        clonedPages[action.data.page] = action.data.results;
        return fromJS(clonedPages);
      });
  case types.GET_CUSTOMER_SEGMENTS_API_CALL_FAILURE:
  case types.GET_CUSTOMER_SEGMENTS_FILTERS_API_CALL_FAILURE:
  case types.GET_CUSTOMER_SEGMENTS_COUNT_API_CALL_FAILURE:
  case types.SEARCH_CUSTOMER_SEGMENTS_API_CALL_FAILURE:
    return state.setIn(['fetching'], false)
      .setIn(['error'], action.error);
  case types.GET_CUSTOMER_SEGMENTS_API_CALL_CANCELLED:
  case types.SEARCH_CUSTOMER_SEGMENTS_API_CALL_CANCELLED:
    return state.setIn(['fetching'], false)
      .setIn(['error'], false);

  case types.UPDATE_CUSTOMER_SEGMENTS_LIST:
    return state.setIn(['customerSegments', 'activePage'], action.activePage);

  case types.RESET_SELECTED_CUSTOMER_SEGMENT_TO_DEFAULT:
    return state.setIn(['selectedCustomerSegment'], defaultCustomerSegment);

  case types.CREATE_CUSTOMER_SEGMENTS_API_CALL_REQUEST:
  case types.UPDATE_CUSTOMER_SEGMENTS_API_CALL_REQUEST:
    return state.setIn(['fetching'], true)
      .setIn(['createAndUpdateError'], false);

  case types.CREATE_CUSTOMER_SEGMENTS_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['createAndUpdateError'], false)
      .updateIn(['customerSegments', 'total'], total => total + 1 )
      .updateIn(['customerSegments'], customerSegments => {
        const clonedCustomerSegments = cloneDeep(customerSegments.toJS());
        resetPagination(clonedCustomerSegments, action.data, false, 'customersegments');
        return fromJS(clonedCustomerSegments);
      });
  case types.UPDATE_CUSTOMER_SEGMENTS_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .updateIn(['customerSegments', 'pages'], pages => {
        const clonePages = pages.toJS();
        // TODO , why active page was not reset after resetting pages
        if (clonePages && clonePages[activePage]) {
          const updatedPage = clonePages[activePage].map(ele => {
            if (ele.slug === action.oldCSSlug) {
              ele = action.data;
            }
            return ele;
          });
          clonePages[activePage] = updatedPage;
        }
        return fromJS(clonePages);
      })
      .setIn(['createAndUpdateError'], false);

  case types.CREATE_CUSTOMER_SEGMENTS_API_CALL_FAILURE:
  case types.UPDATE_CUSTOMER_SEGMENTS_API_CALL_FAILURE:
    return state.setIn(['fetching'], false)
      .setIn(['createAndUpdateError'], action.error);

  case types.GET_CUSTOMER_SEGMENTS_FILTERS_API_CALL_SUCCESS: {
    const editedSegmentFilter = state.getIn(['selectedCustomerSegment']).filters;
    const updatedFiltersItem = manipulateAllFilters(action.data, editedSegmentFilter, action.isEdit);
    return state.setIn(['fetching'], false)
      .setIn(['error'], false)
      .setIn(['filters'], action.data)
      .setIn(['selectedCustomerSegment', 'filters'], updatedFiltersItem);

  }
  case types.UPDATE_CUSTOMER_SEGMENTS_FILTERS:
    return state.setIn(['selectedCustomerSegment', 'filters'], action.filters);

  case types.RESET_CUSTOMER_SEGMENTS: {
    const customerSegments = {
      items: [],
      total: 0,
      pages: {},
      activePage: 1,
      countFetching: false,
      searchResultPages: {},
    };
    return state.setIn(['customerSegments'], fromJS(customerSegments));
  }
  case types.GET_CUSTOMER_SEGMENTS_COUNT_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['error'], false)
      .setIn(['totalCustomers'], action.data);

  case types.INDIVIDUAL_CUSTOMER_SEGMENT_COUNT_API_CALL_REQUEST:
    return state.setIn(['customerSegments', 'countFetching'], true)
      .setIn(['error'], false);
  case types.INDIVIDUAL_CUSTOMER_SEGMENT_COUNT_API_CALL_SUCCESS:
    return state.setIn(['customerSegments', 'countFetching'], false)
      .setIn(['error'], false)
      .updateIn(['customerSegments', 'pages'], pages => {
        const clonedPages = pages.toJS();
        const page = clonedPages[activePage]; //Active page
        const slug = action && action.data && action.data.slug;
        const updatedItemIndex = page && page.findIndex(item => item.slug === slug);
        const count = action && action.data && action.data.count;
        if (typeof updatedItemIndex !== 'undefined') {
          page[updatedItemIndex] = { ...page[updatedItemIndex], count};
        }
        return fromJS(clonedPages);
      })
      .updateIn(['customerSegments', 'searchResultPages'], searchResultPages => {
        const clonedPages = searchResultPages.toJS();
        const page = clonedPages[activePage]; //Active page
        const slug = action && action.data && action.data.slug;
        const updatedItemIndex = page && page.findIndex(item => item.slug === slug);
        const count = action && action.data && action.data.count;
        if (typeof updatedItemIndex !== 'undefined') {
          page[updatedItemIndex] = { ...page[updatedItemIndex], count};
        }
        return fromJS(clonedPages);
      });
  case types.INDIVIDUAL_CUSTOMER_SEGMENT_COUNT_API_CALL_FAILURE:
    return state.setIn(['customerSegments', 'countFetching'], false)
      .setIn(['error'], action.error);

  case types.SEARCH_CUSTOMER_SEGMENTS_API_CALL_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['error'], false)
      .setIn(['customerSegments', 'total'], action.data.count)
      .updateIn(['customerSegments', 'searchResultPages'], pages => {
        const clonedPages = pages.toJS();
        clonedPages[action.data.page] = action.data.results;
        return fromJS(clonedPages);
      });

  case types.RESET_CUSTOMER_SEGMENTS_ERROR:
    return state.setIn(['createAndUpdateError'], false);

  case types.DELETE_CUSTOMER_SEGMENTS_API_CALL_REQUEST:
    return state.setIn(['fetching'], true)
      .setIn(['deleteError'], false);
  case types.DELETE_CUSTOMER_SEGMENTS_API_CALL_SUCCESS: {
    const deletedSlug = action.slug;
    return state.setIn(['fetching'], false)
      .setIn(['deleteError'], false)
      .updateIn(['customerSegments', 'total'], total => total - 1 )
      .updateIn(['customerSegments'], customerSegments => {
        const clonedCustomerSegments = customerSegments.toJS();
        resetPagination(clonedCustomerSegments, deletedSlug, true, 'customersegments');
        return fromJS(clonedCustomerSegments);
      });
  }
  case types.DELETE_CUSTOMER_SEGMENTS_API_CALL_FAILURE:
    return state.setIn(['fetching'], false)
      .setIn(['deleteError'], action.error);
  case types.GET_CLIENT_INTEGRATION_CONFIGURATION_SUCCESS:
    return state.setIn(['clientIntegrationConfig'], action.data);

  case types.UPLOAD_CONSUMER_DATA_FILE_REQUEST:
    return state.setIn(['uploadDataFile', 'uploading'], true);
  case types.UPLOAD_CONSUMER_DATA_FILE_SUCCESS:
    return state.setIn(['uploadDataFile', 'uploading'], false);
  case types.UPLOAD_CONSUMER_DATA_FILE_FAILURE:
    return state.setIn(['uploadDataFile', 'uploading'], false);

  default:
    return state;
  }
};


// ACTION CREATORS
export const updateSelectedCustomerSegment = (customerSegment, updateCache) => (
  { type: types.UPDATE_SELECTED_CUSTOMER_SEGMENT, customerSegment, updateCache }
);
export const resetSelectedCustomerSegmentToDefault = () => ({ type: types.RESET_SELECTED_CUSTOMER_SEGMENT_TO_DEFAULT });
export const getCustomerSegmentsRequest = (page) => ({ type: types.GET_CUSTOMER_SEGMENTS_API_CALL_REQUEST, page});
export const updateCustomerSegmentsList = (activePage) => ({ type: types.UPDATE_CUSTOMER_SEGMENTS_LIST, activePage });
export const createCustomerSegmentsRequest = (data, history) => (
  { type: types.CREATE_CUSTOMER_SEGMENTS_API_CALL_REQUEST, data, history}
);
export const patchCustomerSegmentsRequest = (data) => ({ type: types.UPDATE_CUSTOMER_SEGMENTS_API_CALL_REQUEST, data });
export const getCustomerSegmentsFiltersRequest = (isEdit) => (
  { type: types.GET_CUSTOMER_SEGMENTS_FILTERS_API_CALL_REQUEST, isEdit}
);

export const updateCustomerSegmentsFilters = (filters) => ({ type: types.UPDATE_CUSTOMER_SEGMENTS_FILTERS, filters });
export const searchCustomerSegmentsRequest = (page, search) => (
  { type: types.SEARCH_CUSTOMER_SEGMENTS_API_CALL_REQUEST, page, search}
);
export const resetCustomerSegments = () => ({ type: types.RESET_CUSTOMER_SEGMENTS });
export const getCustomerSegmentsCountRequest = () => ({ type: types.GET_CUSTOMER_SEGMENTS_COUNT_API_CALL_REQUEST });
export const resetCustomerSegmentsError= () => ({ type: types.RESET_CUSTOMER_SEGMENTS_ERROR });
export const deleteCustomerSegmentsRequest = (slug) => (
  { type: types.DELETE_CUSTOMER_SEGMENTS_API_CALL_REQUEST, slug }
);
export const getClientIntegrationConfiguration = () => ({ type: types.GET_CLIENT_INTEGRATION_CONFIGURATION });
export const uploadConsumerDataFile = (filename, file, callback) => ({ type: types.UPLOAD_CONSUMER_DATA_FILE_REQUEST, filename, file, callback });

export default reducer;
