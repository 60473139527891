import axios from 'axios';
import { takeEvery, call, put, select, all } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { getClient, getParams } from '../../common/utils';

const OCTET_STREAM = 'application/octet-stream';
const CONTENT_TYPE = 'content-type';

export function* uploadTemplateOfferWatcherSaga() {
  yield takeEvery(types.UPLOAD_TEMPLATE_API_CALL_REQUEST, workerSaga);

}

export function getGeneratedUrl(templateName) {
  const client = getClient();
  const url =
  `${config.API_ROOT_URL}/v1/client/${client}/generate_presigned_url/landing-page/?template_name=${templateName}`;

  return axios.get(url)
    .then(val => val)
    .catch(err => err.response);
}

export function postTemplateJSON(url, json) {
  const finalUrl = url.split('?');
  const params = getParams(url);
  delete params[CONTENT_TYPE];

  return fetch(finalUrl[0], {
    method: 'put',
    headers: {...params, 'Content-Type': OCTET_STREAM},
    body: JSON.stringify(json)
  })
    .then(val => val)
    .catch(error => console.log(error));
}

export function postTemplateHTML(url, html) {
  const finalUrl = url.split('?');
  const params = getParams(url);
  delete params[CONTENT_TYPE];

  return fetch(finalUrl[0], {
    method: 'put',
    headers: {...params, 'Content-Type': OCTET_STREAM},
    body: html
  })
    .then(val => val)
    .catch(error => console.log(error));
}

export function postThumbnail(url, thumbnail) {
  const finalUrl = url.split('?');
  const params = getParams(url);
  delete params[CONTENT_TYPE];

  return fetch(finalUrl[0], {
    method: 'put',
    headers: {...params, 'Content-Type': OCTET_STREAM},
    body: thumbnail
  })
    .then(val => val)
    .catch(error => console.log(error));
}

export function* workerSaga(action) {
  try {
    const state = yield select();
    const uploadTemplateData = action.data;
    const { activeStep, selectedOffer } = state.offers.toJS();
    const { landing_page } = selectedOffer;
    const { json, html, thumbnail } = uploadTemplateData;

    let genratedUrlResponse;

    if (activeStep === 3) {
      const { file_name } = uploadTemplateData;
      landing_page.name = file_name;
      genratedUrlResponse = yield call(getGeneratedUrl, file_name);
    }

    if (genratedUrlResponse && genratedUrlResponse.status === 200) {
      const { json_key, json_url, html_key, html_url, thumbnail_key, thumbnail_url } = genratedUrlResponse.data;

      landing_page.json_key = json_key;
      landing_page.html_key = html_key;
      landing_page.thumbnail_key = thumbnail_key;

      // effects will get executed in parallel
      const [postTemplateJSONResponse, postTemplateHTMLResponse, postThumbnailResponse] = yield all([
        call(postTemplateJSON, json_url, json),
        call(postTemplateHTML, html_url, html),
        call(postThumbnail, thumbnail_url, thumbnail),
      ]);

      if (postTemplateJSONResponse && postTemplateJSONResponse.status === 200
        && postTemplateHTMLResponse && postTemplateHTMLResponse.status === 200
        && postThumbnailResponse && postThumbnailResponse.status === 200) {

        yield put({ type: types.UPLOAD_TEMPLATE_API_CALL_SUCCESS, landing_page });

        const data = {
          ...landing_page,
        };

        yield put({ type: types.CREATE_TEMPLATE_API_CALL_REQUEST, data });

        yield put({ type: types.UPDATE_ACTIVE_STEP, activeStep: activeStep + 1 });
        yield put({ type: types.UPDATE_STEPS, activeStep });

      } else {
        yield put({ type: types.UPLOAD_TEMPLATE_API_CALL_FAILURE, error: true });
      }
    } else {
      yield put({ type: types.UPLOAD_TEMPLATE_API_CALL_FAILURE, error: true });
    }
  } catch (error) {
    yield put({ type: types.UPLOAD_TEMPLATE_API_CALL_FAILURE, error });
  }
}
