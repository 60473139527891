import axios from 'axios';
import { getConversionAPIURL, getMetricsAPIURL, getPerformanceMetricsAPIURL } from './utils';
import { getClient } from '../../common/utils';
import config from '../../config/app_config';

export function fetchMetricsData(userPrivilege, entityType, metricType, selectedDateRange, selectedEntities ) {
  const client = getClient();
  const url = getMetricsAPIURL(client, userPrivilege, entityType, metricType, selectedDateRange, selectedEntities);
  return axios.get(url, {})
    .then(val => val)
    .catch(err => err);
}

export function fetchPerformanceMetricsData(entityType, metricType, selectedDateRange, selectedEntities ) {
  const client = getClient();
  const url = getPerformanceMetricsAPIURL(client, entityType, selectedDateRange, selectedEntities);
  return axios.get(url, {})
    .then(val => val)
    .catch(err => err);
}

export function fetchClientEntitiesData() {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/client/${client}/entities/`;

  return axios.get(url)
    .then(val => val)
    .catch(err => err);
}

export function fetchPerkPageRecommendationsData(selectedDateRange, selectedEntities) {
  const client = getClient();
  const dateRangeQueryParams =
    `&start_datetime=${selectedDateRange.startDate}&end_datetime=${selectedDateRange.endDate}`;
  let url = `${config.API_ROOT_URL}/v1/client/${client}/perkpagerecommendations/`;
  if (selectedEntities && selectedEntities.length > 0) {
    url = `${url}?${dateRangeQueryParams}&slugs=${selectedEntities}`;
  } else {
    url = `${url}?${dateRangeQueryParams}`;
  }

  return axios.get(url, {})
    .then(val => val)
    .catch(err => err);
}

export function fetchPartnerConversionMetricsData(entityType, metricType, selectedDateRange ) {
  const client = getClient();
  const url = getConversionAPIURL(client, entityType, selectedDateRange);
  return axios.get(url, {})
    .then(val => val)
    .catch(err => err);
}

