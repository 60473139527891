import * as types from './types';
import initialState from './initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case types.UPDATE_USER:
    return state.setIn(['user'], action.user);

  case types.LOGIN_API_CALL_REQUEST:
    return state.set(['fetching'], true)
      .setIn(['error'], false);
  case types.FORGOT_PWD_API_CALL_REQUEST:
    return state.set(['fetching'], true)
      .setIn(['error'], false)
      .setIn(['resetpasswordlinksent'], false);


  case types.LOGIN_API_CALL_SUCCESS:
    return state.set(['fetching'], false)
      .setIn(['error'], false)
      .setIn(['user'], action.data);
  case types.FORGOT_PWD_API_CALL_SUCCESS:
    return state.set(['fetching'], false)
      .setIn(['error'], false)
      .setIn(['resetpasswordlinksent'], true);

  case types.LOGIN_API_CALL_FAILURE:
  case types.FORGOT_PWD_API_CALL_FAILURE:
    return state.set(['fetching'], false)
      .setIn(['error'], action.error);

  case types.RESET_LOGIN_ERROR:
    return state.setIn(['error'], false);

  case types.RESET_PWD_REQ_RESET:
    return state.setIn(['error'], false)
      .setIn(['resetpasswordlinksent'], false);
  default:
    return state;
  }
};

// ACTION CREATORS
export const loginRequest = (user, history) => ({ type: types.LOGIN_API_CALL_REQUEST, user, history });
export const updateUser = (user) => ({ type: types.UPDATE_USER, user });
export const updateUserRequest =
  (data) => ({ type: types.UPDATE_USER_REQUEST, data });
export const resetLoginError = () => ({ type: types.RESET_LOGIN_ERROR });
export const resetPasswordResetRequest = () => ({ type: types.RESET_PWD_REQ_RESET });
export const requestDemoInvitation = (email) => ({ type: types.REQUEST_DEMO_INVITATION, email });
export const forgotPasswordRequest =
  (user, history) => ({ type: types.FORGOT_PWD_API_CALL_REQUEST, user, history });
export const resetPasswordSubmitRequest = (token, password, callback) => ({
  type: types.RESET_PWD_API_CALL_REQUEST,
  token,
  password,
  callback,
});

export default reducer;
