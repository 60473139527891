import { takeEvery, call, put } from 'redux-saga/effects';
import * as types from './types';
import { fetchPerkPageRecommendationsData } from './service';

export function* getPerkPageRecommendationsWatcherSaga() {
  yield takeEvery(types.GET_PERKPAGE_RECOMMENDATION_REQUEST, workerSaga);
}

export function* workerSaga({ selectedDateRange, selectedEntities }) {
  try {
    const response = yield call(fetchPerkPageRecommendationsData, selectedDateRange, selectedEntities);
    if (response && response.status === 200) {
      const { data } = response;
      yield put({ type: types.GET_PERKPAGE_RECOMMENDATION_SUCCESS, data });
    } else {
      yield put({
        type: types.GET_PERKPAGE_RECOMMENDATION_FAILURE });
    }
  } catch (error) {
    yield put({ type: types.GET_PERKPAGE_RECOMMENDATION_FAILURE });
  }
}
