import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import Spinner from '../../components/Spinner/Spinner';

import {setCurrentHeader, getAppConfigurationRequest, setCurrentSubtitle} from '../../features/App/reducer';
import { isUserLoggedIn } from '../../common/utils';
import { getARMPrivateRoutes, getPRMPrivateRoutes } from '../../features/App/helpers';

import { PRM_PRIVILEGE } from '../../common/constants';
import { Props, State } from './flowTypes';

class PrivatePage extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      user: isUserLoggedIn(), // Using storage
    };
  }

  render() {
    const { user } = this.state;
    const {
      setPageCurrentHeader,
        setPageCurrentSubtitle,
      appConfiguration: {
        fetching: fetchingAppConfiguration,
        configuration
      }
    } = this.props;

    const ROUTES =
      user && user.privilege ===
        PRM_PRIVILEGE ? getPRMPrivateRoutes() : getARMPrivateRoutes();

    if (fetchingAppConfiguration) {
      return (<Spinner left={'50%'} top={'50%'} fontSize={'4rem'} />);
    }

    return (
      <React.Fragment>
        <Switch>
          {ROUTES.map((route, index) => (
            <Route
              key={index}
              exact
              path={route.path}
              render={(props) => {
                setTimeout(() => {
                  setPageCurrentHeader(route.header)
                  setPageCurrentSubtitle(route.subtitle)
                }, 0);


                return (user && configuration) ? (
                  <route.component {...props}/>
                ) : (
                  <Redirect to={{
                    pathname: '/login', state: {from: props.location}
                  }} />
                );
              }}
            />
          ))}
          <Redirect to="/login"/>

        </Switch>
      </React.Fragment>
    );
  }

  componentDidMount() {
    const {
      getAppConfigurationRequest
    } = this.props;
    getAppConfigurationRequest();
  }
}

///////////////////////////////////////////////////////////////////////
//  REDUX CONNECTION
///////////////////////////////////////////////////////////////////////
function mapStateToProps(state) {
  const { app } = state;
  const appToJS = app.toJS();

  return {
    appConfiguration: appToJS.appConfiguration,
  };
}

export default connect(
  mapStateToProps, {
    setPageCurrentHeader: setCurrentHeader,
      setPageCurrentSubtitle: setCurrentSubtitle,
    getAppConfigurationRequest,
  })(PrivatePage);
