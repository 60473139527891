import axios from 'axios';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as types from './types';
import constants from '../../common/constants';
import config from '../../config/app_config';
import { getClient } from '../../common/utils';

export function* getBuyingMomentsWatcherSaga() {
  yield takeLatest(types.GET_BUYING_MOMENTS_API_CALL_REQUEST, workerSaga);
}

export function fetchData(page, size, search) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/offer/${client}/buying-moment/`;
  let params;

  if (page) {
    params = {
      page,
      page_size: size,
      search,
    };
  }

  return axios.get(url, { params})
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga({ page, size, search }) {
  try {
    const state = yield select();
    const pages = state.buyingMoments.toJS().buyingMoments.pages;

    if (size !== constants.INFINITE && page && pages[page]) { // Avoid this check for get all buying moment
      // we already got this data so don't get it again
      yield put({ type: types.GET_BUYING_MOMENTS_API_CALL_CANCELLED });
      return;
    }

    const response = yield call(fetchData, page, size, search);
    if (response && response.status === 200) {
      const { data } = response;
      if (size !== constants.INFINITE) {
        data['page'] = page;

        yield put({ type: types.GET_BUYING_MOMENTS_API_CALL_SUCCESS, data });
      } else {
        yield put({ type: types.GET_ALL_BUYING_MOMENTS_API_CALL_SUCCESS, data });
      }

    } else {
      yield put({ type: types.GET_BUYING_MOMENTS_API_CALL_FAILURE, error: 'error occured during get buying moments' });
    }
  } catch (error) {
    yield put({ type: types.GET_BUYING_MOMENTS_API_CALL_FAILURE, error });
  }
}
