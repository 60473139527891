import { fromJS } from 'immutable';

export const initialState = fromJS({
  selectedMarketplace: null,
  error: null,
  fetching: false,
  marketplaceoffers: {
    items: [],
    total: 0,
  },
  allAvailableOffersLocations: [],
  locations: [],
  industries: [],
  kpis: [],
  brands: [],
  showPrivate: false,
  search: '',
  updatedFilter: null
});
