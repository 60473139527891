import * as types from './types';
import initialState, { initialPassword } from './initialState';
import { fromJS } from 'immutable';

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case types.SET_USER_PASSWORD:
    return state.setIn(['password', action.category], action.password);
  case types.CHANGE_PASSWORD_REQUEST:
    return state.setIn(['loading'], true)
      .setIn(['error'], null);
  case types.CHANGE_PASSWORD_SUCCESS:
    return state.set(['loading'], false)
      .setIn(['error'], null)
      .setIn(['password'], fromJS(initialPassword));
  case types.CHANGE_PASSWORD_FAILURE:
    return state.set(['loading'], false)
      .setIn(['error'], fromJS(action.error));
  case types.FETCH_CLIENT_USERS_SUCCESS:
    return state.set(['users'], fromJS(action.data));
  case types.ADD_CLIENT_USER_REQUEST:
    return state.setIn(['loading'], true);
  case types.ADD_CLIENT_USER_SUCCESS:
  case types.ADD_CLIENT_USER_FAILURE:
    return state.setIn(['loading'], false);
  default:
    return state;
  }
};

// ACTION CREATORS
export const setUserPassword =
  (category, password) => ({ type: types.SET_USER_PASSWORD, category, password });
export const setPasswordError =
  (error) => ({ type: types.CHANGE_PASSWORD_FAILURE, error });
export const updateUserPassword =
  (data, callback) => ({ type: types.CHANGE_PASSWORD_REQUEST, data, callback });
export const getClientUsersRequest = () => ({ type: types.FETCH_CLIENT_USERS_REQUEST });
export const addClientUserRequest = (data) => ({ type: types.ADD_CLIENT_USER_REQUEST, data });
export const updateUserNewPasswordRequest =
  (data, callback) => ({ type: types.UPDATE_NEW_PASSWORD_REQUEST, data, callback });

export default reducer;
