import { fromJS } from 'immutable';

import { initialState } from './initialState';
import * as types from './types';

const reducer = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
  case types.GET_ANCILLARY_API_DETAILS:
    return state.setIn(['fetching'], true)
      .setIn(['error'], false);

  case types.GET_ANCILLARY_API_DETAILS_SUCCESS:
    return state.setIn(['fetching'], false)
      .setIn(['error'], false)
      .setIn(['apiDetails'], data.api_details)
      .setIn(['apiKey'], data.api_token);

  case types.GET_ANCILLARY_API_DETAILS_ERROR:
    return state.setIn(['fetching'], false)
      .setIn(['error'], fromJS(action.error));

  case types.CREATE_PERK_SERVICE_CALL_REQUEST:
    return state.setIn(['adding'], true);
  case types.DELETE_PERK_SERVICE_CALL_REQUEST:
    return state.setIn(['removing'], true);
  case types.GET_PERK_SERVICE_CALL_REQUEST:
    return state.setIn(['error'], null);
  case types.GET_PERK_SERVICE_CALL_SUCCESS:
    return state.setIn(['error'], false)
      .setIn(['adding'], false)
      .setIn(['removing'], false)
      .setIn(['offers'], fromJS(data));
  case types.GET_PERK_SERVICE_CALL_FAILURE:
    return state.setIn(['adding'], false)
      .setIn(['removing'], false)
      .setIn(['error'], fromJS(action.error));
  case types.REARRANGE_PERK_SERVICE_CALL_REQUEST:
    return state.setIn(['offers'], fromJS(data))
      .setIn(['reArranging'], true)
      .setIn(['error'], null);
  case types.UPDATE_PERK_SERVICE_CALL_SUCCESS:
    return state.setIn(['reArranging'], false);
  default:
    return state;
  }
};

// Action Creators
export const getAncillaryAPIDetails = () => ({ type: types.GET_ANCILLARY_API_DETAILS });
export const getPerkServiceRequest = () => ({ type: types.GET_PERK_SERVICE_CALL_REQUEST });
export const createPerkServiceRequest = data => ({ type: types.CREATE_PERK_SERVICE_CALL_REQUEST, data });
export const deletePerkServiceRequest = offer => ({ type: types.DELETE_PERK_SERVICE_CALL_REQUEST, offer });
export const updatePerkServiceRequest =
  (data) => ({ type: types.UPDATE_PERK_SERVICE_CALL_REQUEST, data });
export const reArrangePerkServiceData =
  (data) => ({ type: types.REARRANGE_PERK_SERVICE_CALL_REQUEST, data });

export default reducer;
