import {Form} from "react-bootstrap";
import React from "react";
import "./FormField.scss"

type Props = {
    controlId: string,
    label: string,
    required?: boolean,
    type?: string,
    name: string,
    placeholder?: string,
    value: string,
    onChange: (value: string) => void,
    onBlur?: () => void,
    error?: string,
}

export const FormField = ({ controlId, label, error, ...inputProps }: Props) => {
    return <Form.Group controlId={controlId} className="form-group-new">
        <Form.Label className="form-group-new-label">{label}</Form.Label>
        <Form.Control
            className={`form-group-new-input ${error ? "form-group-new-input-error" : ''}`}
            { ...inputProps }
        />
        {!!error && <span className="form-group-new-error">{error}</span>}
    </Form.Group>
}