import moment from 'moment';

import {
  checkNavigationPermission,
  checkEarningPermission,
  checkConversionPermission,
  checkPerformanceReportPermission,
  checkBoostMetricsPermission
} from '../../components/SideNav/helpers';

import config from '../../config/app_config';
import { ANCILLARY_API, CAMPAIGNS, PERKS } from '../../common/i18n';
import { DEMO_CLIENT_SLUG } from '../../common/constants';

export const getMetricsAPIURL = (client, userPrivilege, entityType='campaign', metricType, selectedDateRange, selectedEntities) => {
  const dateRangeQueryParams = `&start_datetime=${selectedDateRange.startDate}&end_datetime=${selectedDateRange.endDate}`;
  if (entityType === 'campaign') {
    if(userPrivilege === 'PRM') {
      return `${config.API_ROOT_URL}/v1/offer/${client}/prm-metrics/?metric_type=${metricType}`;
    } else if(entityType === 'campaign') {
      if (client === DEMO_CLIENT_SLUG) {
        return `${config.API_ROOT_URL}/v1/offer/${client}/metrics/?metric_type=${metricType}&start_datetime=2021-05-31T18:30:00.000Z&end_datetime=2021-06-30T18:29:59.999Z`;
      }
      return `${config.API_ROOT_URL}/v1/offer/${client}/metrics/?metric_type=${metricType}${dateRangeQueryParams}`;
    }
  } else if (entityType === 'perk' || entityType === 'perkByEntities') {
    if (client === DEMO_CLIENT_SLUG) {
      return `${config.API_ROOT_URL}/v1/offer/${client}/demo-perkpage-metrics/?metric_type=${metricType}${dateRangeQueryParams}`;
    }
    if (selectedEntities && selectedEntities.length > 0) {
      return `${config.API_ROOT_URL}/v1/offer/${client}/perkpage-metrics/?metric_type=${metricType}${dateRangeQueryParams}&slugs=${selectedEntities}`;
    }
    return `${config.API_ROOT_URL}/v1/offer/${client}/perkpage-metrics/?metric_type=${metricType}${dateRangeQueryParams}`;

  } else if (entityType === 'ancillaryapi') {
    if (client === DEMO_CLIENT_SLUG) {
      return `${config.API_ROOT_URL}/v1/offer/${client}/demo-perkservice-metrics/?metric_type=${metricType}${dateRangeQueryParams}`;
    }
    return `${config.API_ROOT_URL}/v1/offer/${client}/perkservice-metrics/?metric_type=${metricType}${dateRangeQueryParams}`;
  } else if (entityType === 'universal') {
    if (client === DEMO_CLIENT_SLUG) {
      return `${config.API_ROOT_URL}/v1/offer/${client}/demo-universal-metrics/?metric_type=${metricType}&start_datetime=2021-05-31T18:30:00.000Z&end_datetime=2021-06-30T18:29:59.999Z`;
    }
    return `${config.API_ROOT_URL}/v1/offer/${client}/universal-metrics/?metric_type=${metricType}${dateRangeQueryParams}`;
  } else if (entityType === 'unattributed') {
    return `${config.API_ROOT_URL}/v1/offer/${client}/unattributed-metrics/?metric_type=${metricType}${dateRangeQueryParams}`;
  }
};

export const getRevenueAPIURL = (client, userPrivilege, entityType='campaign', selectedDateRange) => {
  const dateRangeQueryParams = `&start_datetime=${selectedDateRange.startDate}&end_datetime=${selectedDateRange.endDate}`;
  if (entityType === 'campaign') {
    if (userPrivilege === 'PRM') {
      return `${config.API_ROOT_URL}/v1/offer/${client}/revenue/?type=offer`;
    }
    return `${config.API_ROOT_URL}/v1/offer/${client}/revenue/?type=${entityType}${dateRangeQueryParams}`;
  } else if (entityType === 'perk') {
    // entityType = perk
    return `${config.API_ROOT_URL}/v1/offer/${client}/perkpage-revenue/?type=perkpage${dateRangeQueryParams}`;
  } else if (entityType === 'ancillaryapi') {
    return `${config.API_ROOT_URL}/v1/offer/${client}/perkservice-revenue/?type=perkservice${dateRangeQueryParams}`;
  }
};

export const getConversionAPIURL = (client, entityType='campaign', selectedDateRange) => {
  const dateRangeQueryParams = `&start_datetime=${selectedDateRange.startDate}&end_datetime=${selectedDateRange.endDate}`;
  if (entityType === 'campaign')
    return `${config.API_ROOT_URL}/v1/offer/${client}/campaign-offer-metrics/?metric_type=offer-converted${dateRangeQueryParams}`;
  else if (entityType === 'perk') {
    if (client === DEMO_CLIENT_SLUG) {
      return `${config.API_ROOT_URL}/v1/offer/${client}/demo-perkpage-offer-metrics/?metric_type=offer-converted${dateRangeQueryParams}`;
    }
    return `${config.API_ROOT_URL}/v1/offer/${client}/perkpage-offer-metrics/?metric_type=offer-converted${dateRangeQueryParams}`;
  }
  else if (entityType === 'ancillaryapi')
    return `${config.API_ROOT_URL}/v1/offer/${client}/ancillary-offer-metrics/?metric_type=offer-converted${dateRangeQueryParams}`;
  else if (entityType === 'universal')
    return `${config.API_ROOT_URL}/v1/offer/${client}/universal-offer-metrics/?metric_type=offer-converted${dateRangeQueryParams}`;
  else if (entityType === 'unattributed')
    return `${config.API_ROOT_URL}/v1/offer/${client}/unattributed-offer-metrics/?metric_type=offer-converted${dateRangeQueryParams}`;
};

export const getPerformanceMetricsAPIURL = (client, entityType='campaign', selectedDateRange, selectedEntities) => {
  const dateRangeQueryParams = `&start_datetime=${selectedDateRange.startDate}&end_datetime=${selectedDateRange.endDate}`;
  if (entityType === 'campaign')
    return `${config.API_ROOT_URL}/v1/offer/${client}/product-offer-performance-metrics/?product_type=campaign${dateRangeQueryParams}`;
  else if (entityType === 'perk' || entityType === 'perkByEntities') {
    if (selectedEntities && selectedEntities.length > 0) {
      return `${config.API_ROOT_URL}/v1/offer/${client}/product-offer-performance-metrics/?product_type=perkpage${dateRangeQueryParams}&slugs=${selectedEntities}`;
    }
    return `${config.API_ROOT_URL}/v1/offer/${client}/product-offer-performance-metrics/?product_type=perkpage${dateRangeQueryParams}`;
  }
  else if (entityType === 'ancillaryapi')
    return `${config.API_ROOT_URL}/v1/offer/${client}/product-offer-performance-metrics/?product_type=ancillary_api${dateRangeQueryParams}`;
  else if (entityType === 'universal')
    return `${config.API_ROOT_URL}/v1/offer/${client}/product-offer-performance-metrics/?product_type=universal${dateRangeQueryParams}`;
  else if (entityType === 'unattributed')
    return `${config.API_ROOT_URL}/v1/offer/${client}/product-offer-performance-metrics/?product_type=unattributed${dateRangeQueryParams}`;
};

export const getProductTabsConfig = () => {
  return [{
    key: 'all',
    position: 0,
    display:  true,
    label: 'Summary'
  }, {
    key: 'perk',
    position: 1,
    display: checkNavigationPermission(PERKS),
    label: 'Perks Page'
  },
    {
      key: 'boost-metrics',
      position: 2,
      display: false,
      label: 'Boost Metrics'
    },
    {
    key: 'universal',
    position: 3,
    display: true,
    label: 'Universal URL'
  }, {
    key: 'campaign',
    position: 4,
    display: checkNavigationPermission(CAMPAIGNS),
    label: 'Campaign'
  }, {
    key: 'ancillaryapi',
    position: 5,
    display: checkNavigationPermission(ANCILLARY_API),
    label: 'Ancillary API'
  }, {
    key: 'performance-report-links',
    position: 6,
    display: checkPerformanceReportPermission(),
    label: 'Download Performance Reports',
      badge: 'BETA'
  }];
};

export const getDefaultStartDateTime = () => {
  return moment().subtract(89, 'days').startOf('d').toISOString();
};

export const getDefaultEndDateTime = () => {
  return moment().endOf('d').toISOString();
};

export function sumMetrics(type, metricsData) {
  return Object.keys(metricsData[type]).reduce((acc, ele) => {
    acc = metricsData[type][ele][type] + acc;
    return acc;
  }, 0);
}

export function sumCampaignMetrics(type, metricsData) {
  return Object.keys(metricsData[type]).reduce((acc, ele) => {
    const totalMetricPerDay = Object.keys(metricsData[type][ele]).reduce((day_acc, campaign) => {
      return day_acc + metricsData[type][ele][campaign][type];
    }, 0);
    return totalMetricPerDay + acc;
  }, 0);
}

export function calculateRate(nominator, denominator) {
  if(nominator && denominator) {
    return parseFloat((nominator/denominator) * 100).toFixed(1);
  }
  return 0;
}

export function getStatisticCardsStyle(type) {
  let numberOfCards;

  if (checkEarningPermission()) {
    numberOfCards++;
  }

  if (checkConversionPermission()) {
    numberOfCards++;
  }

  if(type === 'perk') {
    numberOfCards = 2;
  } else if(type === 'universal') {
    numberOfCards = 1;
  } else if(type === 'campaign') {
    numberOfCards = 3;
  } else if (type === 'ancillary') {
    numberOfCards = 2;
  } else if (type === 'all') {
    numberOfCards = 1;
  }

  if (checkEarningPermission()) {
    numberOfCards++;
  }

  if (checkConversionPermission()) {
    numberOfCards++;
  }

  switch (numberOfCards) {
  case 5:
    return {
      width: '19%',
      marginRight: '1.22%'
    };
  case 4:
    return {
      width: '24%',
      marginRight: '1.33%'
    };
  case 3:
    return {
      width: '30%',
      marginRight: '2%'
    };
  case 2:
    return {
      width: '48%',
      marginRight: '2%'
    };
  case 1:
    return {
      width: '100%',
      marginRight: '0%'
    };
  default:
    break;
  }
}

export function getStatisticsContainerStyle(type, displayConversion) {
  let numberOfCards;

  if (checkEarningPermission()) {
    numberOfCards++;
  }

  if (checkConversionPermission()) {
    numberOfCards++;
  }

  if(type === 'perk') {
    numberOfCards = 2;
  } else if(type === 'universal') {
    numberOfCards = 1;
  } else if(type === 'campaign') {
    numberOfCards = 3;
  } else if (type === 'ancillary') {
    numberOfCards = 2;
  } else if (type === 'all') {
    numberOfCards = 1;
  }

  if (checkEarningPermission()) {
    numberOfCards++;
  }

  if (checkConversionPermission()) {
    numberOfCards++;
  }

  switch (numberOfCards) {
  case 5:
  case 4:
    return {
      width: '100%',
    };
  case 3:
    return {
      width: '76%',
    };
  case 2:
    return {
      width: '50%',
    };
  case 1:
    return {
      width: '25%'
    };
  default:
    break;
  }
}

export function parseClientEntities(entities) {
  return entities.map(entity => ({label: entity.name, value: entity.slug, type: entity.type}))
}


