export const SET_USER_PASSWORD = 'SET_USER_PASSWORD';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const FETCH_CLIENT_USERS_REQUEST = 'FETCH_CLIENT_USERS_REQUEST';
export const FETCH_CLIENT_USERS_SUCCESS = 'FETCH_CLIENT_USERS_SUCCESS';
export const FETCH_CLIENT_USERS_FAILURE = 'FETCH_CLIENT_USERS_FAILURE';

export const ADD_CLIENT_USER_REQUEST = 'ADD_CLIENT_USER_REQUEST';
export const ADD_CLIENT_USER_SUCCESS = 'ADD_CLIENT_USER_SUCCESS';
export const ADD_CLIENT_USER_FAILURE = 'ADD_CLIENT_USER_FAILURE';

export const UPDATE_NEW_PASSWORD_REQUEST = 'UPDATE_NEW_PASSWORD_REQUEST';
export const UPDATE_NEW_PASSWORD_SUCCESS = 'UPDATE_NEW_PASSWORD_SUCCESS';
export const UPDATE_NEW_PASSWORD_FAILURE = 'UPDATE_NEW_PASSWORD_FAILURE';