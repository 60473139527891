import { fromJS } from 'immutable';

import { cloneDeep } from '../../common/utils';


export const defaultCustomerSegment = {
  name: '',
  filters: {}
};

export const initialState = fromJS({
  createAndUpdateError: false,
  deleteError: false,
  fetching: false,
  error: false,
  customerSegments: {
    items: [],
    total: 0,
    pages: {},
    activePage: 1,
    countFetching: false,
    searchResultPages: {},
  },
  totalCustomers: 0,
  selectedCustomerSegment: cloneDeep(defaultCustomerSegment),
  filters: {},
  clientIntegrationConfig: null,
  uploadDataFile: {
    uploading: false,
  }
});
