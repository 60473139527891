import axios from 'axios';
import { takeEvery, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { getClient } from '../../common/utils';
// import { TEMPLATE_TYPE } from './constants';

export function* deleteTemplatesWatcherSaga() {
  yield takeEvery(types.DELETE_TEMPLATES_API_CALL_REQUEST, workerSaga);
}

export function deleteData(templateSlug) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/client/${client}/templates/${templateSlug}/`;

  return axios.delete(url, {})
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga(action) {
  try {
    const { templateSlug } = action;
    const response = yield call(deleteData, templateSlug);

    if (response && response.status >= 200 && response.status <= 204) {
      yield put({ type: types.DELETE_TEMPLATES_API_CALL_SUCCESS, 'templateSlug': templateSlug });
    } else {
      yield put({ type: types.DELETE_TEMPLATES_API_CALL_FAILURE, error: 'error occured during get offer' });
    }
  } catch (error) {
    yield put({ type: types.DELETE_TEMPLATES_API_CALL_FAILURE, error });
  }
}
