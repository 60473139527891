import { fromJS } from 'immutable';

export const initialState = fromJS({
  sideDrawerState: false,
  showModal: false,
  currentHeader: '',
  locations: [],
  industries: [],
  metrics: [],
  brands: [],
  appConfiguration: {
    fetching: true,
    configuration: null
  }
});
