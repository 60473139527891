import * as types from './types';
import { fromJS } from 'immutable';
import { initialState } from './initialState';
import { getClient } from 'common/utils';
import { DEMO_CLIENT_SLUG } from 'common/constants';

const setFetchingState = (action, state, value) => {
  const {
    entityType,
    metricType,
  } = action;

  if (metricType === 'sent') {
    return state.setIn([entityType, 'fetchingSent'], value);
  } else if (metricType === 'opened') {
    return state.setIn([entityType, 'fetchingOpened'], value);
  } else if (metricType === 'clicked') {
    return state.setIn([entityType, 'fetchingClicked'], value);
  } else if (metricType === 'converted') {
    return state.setIn([entityType, 'fetchingConverted'], value);
  } else if (metricType === 'viewed') {
    return state.setIn([entityType, 'fetchingViewed'], value);
  } else if (metricType === 'earnings') {
    return state.setIn([entityType, 'fetchingEarnings'], value);
  } else if (metricType === 'revenue') {
    return state.setIn([entityType, 'fetchingRevenue'], value);
  } else if (metricType === 'partnerOfferClicked') {
    return state.setIn([entityType, 'fetchingPartnerOfferClicked'], value);
  } else if (metricType === 'partnerOfferConverted') {
    return state.setIn([entityType, 'fetchingPartnerOfferConverted'], value);
  } else if (metricType === 'performanceMetricReport') {
    return state.setIn([entityType, 'fetchingPerformanceMetricReport'], value);
  }
};

const onDateRangeChangeRequest = (state) => {
  return state.setIn(['campaign', 'sent'], null)
    .setIn(['campaign', 'opened'], null)
    .setIn(['campaign', 'clicked'], null)
    .setIn(['campaign', 'converted'], null)
    .setIn(['campaign', 'partnerOfferConverted'], null)
    .setIn(['campaign', 'revenue'], null)
    .setIn(['campaign', 'performanceMetricReport'], null)
    .setIn(['perk', 'viewed'], null)
    .setIn(['perk', 'clicked'], null)
    .setIn(['perk', 'converted'], null)
    .setIn(['perk', 'partnerOfferConverted'], null)
    .setIn(['perk', 'performanceMetricReport'], null)
    .setIn(['perk', 'partnerOfferClicked'], null)
    .setIn(['perk', 'revenue'], null)
    .setIn(['perkByEntities', 'viewed'], null)
    .setIn(['perkByEntities', 'clicked'], null)
    .setIn(['perkByEntities', 'converted'], null)
    .setIn(['perkByEntities', 'earnings'], null)
    .setIn(['perkByEntities', 'performanceMetricReport'], null)
    .setIn(['ancillaryapi', 'viewed'], null)
    .setIn(['ancillaryapi', 'clicked'], null)
    .setIn(['ancillaryapi', 'converted'], null)
    .setIn(['ancillaryapi', 'revenue'], null)
    .setIn(['ancillaryapi', 'partnerOfferConverted'], null)
    .setIn(['ancillaryapi', 'performanceMetricReport'], null)
    .setIn(['universal', 'clicked'], null)
    .setIn(['universal', 'converted'], null)
    .setIn(['universal', 'partnerOfferConverted'], null)
    .setIn(['universal', 'performanceMetricReport'], null)
    .setIn(['unattributed', 'partnerOfferConverted'], null)
    .setIn(['unattributed', 'performanceMetricReport'], null)
    .setIn(['unattributed', 'converted'], null)
    .setIn(['unattributed', 'earnings'], null)
    .setIn(['fetchingDateRangeData'], true);
};

const checkClientAndSetEarningForDemoClient = ({metricType, data}) => {
  const client = getClient();
  if (metricType === 'earnings' && client === DEMO_CLIENT_SLUG) {
    return fromJS({});
  }
  return fromJS(data);
};

const onPerkPageEntitySelection = (state, entities) => {
  return state.setIn(['perkByEntities', 'fetchingViewed'], true)
    .setIn(['perkByEntities', 'fetchingClicked'], true)
    .setIn(['perkByEntities', 'fetchingConverted'], true)
    .setIn(['perkByEntities', 'fetchingPerformanceMetricReport'], true)
    .setIn(['perkByEntities', 'fetchingRecommendations'], true)
    .setIn(['perkByEntities', 'viewed'], null)
    .setIn(['perkByEntities', 'clicked'], null)
    .setIn(['perkByEntities', 'converted'], null)
    .setIn(['perkByEntities', 'earnings'], null)
    .setIn(['perkByEntities', 'performanceMetricReport'], null)
    .setIn(['perkByEntities', 'recommendations'], null)
    .setIn(['perkByEntities', 'selectedEntities'], entities);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_METRICS_CALL_REQUEST:
  case types.GET_REVENUE_CALL_REQUEST:
  case types.GET_PARTNER_OFFER_CLICKED_REQUEST:
  case types.GET_PARTNER_CONVERSION_REQUEST:
  case types.GET_PERFORMANCE_METRICS_REQUEST:
    return setFetchingState(action, state, true)
      .setIn(['fetchingDateRangeData'], false)
      .setIn(['error'], null);
  case types.GET_METRICS_CALL_SUCCESS:
  case types.GET_REVENUE_CALL_SUCCESS:
  case types.GET_PARTNER_OFFER_CLICKED_SUCESS:
  case types.GET_PARTNER_CONVERSION_SUCCESS:
  case types.GET_PERFORMANCE_METRICS_SUCCESS:
    return setFetchingState(action, state, false)
      .setIn([action.entityType, action.metricType], checkClientAndSetEarningForDemoClient(action));
  case types.DATE_RANGE_CHANGE_REQUEST:
    return onDateRangeChangeRequest(state);
  case types.GET_CLIENT_ENTITIES_SUCCESS:
    return state.setIn(['clientEntities'], fromJS(action.data))
      .setIn(['fetchingClientEntities'], false);
  case types.PERK_PAGE_ENTITY_CHANGE:
    return onPerkPageEntitySelection(state, action.selectedEntities);
  case types.GET_METRICS_CALL_FAILURE:
  case types.GET_REVENUE_CALL_FAILURE:
  case types.GET_PARTNER_OFFER_CLICKED_FAILURE:
  case types.GET_PARTNER_CONVERSION_FAILURE:
  case types.GET_PERFORMANCE_METRICS_FAILURE:
    return setFetchingState(action, state, false)
      .setIn(['error'], fromJS(action.error));
  case types.GET_CLIENT_ENTITIES_FAILED:
    return state.setIn(['fetchingClientEntities'], false)
      .setIn(['error'], fromJS(action.error));
  case types.GET_PERKPAGE_RECOMMENDATION_REQUEST:
    return state.setIn(['perk', 'fetchingRecommendations'], true);
  case types.GET_PERKPAGE_RECOMMENDATION_SUCCESS:
    return state.setIn(['perk', 'fetchingRecommendations'], false)
      .setIn(['perk', 'recommendations'], fromJS(action.data));
  case types.GET_PERKPAGE_RECOMMENDATION_FAILURE:
    return state.setIn(['perk', 'fetchingRecommendations'], false)
      .setIn(['perk', 'recommendations'], null);
  default:
    return state;
  }
};

// Action Creators
export const getMetricsRequest =
  (userPrivilege, entityType, metricType, selectedDateRange, selectedEntities) =>
    ({ type: types.GET_METRICS_CALL_REQUEST, userPrivilege,
      entityType, metricType, selectedDateRange, selectedEntities });

export const getRevenueRequest =
  (userPrivilege, entityType, selectedDateRange) =>
    ({ type: types.GET_REVENUE_CALL_REQUEST, userPrivilege, entityType, metricType: 'revenue', selectedDateRange });

export const getPerkPageOfferClickedRequest =
  (entityType, selectedDateRange) =>
    ({ type: types.GET_PARTNER_OFFER_CLICKED_REQUEST,
      entityType, metricType: 'partnerOfferClicked', selectedDateRange });

export const getPartnerConversionMetricsRequest =
  (entityType, metricType, selectedDateRange) =>
    ({ type: types.GET_PARTNER_CONVERSION_REQUEST, entityType, metricType, selectedDateRange });

export const getPerformanceMetricsRequest =
  (entityType, metricType, selectedDateRange, selectedEntities) =>
    ({ type: types.GET_PERFORMANCE_METRICS_REQUEST, entityType, metricType, selectedDateRange, selectedEntities });

export const onDateRangeChange = () => ({ type: types.DATE_RANGE_CHANGE_REQUEST });

export const getClientEntities = () => ({ type: types.GET_CLIENT_ENTITIES_REQUEST });

export const onPerkPageEntityChange = selectedEntities =>
  ({ type: types.PERK_PAGE_ENTITY_CHANGE, selectedEntities: selectedEntities });

export const getPerkPageRecommendations = (selectedDateRange, selectedEntities) => ({
  type: types.GET_PERKPAGE_RECOMMENDATION_REQUEST, selectedDateRange, selectedEntities
});

export default reducer;
