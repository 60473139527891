// @flow
import * as React from 'react';
import './Link.scss';

type Props = {
  children?: React.Node,
  variant: string,
  disabled: boolean,
}

const Link = (props: Props) => {
  let linkStyle = props.variant === 'primary' ? 'primary-link' : 'secondary-link';
  if (props.disabled) {
    linkStyle = linkStyle + ' disabled';
  }
  return <div className={linkStyle} {...props}>{props.children}</div>;
};

export default Link;
