import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';

export function* getPerkPageStyleWatcherSaga() {
  yield takeLatest(types.GET_PERK_PAGE_STYLE_REQUEST, workerSaga);
}

export function fetchData(entitySlug) {
  const url = `${config.PERK_PAGE_STYLE_API}/v1/perkpage/${entitySlug}/style/`;

  return axios.get(url)
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga(action) {
  try {
    const { entitySlug } = action;
    const response = yield call(fetchData, entitySlug);

    if (response && response.status === 200) {
      const { data } = response;

      yield put({ type: types.GET_PERK_PAGE_STYLE_SUCCESS, data });

    } else {
      yield put({ type: types.GET_PERK_PAGE_STYLE_FAILURE, error: 'Failed to fetch style.'});
    }
  } catch (error) {
    console.log(error)
    yield put({ type: types.GET_PERK_PAGE_STYLE_FAILURE, error });
  }
}
