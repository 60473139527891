import axios from 'axios';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as types from './types';
import offerUrls from './offerUrls';

export function* getOffersWatcherSaga() {
  yield takeLatest(types.GET_OFFERS_API_CALL_REQUEST, workerSaga);
}

export function fetchData(page, user) {

  const url = offerUrls('postData', user);

  const params = {page};

  return axios.get(url, { params})
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga({ page }) {
  try {
    const state = yield select();
    const user = state.login.toJS().user;
    // const pages = state.offers.toJS().offers.pages;

    // if (page && pages[page]) {
    //   // we already got this data so don't get it again
    //   yield put({ type: types.GET_OFFERS_API_CALL_CANCELLED });
    //   return
    // }


    const response = yield call(fetchData, page, user);

    if (response && response.status === 200) {
      const { data } = response;
      data['page'] = page;

      yield put({ type: types.GET_OFFERS_API_CALL_SUCCESS, data });

    } else {
      yield put({ type: types.GET_OFFERS_API_CALL_FAILURE, error: 'error occured during get offer' });
    }
  } catch (error) {
    yield put({ type: types.GET_OFFERS_API_CALL_FAILURE, error });
  }
}
