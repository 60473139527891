export const GET_METRICS_CALL_REQUEST = 'GET_METRICS_CALL_REQUEST';
export const GET_METRICS_CALL_SUCCESS = 'GET_METRICS_CALL_SUCCESS';
export const GET_METRICS_CALL_FAILURE = 'GET_METRICS_CALL_FAILURE';

export const GET_REVENUE_CALL_REQUEST = 'GET_REVENUE_CALL_REQUEST';
export const GET_REVENUE_CALL_SUCCESS = 'GET_REVENUE_CALL_SUCCESS';
export const GET_REVENUE_CALL_FAILURE = 'GET_REVENUE_CALL_FAILURE';

export const GET_PARTNER_OFFER_CLICKED_REQUEST = 'GET_PARTNER_OFFER_CLICKED_REQUEST';
export const GET_PARTNER_OFFER_CLICKED_SUCESS = 'GET_PARTNER_OFFER_CLICKED_SUCESS';
export const GET_PARTNER_OFFER_CLICKED_FAILURE = 'GET_PARTNER_OFFER_CLICKED_FAILURE';

export const GET_PARTNER_CONVERSION_REQUEST = 'GET_PARTNER_CONVERSION_REQUEST';
export const GET_PARTNER_CONVERSION_SUCCESS = 'GET_PARTNER_CONVERSION_SUCCESS';
export const GET_PARTNER_CONVERSION_FAILURE = 'GET_PARTNER_CONVERSION_FAILURE';

export const GET_PERFORMANCE_METRICS_REQUEST = 'GET_PERFORMANCE_METRICS_REQUEST';
export const GET_PERFORMANCE_METRICS_SUCCESS = 'GET_PERFORMANCE_METRICS_SUCCESS';
export const GET_PERFORMANCE_METRICS_FAILURE = 'GET_PERFORMANCE_METRICS_FAILURE';

export const GET_PERKPAGE_RECOMMENDATION_REQUEST = 'GET_PERKPAGE_RECOMMENDATION_REQUEST';
export const GET_PERKPAGE_RECOMMENDATION_SUCCESS = 'GET_PERKPAGE_RECOMMENDATION_SUCCESS';
export const GET_PERKPAGE_RECOMMENDATION_FAILURE = 'GET_PERKPAGE_RECOMMENDATION_FAILURE';

export const DATE_RANGE_CHANGE_REQUEST = 'DATE_RANGE_CHANGE_REQUEST';
export const PERK_PAGE_ENTITY_CHANGE = 'PERK_PAGE_ENTITY_CHANGE';

export const GET_CLIENT_ENTITIES_REQUEST = 'GET_CLIENT_ENTITIES_REQUEST';
export const GET_CLIENT_ENTITIES_SUCCESS = 'GET_CLIENT_ENTITIES_SUCCESS';
export const GET_CLIENT_ENTITIES_FAILED = 'GET_CLIENT_ENTITIES_FAILED';
