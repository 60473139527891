import axios from 'axios';
import { takeEvery, call, put } from 'redux-saga/effects';
import * as types from './types';
import { getRevenueAPIURL } from './utils';
import { getClient } from '../../common/utils';
import { ERROR_GET_REVENUE_FAILED } from '../../common/i18n';

export function* getRevenueWatcherSaga() {
  yield takeEvery(types.GET_REVENUE_CALL_REQUEST, workerSaga);
}

export function fetchData(userPrivilege, entityType = 'campaign', selectedDateRange) {
  const client = getClient();
  const url = getRevenueAPIURL(client, userPrivilege, entityType, selectedDateRange);

  return axios.get(url, {})
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga({ userPrivilege, entityType, selectedDateRange }) {
  try {
    const response = yield call(fetchData, userPrivilege, entityType, selectedDateRange);
    if (response && response.status === 200) {
      const { data } = response;
      yield put({ type: types.GET_REVENUE_CALL_SUCCESS, data, entityType, metricType: 'revenue' });
    } else {
      yield put({
        type: types.GET_REVENUE_CALL_FAILURE, entityType, metricType: 'revenue', error: ERROR_GET_REVENUE_FAILED });
    }
  } catch (error) {
    yield put({ type: types.GET_REVENUE_CALL_FAILURE, entityType, metricType: 'revenue', error });
  }
}
