// @flow
import { useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import config from '../../config/app_config';
import { removeSession, redirectTo } from '../../common/utils';
import { clearReduxStore } from '../App/reducer';
type Props = {
  history: {
    push: any
  },
  clearReduxStore : () => void
}

const logout = (props: Props) => {
  const url = `${config.API_ROOT_URL}/v1/auth/logout-all/`;

  axios.post(url, {})
    .then(val => {
        if (window.rudderanalytics.getSessionId()) {
            window.rudderanalytics.track("Client user signed out");
        }
      removeSession();
      window.rudderanalytics.endSession();
      window.rudderanalytics.reset();
      props.clearReduxStore();
      redirectTo(props.history, '/login');
      return val;
    })
    .catch(err => err);
};

const Logout = (props: Props) => {
  useEffect(() => {
    if (localStorage.getItem('token')) {
      logout(props);
    }
  }, [props]);

  return null;
};

///////////////////////////////////////////////////////////////////////
//  REDUX CONNECTION
///////////////////////////////////////////////////////////////////////

export default connect(null, { clearReduxStore })(Logout);
