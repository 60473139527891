import React from 'react';

import config from '../config/app_config';
import moment from 'moment';
import { ToastsStore } from 'react-toasts';
import {
  TEMPLATE_TYPE,
  MomentShorthand,
} from './../features/Offers/constants';
import {
  PRM_PRIVILEGE,
  API_DATE_FORMAT,
  API_DATE_FORMAT_IN_HOURS,
  API_RES_DATE_FORMAT,
  DEMO_CLIENT_SLUG
} from './constants';
import {
  SPECIFIC_DATE,
  SPECIFIC_DAY,
  SPECIFIC_EVENT,
} from './../features/Offers/constants';

import {
  CustomerSegmentNotSelectedError,
  CustomerSegmentFilterInvalidRange,
  CAMPAIGN_PERMISSION,
  OFFERS,
} from '../common/i18n';
import LabelWithTooltip from './../components/LabelWithTooltip';
import { checkConversionPermission, checkEarningPermission } from '../components/SideNav/helpers';

export function checkImageURL(src, onSuccess, onError){
  const img = new Image();
  img.onload = function(){
    onSuccess();
  }
  img.onerror = function(){
    onError();
  };
  img.src = src;

}

export const cloneDeep = (item) => {
  if (!item || typeof item !== 'object') {
    return item;
  }

  const constructor = item.constructor();
  return Object.keys(item).reduce((acc, curr) => {
    acc[curr] = cloneDeep(item[curr]);
    return acc;
  }, constructor);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const resetPagination = (items: {}, item: {}, deleted: boolean, itemType: string) => {
  let count = 5;
  if (itemType.toLowerCase() === 'buyingmoments') {
    count = 10;
  }
  const { pages } = items;
  if (!pages) {
    return;
  }
  if (deleted) {
    items.pages = {};
  } else {
    const itemsRemoved = Object.keys(pages).length > 0 && pages[1].filter(i => item.name !== i.name);
    if (itemsRemoved) {
      const firstPage = [item, ...itemsRemoved].slice(0, count);
      items.pages = { '1': firstPage };

      const allItems = items['items'];
      allItems[Object.keys(allItems).length] = item;

    }
  }
  items.activePage = 1;
};

export const getItemsOnPage = (pages: {}, activePage: number) => {
  return Object.keys(pages).length > 0 && pages[activePage] ? pages[activePage] : [];
};

export const addCountProperty = (items: []) => {
  return items.map(item => ({...item, count: undefined}));
};

export const getCustomerSlugs = (items: []) => {
  return items.map(item => item.slug);
};

export const getParams = (url) => {
  var regex = /[?&]([^=#]+)=([^&#]*)/g,
    params = {},
    match; // eslint-disable-next-line
  while(match = regex.exec(url)) {
    params[match[1]] = match[2];
  }
  return params;
};

export const getNextStep = (currentStep: string) => {
  const currentStepNumber = currentStep.split(' ');
  const nextStep= Number(currentStepNumber[1]) + 1;
  return `step ${nextStep}`;
};

export const keyPress = (key) => {
  var event = document.createEvent('Event');
  event.keyCode = key; // Deprecated, prefer .key instead.
  event.key = key;
  event.initEvent('keydown');
  document.dispatchEvent(event);
};

export const getActiveChannels = (channels) => {
  return channels.filter(item => item.is_active);
};

export const storeToken = (token) => {
  localStorage.setItem('token', token);
};

export const getToken = () => {
  return localStorage.getItem('token');
};

export const deleteToken = () => {
  localStorage.removeItem('token');
};

export const storeClient = (client) => {
  localStorage.setItem('client', client);
};

export const getClient = () => {
  return localStorage.getItem('client');
};

export const getClientData = () => {
  return JSON.parse(localStorage.getItem('clientData'));
}

export const deleteClient = () => {
  localStorage.removeItem('client');
};

export const isUserLoggedIn = () => {
  const user = localStorage.getItem('user');
  return user && JSON.parse(user);
};

export const storeUser = (user) => {
  localStorage.setItem('user', user);
};

export const storeSession = (response) => {
  storeUser(JSON.stringify(response.user));
  localStorage.setItem('token', response.token);
  localStorage.setItem('client', response.client[0]);
  localStorage.setItem('navigation', JSON.stringify(response.navigation || []));
  localStorage.setItem('clientData', JSON.stringify({
    ...response.client_data,
    apiToken: response.api_token
  }));
};

export const removeSession = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('client');
  localStorage.removeItem('clientData');
};

export function redirectToLogin() {
  window.localStorage.clear();
  window.location.replace(`${config.WEBAPP_ROOT_URL}/login`);
}

export function redirectToDashboard() {
  window.location.replace(`${config.WEBAPP_ROOT_URL}/dashboard`);
  return null;
}

export function redirectToDefault(privilege) {
  if (privilege && privilege === PRM_PRIVILEGE) {
    window.location.replace(`${config.WEBAPP_ROOT_URL}/offers`);
  } else {
    window.location.replace(`${config.WEBAPP_ROOT_URL}/dashboard`);
  }
  return null;
}

export function redirectTo(history, route) {
  history.push(`${route}`);
}

export function mapOfferResponceToSelectedOffer(fetchedOffer) {
  const {
    name,
    value,
    value_unit,
    description,
    end_date,
    start_date,
    consumer_segment,
    slug,
    channels,
    template, // Special Property from PRM
    buying_moment,
    is_published,
    added_on,
    is_active,
    used_in_live_campaigns_count,
    industries,
    locations,
    kpis,
    offers,
  } = fetchedOffer;

  const formatedStartDate =
    start_date && moment.utc(start_date).format(API_RES_DATE_FORMAT);
  const formatEndDate =
    end_date && moment.utc(end_date).format(API_RES_DATE_FORMAT);

  let selectedBuyingMoment;
  if (buying_moment && buying_moment[0].day) {
    selectedBuyingMoment = SPECIFIC_DAY;
  } else if (buying_moment && buying_moment[0].date) {
    selectedBuyingMoment = SPECIFIC_DATE;
  } else {
    selectedBuyingMoment = SPECIFIC_EVENT;
  }

  const selectedChannel = (channels && channels[0].name) || 'SMS';

  let selectedTemplate;

  if (template) {
    selectedTemplate = template;
  } else {
    selectedTemplate = channels && channels.length > 0 && channels[0].template;
  }

  // TODO Try to Use initial State because it is redundant to copy same state at two place for every new state
  return {
    name,
    value,
    unit: value_unit,
    description,
    isOfferTimeLimited: !!(end_date && start_date),
    start_date: formatedStartDate,
    end_date: formatEndDate,
    consumer_segment: consumer_segment && consumer_segment.slug,
    consumer_segment_original: consumer_segment,
    current_step: 'step 6',
    slug,
    landing_page: selectedTemplate || {
      slug: '',
      name: '',
      html_key: '',
      json_key: '',
      template_type: TEMPLATE_TYPE
    },
    selectedChannel,
    offers,
    channels: sortByReminder(channels) || [
      {
        name: 'SMS',
        reminder: 0,
        displayContentArea: false,
        content: {
          body: '',
        }
      }
    ],
    selectedBuyingMoment: selectedBuyingMoment,
    buying_moment: sortByReminder(buying_moment) || [{
      reminder: 0,
      duration: 0,
      duration_unit: 'Week',
      occurence: 'Before',
      date: '',
      day: '',
      event: '',
    }],
    isPublished: is_published,
    isActive: is_active,
    saveAndQuit: false,
    lastSavedOfferValue: value,
    createdOn: added_on,
    metrics: null,
    usedInCampaignCount: used_in_live_campaigns_count,
    industries,
    locations,
    kpis,
  };
}

export function mapOfferResponceToSelectedCampaign(offer) {
  const {
    name,
    description,
    end_date,
    start_date,
    template, // Special Property from PRM
    added_on,
  } = offer;

  const formatedStartDate = start_date && moment.utc(start_date).format(API_RES_DATE_FORMAT);
  const formatEndDate = end_date && moment.utc(end_date).format(API_RES_DATE_FORMAT);

  // TODO Try to Use initial State because it is redundant to copy same stateat two place for every new state
  return {
    name,
    description,
    isOfferTimeLimited: !!(end_date && start_date),
    start_date: formatedStartDate,
    end_date: formatEndDate,
    current_step: 'step 1',
    channels: [
      {
        name: 'SMS',
        reminder: 0,
        template: template,
        displayContentArea: false,
        content: {
          body: '',
        }
      }
    ],
    isPublished: false,
    isActive: false,
    createdOn: added_on,
    dateLimit: end_date && start_date && [formatedStartDate, formatEndDate],
    origin: 'marketplace',
    offers: [offer]
  };
}

export function handleError (error) {
  if (error && typeof error === 'object') {
    const messageValues = Object.values(error);
    const errorMessage = messageValues && messageValues[0];
    if (errorMessage) {
      if (typeof errorMessage === 'string') {
        ToastsStore.error(errorMessage);
      } else if (Array.isArray(errorMessage)) {
        ToastsStore.error(errorMessage[0]);
      }
    }
  }
}

export const accumulateMetrics = (accumulator, current) => {
  accumulator['sent'] += parseInt(current['sent'] || 0);
  accumulator['opened'] += parseInt(current['opened'] || 0);
  accumulator['clicked'] += parseInt(current['clicked'] || 0);
  accumulator['claimed'] += parseInt(current['claimed'] || 0);
  accumulator['converted'] += parseInt(current['converted'] || 0);
  accumulator['earnings'] += parseFloat(current['earnings'] || 0);
  return accumulator;
};

export const crunchPerkPartnerOfferClickData = (metrics) => {
  const OFFER_CLICKED = 'offer-clicked';

  // metrics.partnerOfferConverted will be null if conversion is turned off
  if (!metrics.partnerOfferConverted) {
    delete metrics.partnerOfferConverted;
  }

  return Object.values(metrics).reduce((initialAcc, data) => {
    return Object.keys(data).reduce((acc, cur) => {
      if (initialAcc[cur]) {
        initialAcc[cur] += parseInt(data[cur][OFFER_CLICKED]);
        return acc;
      }
      acc[cur] = parseInt(data[cur][OFFER_CLICKED]);
      return acc;
    }, initialAcc);
  }, {});
};

export function crunchMetricData (metrics) {
  // metrics.partnerOfferConverted will be null if conversion is turned off
  if (!metrics.partnerOfferConverted) {
    delete metrics.partnerOfferConverted;
  }
  if (!checkConversionPermission()) {
    delete metrics.converted;
  }

  if (!checkEarningPermission()) {
    delete metrics.earnings;
  }

  return Object.values(metrics).reduce((initialAcc, data) => {
    return Object.keys(data).reduce((acc, cur) => {
      // Get all dates
      const dateFormat = moment.utc(cur, API_DATE_FORMAT_IN_HOURS).local().format(API_DATE_FORMAT);
      if (acc[dateFormat]) {
        const tempAcc = Object.values(data[cur]).reduce(
          accumulateMetrics, {
            'sent': 0,
            'opened': 0,
            'clicked': 0,
            'claimed': 0,
            'converted': 0,
            'earnings': 0
          }
        );
        acc[dateFormat]['sent'] += parseInt(tempAcc['sent']);
        acc[dateFormat]['opened'] += parseInt(tempAcc['opened']);
        acc[dateFormat]['clicked'] += parseInt(tempAcc['clicked']);
        acc[dateFormat]['claimed'] += parseInt(tempAcc['claimed']);
        acc[dateFormat]['converted'] += parseInt(tempAcc['converted']);
        acc[dateFormat]['earnings'] += parseFloat(tempAcc['earnings']);
        return acc;
      }
      acc[dateFormat] = Object.values(data[cur]).reduce(
        accumulateMetrics, {
          'sent': 0,
          'opened': 0,
          'clicked': 0,
          'claimed': 0,
          'converted': 0,
          'earnings': 0
        }
      );
      return acc;
    }, initialAcc);
  }, {});
}

export function crunchPerkPageMetricData (metrics) {
  // metrics.partnerOfferConverted will be null if conversion is turned off
  if (!metrics.partnerOfferConverted) {
    delete metrics.partnerOfferConverted;
  }
  if (!checkConversionPermission()) {
    delete metrics.converted;
  }

  if (!checkEarningPermission()) {
    delete metrics.earnings;
  }

  return Object.values(metrics).reduce((initialAcc, data) => {
    return Object.keys(data).reduce((acc, cur) => {
      // Get all dates
      const dateFormat = moment.utc(cur, API_DATE_FORMAT_IN_HOURS).local().format(API_DATE_FORMAT);
      if (acc[dateFormat]) {
        acc[dateFormat]['viewed'] += parseInt(data[cur]['viewed'] || 0);
        acc[dateFormat]['clicked'] += parseInt(data[cur]['clicked'] || 0);
        acc[dateFormat]['claimed'] += parseInt(data[cur]['claimed'] || 0);
        acc[dateFormat]['converted'] += parseInt(data[cur]['converted']|| 0);
        acc[dateFormat]['earnings'] += parseFloat(data[cur]['earnings']|| 0);
        return acc;
      }
      acc[dateFormat] = {
        'viewed': parseInt(data[cur]['viewed'] || 0),
        'clicked': parseInt(data[cur]['clicked'] || 0),
        'claimed': parseInt(data[cur]['claimed'] || 0),
        'converted': parseInt(data[cur]['converted'] || 0),
        'earnings': parseFloat(data[cur]['earnings'] || 0)
      };
      return acc;
    }, initialAcc);
  }, {});
}

export function crunchRevenueData (data) {
  const topOffers = data && data.results.reduce((acc, ele) => {
    acc.push({
      'name': ele.name,
      'value': ele.revenue,
    });
    return acc;
  }, []);

  return [...topOffers, {
    'name': 'others',
    'value': (data && data.others && data.others.revenue) || 0
  }];
}

export function setMetricsForMissingDates (data, selectedDateRange) {
  const dateFormat = API_DATE_FORMAT;
  const sortedDates = Object.keys(data).sort(function(a, b) {
    return moment(a, dateFormat).diff(moment(b, dateFormat));
  });

  let datesExtent = null;
  if (selectedDateRange) {
    datesExtent = [moment(selectedDateRange.startDate).format(dateFormat), moment(selectedDateRange.endDate).format(dateFormat)];
  }
  else {
    if (sortedDates.length > 1) {
      datesExtent = [sortedDates[0], sortedDates[sortedDates.length - 1]];
    }
    if (!datesExtent) {
      return data;
    }
  }

  const daysDiffernce = moment(datesExtent[1], dateFormat)
    .diff(moment(datesExtent[0], dateFormat), 'days');

  const allDaysData = {};

  for(let day = 0; day <= daysDiffernce; day++) {
    const calculatedDate = moment(datesExtent[0], dateFormat)
      .add(day, 'day').format(dateFormat);
    allDaysData[calculatedDate] = data[calculatedDate] || {
      'sent': 0,
      'opened': 0,
      'clicked': 0,
      'claimed': 0,
      'converted': 0,
      'earnings': 0
    };
  }
  return allDaysData;
}

export function setPerkPageMetricsForMissingDates (data, selectedDateRange) {
  const dateFormat = API_DATE_FORMAT;
  let datesExtent = null;

  const client = getClient();
  if (client === DEMO_CLIENT_SLUG) {
    const sortedDates = Object.keys(data).sort(function(a, b) {
      return moment(a, dateFormat).diff(moment(b, dateFormat));
    });

    if (sortedDates.length > 1) {
      datesExtent = [sortedDates[0], sortedDates[sortedDates.length - 1]];
    }

    if (!datesExtent) {
      return data;
    }
  } else {
    datesExtent = [moment(selectedDateRange.startDate).format(API_DATE_FORMAT), moment(selectedDateRange.endDate).format(API_DATE_FORMAT)];
  }

  const daysDiffernce = moment(datesExtent[1], dateFormat)
    .diff(moment(datesExtent[0], dateFormat), 'days');

  const allDaysData = {};

  for(let day = 0; day <= daysDiffernce; day++) {
    const calculatedDate = moment(datesExtent[0], dateFormat)
      .add(day, 'day').format(dateFormat);
    allDaysData[calculatedDate] = data[calculatedDate] || {
      'viewed': 0,
      'clicked': 0,
      'claimed': 0,
      'converted': 0,
      'earnings': 0
    };
  }
  return allDaysData;
}

export function crunchRevenueDataByOffer (data) {
  return [
    {
      'name': 'SMS',
      'value': data['sms'] || 0
    },
    {
      'name': 'Email',
      'value': data['email'] || 0
    }
  ];
}

export function checkAndReturnErrorMessage (error, field) {
  const errorFields = error && Object.keys(error);
  if (field && errorFields && errorFields.indexOf(field) > -1) {
    // $FlowFixMe
    return error[field][0];
  }
  return '';
}

export function checkPositiveInteger (value) {
  const intRegExp = new RegExp(/^(\s*|\d+)$/);
  return intRegExp.test(value);
}

export function changeCaseFirstLetter(params) {
  if(typeof params === 'string') {
    return params.charAt(0).toUpperCase() + params.slice(1);
  }
  return null;
}

export function applyCustomMargin(className) {
  let marginRight = 0;

  let numberOfCards;
  // Custom logic to calculate Margin Right.
  // Not using justify content because it was not able to align items for uncomplete row.
  if (document.querySelector(className)) {
    // $FlowFixMe
    const widthOfContainer = document.querySelector(className).clientWidth;
    // TOOD Remove fluid container and make it same as LandingPage auto margin and width
    numberOfCards = Math.floor(widthOfContainer / 226);
    // $FlowFixMe
    const spaceToFillWithMargin = Math.floor(widthOfContainer % 226);

    if (numberOfCards > 1) {
      marginRight = spaceToFillWithMargin / (numberOfCards - 1);
    }
  }

  return {
    marginRight,
    numberOfCards,
  };
}

export function formatMobileNumber(number) {
  const numberString = number.toString();
  let country = '';

  if(numberString.indexOf('+1') > -1 && numberString.length === 12) {
    country = 'US';
  }

  if (country && country === 'US') {
    return `(${numberString.substring(2,5)}) ${numberString.substring(5, 8)}-${numberString.substring(8, 12)}`;
  }

  return numberString;
}

export function checkTypeAndReturn (event) {
  if (typeof event === 'object' && event !== null) {
    return event.slug;
  }
  return event;
}

export function sortByReminder(entities) {
  return entities && entities.sort(function(a, b) {
    return parseInt(a.reminder) - parseInt(b.reminder);
  });
}

export function momentFromChannel(duration, unit, occurance, baseDate=moment()) {
  let absoluteMomentDate = null;
  switch (occurance.toLowerCase()) {
  case 'before':
    absoluteMomentDate = moment(baseDate).subtract(duration, MomentShorthand[unit.toLowerCase()]);
    break;
  case 'after':
    absoluteMomentDate = moment(baseDate).add(duration, MomentShorthand[unit.toLowerCase()]);
    break;
  default:
    return null;
  }

  return absoluteMomentDate;
}

export function firstLetterCapital(input) {
  switch (input) {
  case 'kpis':
    return 'KPIs';
  case 'industries':
    return 'Categories';
  default:
    return input.charAt(0).toUpperCase() + input.slice(1);
  }
}

//////////////////////////////////// Customer Segment /////////////////////////
export const manipulateAllFilters = (filters, editedSegmentFilter, isEdit) => {
  const updatedFiltersItem = {};
  Object.keys(filters).forEach(key => {

    // set filter checked.
    // Use filterChecked to set default or or edited value
    const filterChecked = isEdit && editedSegmentFilter.hasOwnProperty(key);

    updatedFiltersItem[key] = {
      ...filters[key],
      checked: filterChecked,
    };

    switch (filters[key].type) {
    case 'list':
      updatedFiltersItem[key]['value'] = filterChecked ? editedSegmentFilter[key] : [];
      break;
    case 'range':
      const [minValue, maxValue] = filterChecked ?
        editedSegmentFilter[key].split('-') :
        filters[key].choices.split('-');
      const min = Number(minValue), max = Number(maxValue);

      updatedFiltersItem[key]['value'] = {min, max};
      break;
    case 'datetime_gte':
      updatedFiltersItem[key]['value'] = filterChecked ? editedSegmentFilter[key]
        : moment().format('YYYY-MM-DD');
      break;
    case 'datetimerange':
    case 'boolean':
      updatedFiltersItem[key]['value'] = filterChecked ? editedSegmentFilter[key] : '';
      break;
    default:
      delete updatedFiltersItem[key];
      break;
    }
  });
  return updatedFiltersItem;
};

export const isValidCustomerSegmentFilters = (filters) => {
  let toastErrorMessage = CustomerSegmentNotSelectedError;
  const isValid = Object.keys(filters).every(filter => {
    if (filters[filter].checked) {
      const filterValue = filters[filter].value;
      switch (typeof filterValue) {
      case 'string':
        return filterValue !== '';
      case 'undefined':
        return false;
      case 'object':
        if (Array.isArray(filterValue)) {
          return filterValue.length !== 0;
        }
        if (filters[filter].type === 'range') {
          const filterChoicesMin =
            parseInt(filters[filter].choices.split('-')[0]);
          const filterChoicesMax =
            parseInt(filters[filter].choices.split('-')[1]);
          if ((filterValue.max < filterValue.min) ||
                (filterValue.min < filterChoicesMin) ||
                (filterValue.max > filterChoicesMax)) {
            toastErrorMessage = CustomerSegmentFilterInvalidRange;
            return false;
          }
        }
        return true;
      default:
        return true;
      }
    }
    return true;
  });

  return {
    isValid,
    toastErrorMessage,
  };
};

export const addUnits = (type, value) => {
  switch (type) {
  case 'age':
    return `${value} Years`;
  case 'income':
  case 'salary':
    return `$ ${value}`;
  default:
    return value;
  }
};

export const nonCachedURL = (url) => {
  const timestamp = moment().format('x');
  if (url.indexOf('?') > -1) {
    return `${url}&time=${timestamp}`;
  }
  return `${url}?time=${timestamp}`;
};

export const changeToLowercase = (value) => {
  return value.replace(/ /g, '').toLowerCase();
};

export const isUserPRM = (user) => user?.privilege === PRM_PRIVILEGE;

export const sortByDisplayValue = (a, b) => {
  if(a.display_value > b.display_value) return 1;
  if(a.display_value === b.display_value) return 0;
  if(a.display_value < b.display_value) return -1;
};

export const formatName = (name, splitCharacter) => name.split(splitCharacter)
  .reduce((acc, partialLabel) => {
    return acc + partialLabel.charAt(0).toUpperCase() + partialLabel.slice(1) + ' ';
  }, '');

export const canCreateCampaigns = (perk) => {
  if (perk.is_coupon) {
    return false;
  }

  const navigation = JSON.parse(localStorage.getItem('navigation'));
  if (navigation) {
    if (navigation.length === 0)
      return true;
    return navigation.includes(CAMPAIGN_PERMISSION);
  } else {
    return true;
  }
};

// function to hide Offers tab from sidenavigation for all users except demo client user
export const hideOffersTabForDemoAccount = navigationDetails => {
  const client = getClient();
  return ((client === DEMO_CLIENT_SLUG && navigationDetails) || navigationDetails.name !== OFFERS);
};

export const crunchTotalPerformanceData = totalMetrics => {
  let totalPerformanceData = {};

  Object.values(totalMetrics).forEach(metric=> {
    metric && metric.forEach(data => {
      const unique_name = `${data.prm_name} ${data.offer_name}`;
      if(totalPerformanceData[unique_name]){
        totalPerformanceData[unique_name].clicks = totalPerformanceData[unique_name].clicks + data.clicks;
        totalPerformanceData[unique_name].conversions =
          totalPerformanceData[unique_name].conversions + data.conversions;
        totalPerformanceData[unique_name].earnings =
          totalPerformanceData[unique_name].earnings + data.earnings;
      } else{
        totalPerformanceData[unique_name] = {
          ...data,
        };
      }
    });
  });
  return Object.values(totalPerformanceData);
};

export const crunchPartnerPerformanceData = metrics => {
  return metrics.map(metric => {
    const conversionRate = metric.clicks > 0 ? metric.conversions/metric.clicks : 0;
    return {
      ...metric,
      'conversionRate': conversionRate > 0 ? Math.round(1000 * conversionRate) / 10 + '%' : '0',
      // eslint-disable-next-line max-len
      'customOffer': metric.is_private ? (<LabelWithTooltip tooltipText="This is partner's private offer" data={metric.offer_name} tooltipLabel='Private' tooltipPosition='top' className='private-label'></LabelWithTooltip>) : (<div>{metric.offer_name}</div>)
    };
  });
};

export const getPartnerPerformanceMetricColumn = () => {
  return [
    {field: 'prm_name', header: 'Partner', width: '20%', sortable: true, show: true},
    {field: 'offer_name', header: 'Offer', width: '15%', sortable: true, custom: true, show: true, customField: 'customOffer'},
    {field: 'clicks', header: 'Clicks', width: '15%', sortable: true, headerClassName: 'th-center', bodyClassName: 'th-center', show: true},
    {field: 'conversions', header: 'Conversions', width: '15%', sortable: true, headerClassName: 'th-center', bodyClassName: 'th-center',
      show: checkConversionPermission()},
    {field: 'conversionRate', header: 'Conversion Rate', width: '15%', sortable: true, headerClassName: 'th-center',
      bodyClassName: 'th-center', show: checkConversionPermission()},
    {field: 'earnings', header: 'Earnings($)', width: '20%', sortable: true, headerClassName: 'th-center', bodyClassName: 'th-center', show: checkEarningPermission()},
  ];
};

export const getPerkPageRecommendationColumn = () => {
  return [
    {
      field: 'added_on',
      header: 'Date',
      width: '20%',
      sortable: true,
      show: true,
      dataType: 'date',
      body: (rowData) => {
        return moment(rowData.added_on).format('MMM DD, YYYY');
      }
    },
    {field: 'recommendation', header: 'Recommendation', width: '60%', sortable: true, show: true},
    {field: 'source', header: 'Source', width: '20%', sortable: true, show: true},
  ];
};

export function crunchOverAllMetricData(metrics) {
  const crunchedData = {};
  Object.values(metrics).forEach(metric => {
    metric && Object.keys(metric).forEach(data => {
      Object.keys(metric[data]).forEach(metricData => {
        if (crunchedData[data]) {
          crunchedData[data][metricData] += parseFloat(metric[data][metricData]);
        } else {
          crunchedData[data] = {'clicked': 0, 'converted': 0, 'earnings': 0};
          crunchedData[data][metricData] = metric[data][metricData];
        }
      });
    });
  });
  return crunchedData;
}

export function setOverAllMetricsForMissingDates (data, selectedDateRange) {
  const dateFormat = API_DATE_FORMAT;
  let datesExtent = null;

  const client = getClient();
  if (client === DEMO_CLIENT_SLUG) {
    const sortedDates = Object.keys(data).sort(function(a, b) {
      return moment(a, dateFormat).diff(moment(b, dateFormat));
    });

    if (sortedDates.length > 1) {
      datesExtent = [sortedDates[0], sortedDates[sortedDates.length - 1]];
    }

    if (!datesExtent) {
      return data;
    }
  } else {
    datesExtent = [moment(selectedDateRange.startDate).format(API_DATE_FORMAT), moment(selectedDateRange.endDate).format(API_DATE_FORMAT)];
  }

  const daysDiffernce = moment(datesExtent[1], dateFormat)
    .diff(moment(datesExtent[0], dateFormat), 'days');

  const allDaysData = {};

  for(let day = 0; day <= daysDiffernce; day++) {
    const calculatedDate = moment(datesExtent[0], dateFormat)
      .add(day, 'day').format(dateFormat);
    allDaysData[calculatedDate] = data[calculatedDate] || {
      'clicked': 0,
      'converted': 0,
      'earnings': 0
    };
  }

  if (client === DEMO_CLIENT_SLUG) {
    let sortedByDate = {};

    Object.keys(allDaysData).sort(function(a, b) {
      return moment(a, API_DATE_FORMAT).toDate() - moment(b, API_DATE_FORMAT).toDate();
    }).forEach(function(key) {
      sortedByDate[key] = allDaysData[key];
    })

    const lastNonNullableElementIndex = Object.values(sortedByDate).findLastIndex(date => date.clicked || date.converted || date.earnings);

    return Object.fromEntries(Object.entries(sortedByDate).slice(0, lastNonNullableElementIndex + 1));
  }
  return allDaysData;
}

export function crunchCampaignMetricDataForAllMetrics (metrics) {
  if (!metrics)
    return null;

  delete metrics.sent;
  delete metrics.claimed;
  delete metrics.opened;

  if (!metrics.partnerOfferConverted) {
    delete metrics.partnerOfferConverted;
  }
  if (!checkConversionPermission()) {
    delete metrics.converted;
  }

  if (!checkEarningPermission()) {
    delete metrics.earnings;
  }

  return Object.values(metrics).reduce((initialAcc, data) => {
    return data && Object.keys(data).reduce((acc, cur) => {
      // Get all dates
      const dateFormat = moment.utc(cur, API_DATE_FORMAT_IN_HOURS).local().format(API_DATE_FORMAT);
      if (acc[dateFormat]) {
        const tempAcc = Object.values(data[cur]).reduce(
          accumulateMetrics, {
            'clicked': 0,
            'converted': 0,
            'earnings': 0
          }
        );
        acc[dateFormat]['clicked'] += parseInt(tempAcc['clicked']);
        acc[dateFormat]['converted'] += parseInt(tempAcc['converted']);
        acc[dateFormat]['earnings'] += parseFloat(tempAcc['earnings']);
        return acc;
      }
      acc[dateFormat] = Object.values(data[cur]).reduce(
        accumulateMetrics, {
          'clicked': 0,
          'converted': 0,
          'earnings': 0
        }
      );
      return acc;
    }, initialAcc);
  }, {});
}

export function crunchPerkPageMetricDataForAllMetrics (metrics) {
  if (!metrics)
    return null;

  delete metrics.viewed;
  delete metrics.claimed;
  // metrics.partnerOfferConverted will be null if conversion is turned off
  if (!metrics.partnerOfferConverted) {
    delete metrics.partnerOfferConverted;
  }
  if (!checkConversionPermission()) {
    delete metrics.converted;
  }
  if (!checkEarningPermission()) {
    delete metrics.earnings;
  }

  return Object.values(metrics).reduce((initialAcc, data) => {
    return data && Object.keys(data).reduce((acc, cur) => {
      // Get all dates
      const dateFormat = moment.utc(cur, API_DATE_FORMAT_IN_HOURS).local().format(API_DATE_FORMAT);
      if (acc[dateFormat]) {
        acc[dateFormat]['clicked'] += parseInt(data[cur]['clicked'] || 0);
        acc[dateFormat]['converted'] += parseInt(data[cur]['converted']|| 0);
        acc[dateFormat]['earnings'] += parseFloat(data[cur]['earnings']|| 0);
        return acc;
      }
      acc[dateFormat] = {
        'clicked': parseInt(data[cur]['clicked'] || 0),
        'converted': parseInt(data[cur]['converted'] || 0),
        'earnings': parseFloat(data[cur]['earnings'] || 0)
      };
      return acc;
    }, initialAcc);
  }, {});
}

export function calculateStatsSummary(performanceTableData) {
  const client = getClient();
  if (client === DEMO_CLIENT_SLUG) {
    return {totalClicks: 2653, totalConversions: 1198, totalEarnings: 1797};
  }

  const [totalClicks, totalConversions, totalEarnings] = performanceTableData.reduce((acc, ele) => {
    acc[0] = acc[0] + ele['clicks'];
    acc[1] = acc[1] + ele['conversions'];
    acc[2] = acc[2] + (ele['earnings'] || 0);
    return acc;
  }, [0, 0, 0]);
  return {totalClicks, totalConversions, totalEarnings};
}

/*
* Util function to prepare marketplace dropdown filters for sending to API or RudderStack
*
* @param {object} options - all available options (industryOptions, locationOptions, brandOptions)
* @param {object} selectedOptions - selected options (industries, locations, brands)
* @param {boolean} replaceWithAll - should we consider all and none selected options as empty array or as 'All'
* */
export const prepareMarketplaceFiltersForRequest = (options, selectedOptions, replaceWithAll = false) => {
  const {
    industryOptions,
      locationOptions,
      brandOptions
  } = options;

  const {
    industries,
      locations,
      brands
  } = selectedOptions;

  const getOptionsForRequest = (selectedOptions, options) => {
    return [0, options?.length].includes(selectedOptions?.length) ? (replaceWithAll ? 'All' : []) : selectedOptions;
  }

  return {
    locations: getOptionsForRequest(locations, locationOptions),
    industries: getOptionsForRequest(industries, industryOptions),
    brands: getOptionsForRequest(brands, brandOptions),
  }
}
