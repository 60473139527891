import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { getClient } from '../../common/utils';
import { INFINITE } from '../../common/constants';
import { ERROR_GET_MP_OFFERS_LOCATIONS } from '../../common/i18n';

export function* getMarketPlaceOffersLocationsWatcherSaga() {
  yield takeLatest(types.GET_MP_OFFERS_LOCATIONS_REQUEST, workerSaga);
}

export function fetchData() {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/offer/${client}/locations/`;

  const params = {
    page_size: INFINITE,
  };

  return axios.get(url, { params })
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga() {
  try {
    const response = yield call(fetchData);

    if (response && response.status === 200) {
      const { data } = response;

      yield put({ type: types.GET_MP_OFFERS_LOCATIONS_SUCCESS, data });

    } else {
      yield put({ type: types.GET_MP_OFFERS_LOCATIONS_FAILURE, error: ERROR_GET_MP_OFFERS_LOCATIONS});
    }
  } catch (error) {
    yield put({ type: types.GET_MP_OFFERS_LOCATIONS_FAILURE, error });
  }
}
