// VERY IMPORTANT!
// Make sure you use descriptive names in the strings, otherwise there will be name clashes.
// In other words, we cannot re-use a name inside a string inside another redux component
export const UPDATE_SIDE_DRAWER_STATE = 'UPDATE_SIDE_DRAWER_STATE';

export const UPDATE_MODAL_STATE = 'UPDATE_MODAL_STATE';

export const CLEAR_REDUX_STORE = 'CLEAR_REDUX_STORE';

export const SET_CURRENT_HEADER = 'SET_CURRENT_HEADER';

export const SET_CURRENT_SUBTITLE = 'SET_CURRENT_SUBTITLE';
