import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { ERROR_GET_MP_OFFERS } from '../../common/i18n';

export function* getPerksWatcherSaga() {
  yield takeLatest(types.GET_PERKS_CALL_REQUEST, workerSaga);
}

export function fetchData(entitySlug) {
  // filter all admin dashboard requests from getting inserted into perkpageeventtracker table
  const url = `${config.API_ROOT_URL}/v1/offer/${entitySlug}/perks/?isAdminRequest=true`;

  return axios.get(url)
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga(action) {
  try {
    const { entitySlug } = action;
    const response = yield call(fetchData, entitySlug);

    if (response && response.status === 200) {
      const { data } = response;

      yield put({ type: types.GET_PERKS_CALL_SUCCESS, data });

    } else {
      yield put({ type: types.GET_PERKS_CALL_FAILURE, error: ERROR_GET_MP_OFFERS});
    }
  } catch (error) {
    console.log(error)
    yield put({ type: types.GET_PERKS_CALL_FAILURE, error });
  }
}
