// @flow

import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { getARMNavigationDetails, getCommonNavigationDetails, getPRMNavigationDetails } from './constants';
import { hideOffersTabForDemoAccount } from '../../common/utils';
import { checkImageURL } from '../../common/utils';
import ChevronDown from '../../icons/chevron-down.svg'

import { PRM_PRIVILEGE } from './../../common/constants';

import LogoIcon from '../../images/logo-with-text.svg';
import LogoWithoutTextIcon from '../../images/logo-without-text.svg';

import '../../stylesheets/fontcustom.css';
import './SideNav.scss';

import LogOut from '../../icons/log-out.svg'

type Props = {
  user: any,
  isNavbarExpanded: boolean,
  onToggleNavbar: any
}

const LinkComponent = ({ item, children }) => {
  const isExternalLink = item.linkTo.startsWith('http://') || item.linkTo.startsWith('https://');

  if (isExternalLink) {
    return <a href={item.linkTo}>
      {children}
    </a>
  }

  return (
      <NavLink to={{ pathname: item.linkTo}} exact activeClassName="active">
        { children }
      </NavLink>
  )
}

class SideNav extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isProfileImageAvailable: true,
    }
  }

  render () {
    const { user, isNavbarExpanded, onToggleNavbar } = this.props;
    const { isProfileImageAvailable } = this.state;

    const clientData = localStorage.getItem('clientData');
    const parsedClientData = clientData && JSON.parse(clientData);
    const WIDTH = isNavbarExpanded ? '280px' : '64px';

    function checkIfProfileImageAvailable(context){
      checkImageURL(
          user.profile_image,
          function(){
            context.setState({ isProfileImageAvailable: true })
          },
          function(){
            context.setState({ isProfileImageAvailable: false })
          }
      );
    }

    function getLetterAvatarText(){
      const letters = user.name.split(' ');
      if (letters.length > 1) {
        return `${letters[0][0]} ${letters[1][0]}`
      }
      return letters[0][0];
    }

    checkIfProfileImageAvailable(this);

    if (isNavbarExpanded) {
      return (
          <nav
              className="sidebar"
              style={{ width: WIDTH }}
          >
            <div className="collapse-button" onClick={onToggleNavbar}>
              <img className="collapse-button__image" src={ChevronDown} alt="Chevron Down" />
            </div>
            <div>
              <NavLink to={user && user.privilege === PRM_PRIVILEGE ? '/offers' : '/dashboard'}>
                <div className="sidebar-header">
                  <img
                      alt="logoicon"
                      src={LogoIcon}
                      className="sidebar-logo"
                  />
                </div>
              </NavLink>

              {
                user && user.privilege === PRM_PRIVILEGE ? (
                    <ul className="list-unstyled components">
                      {
                        getPRMNavigationDetails().map((item, index) => {
                          return (
                              <li key={item+index} className={item.className}>
                                <LinkComponent item={item}>
                                  <div className="list-item">
                                    <div className="list-item-name">
                                                              <span className="list-item-icon">
                                {item.icon
                                    ? <img src={item.icon} alt="Menu item"/>
                                    : <span className={item.iconClassName}></span>
                                }
                          </span>
                                      <span className="list-item-text">{item.name}</span>
                                    </div>
                                  </div>
                                </LinkComponent>
                              </li>
                          );
                        })
                      }
                    </ul>
                ) : (
                    <ul className="list-unstyled components">
                      {
                        getARMNavigationDetails()
                            .filter(hideOffersTabForDemoAccount)
                            .map((item, index) => {
                              return (
                                  item.display &&
                                  <li key={item+index} className={item.className}>
                                    <LinkComponent item={item}>
                                      <div className="list-item">
                                        <div className='list-item-name'>
                                      <span className="list-item-icon">
                                    {item.icon
                                        ? <img src={item.icon} alt="Menu item"/>
                                        : <span className={item.iconClassName}></span>
                                    }
                                    </span>
                                          <span className="list-item-text">{item.name}</span>
                                        </div>
                                      </div>
                                    </LinkComponent>
                                  </li>
                              );
                            })
                      }
                    </ul>
                )
              }
            </div>
            <div>
              <ul className="list-unstyled components bottom-nav-items">
                {
                  getCommonNavigationDetails().map((item, index) => {
                    return (
                        <li key={item+index} className={item.className}>
                          <LinkComponent item={item}>
                            <div className="list-item">
                              <div className="list-item-name">
                                                  <span className="list-item-icon">
                                {item.icon
                                    ? <img src={item.icon} alt="Menu item"/>
                                    : <span className={item.iconClassName}></span>
                                }
                      </span>
                                <span className="list-item-text">{item.name}</span>
                              </div>
                            </div>

                          </LinkComponent>
                        </li>
                    );
                  })
                }
              </ul>
              <div className="profile">
                <div className="profile-info">
                  {isProfileImageAvailable ? <img className="avatar" src={user.profile_image} alt="Avatar" /> : <div className="avatar letter-avatar">
                    { getLetterAvatarText() }
                  </div>}
                  <div className="name-container">
                    <p className="name">{user.name}</p>
                    {parsedClientData && <p className="client">{parsedClientData?.client__name}</p>}
                  </div>
                </div>
                <NavLink className="log-out" to="/logout">
                  <img src={LogOut} alt="Log out" />
                </NavLink>
              </div>
              </div>
          </nav>
      )
    }

    return (
          <nav
              className="sidebar sidebar-collapse"
              style={{ width: WIDTH }}
          >
            <div className="collapse-button" onClick={onToggleNavbar}>
              <img className="collapse-button__image" src={ChevronDown} alt="Chevron Down" />
            </div>
            <div>
              <NavLink to={user && user.privilege === PRM_PRIVILEGE ? '/offers' : '/dashboard'}>
                <div className="sidebar-header">
                  <img
                      alt="logoicon"
                      src={LogoWithoutTextIcon}
                      className="sidebar-logo"
                  />
                </div>
              </NavLink>

              {
                user && user.privilege === PRM_PRIVILEGE ? (
                    <ul className="list-unstyled components">
                      {
                        getPRMNavigationDetails().map((item, index) => {
                          return (
                              <li key={item+index} className={item.className}>
                                <LinkComponent item={item}>
                                  <div className="list-item">
                                    <div className="list-item-name">
                                                              <span className="list-item-icon">
                                {item.icon
                                    ? <img src={item.icon} alt="Menu item"/>
                                    : <span className={item.iconClassName}></span>
                                }
                          </span>
                                    </div>
                                  </div>
                                </LinkComponent>
                              </li>
                          );
                        })
                      }
                    </ul>
                ) : (
                    <ul className="list-unstyled components">
                      {
                        getARMNavigationDetails()
                            .filter(hideOffersTabForDemoAccount)
                            .map((item, index) => {
                              return (
                                  item.display &&
                                  <li key={item+index} className={item.className}>
                                    <LinkComponent item={item}>
                                      <div className="list-item">
                                        <div className='list-item-name'>
                                      <span className="list-item-icon">
                                    {item.icon
                                        ? <img src={item.icon} alt="Menu item"/>
                                        : <span className={item.iconClassName}></span>
                                    }
                                    </span>
                                        </div>
                                      </div>
                                    </LinkComponent>
                                  </li>
                              );
                            })
                      }
                    </ul>
                )
              }
            </div>
            <div>
              <ul className="list-unstyled components bottom-nav-items">
                {
                  getCommonNavigationDetails().map((item, index) => {
                    return (
                        <li key={item+index} className={item.className}>
                          <LinkComponent item={item}>
                            <div className="list-item">
                              <div className="list-item-name">
                                                  <span className="list-item-icon">
                                {item.icon
                                    ? <img src={item.icon} alt="Menu item"/>
                                    : <span className={item.iconClassName}></span>
                                }
                      </span>
                              </div>
                            </div>
                          </LinkComponent>
                        </li>
                    );
                  })
                }
              </ul>
              <div className="profile-collapsed">
                <div className="profile-info">
                  {isProfileImageAvailable ? <img className="avatar" src={user.profile_image} alt="Avatar" /> :
                      <div className="avatar letter-avatar">
                        { getLetterAvatarText() }
                      </div>}
                </div>
                <NavLink className="log-out" to="/logout">
                  <img src={LogOut} alt="Log out" />
                </NavLink>
              </div>
            </div>
          </nav>
    );
  }
}

//  REDUX CONNECTION
///////////////////////////////////////////////////////////////////////
function mapStateToProps(state) {
  const { login } = state;
  const loginToJS = login.toJS();
  const { user } = loginToJS;

  return {
    user: user,
  };
}

export default connect(mapStateToProps, null)(SideNav);
