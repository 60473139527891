import axios from 'axios';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as types from './types';
import offerUrls from './offerUrls';

export function* searchOffersWatcherSaga() {
  yield takeLatest(types.SEARCH_OFFERS_API_CALL_REQUEST, workerSaga);
}

export function fetchData(page, search, user) {

  const url = offerUrls('fetchData', user);
  const params = {page, search};

  return axios.get(url, { params})
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga({ page, search }) {
  try {
    const state = yield select();
    const user = state.login.toJS().user;
    const pages = state.customerSegments.toJS().customerSegments.searchResultPages;

    if (page && pages[page]) {
      // we already got this data so don't get it again
      yield put({ type: types.SEARCH_OFFERS_API_CALL_CANCELLED });
      return;
    }

    const response = yield call(fetchData, page, search, user);
    if (response && response.status === 200) {
      const { data } = response;
      data['page'] = page;

      yield put({ type: types.SEARCH_OFFERS_API_CALL_SUCCESS, data });


    } else {
      yield put({ type: types.SEARCH_OFFERS_API_CALL_FAILURE, error: 'error occured during search offers' });
    }
  } catch (error) {
    yield put({ type: types.SEARCH_OFFERS_API_CALL_FAILURE, error });
  }
}
