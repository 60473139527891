export const UPDATE_SELECTED_OFFER = 'UPDATE_SELECTED_OFFER';

export const RESET_SELECTED_OFFER_TO_DEFAULT = 'RESET_SELECTED_OFFER_TO_DEFAULT';

export const GET_OFFERS_API_CALL_REQUEST = 'GET_OFFERS_API_CALL_REQUEST';
export const GET_OFFERS_API_CALL_SUCCESS = 'GET_OFFERS_API_CALL_SUCCESS';
export const GET_OFFERS_API_CALL_FAILURE = 'GET_OFFERS_API_CALL_FAILURE';
export const GET_OFFERS_API_CALL_CANCELLED = 'GET_OFFERS_API_CALL_CANCELLED';

export const UPDATE_OFFERS_LIST = 'UPDATE_OFFERS_LIST';


export const CREATE_OFFER_API_CALL_REQUEST = 'CREATE_OFFER_API_CALL_REQUEST';
export const CREATE_OFFER_API_CALL_SUCCESS = 'CREATE_OFFER_API_CALL_SUCCESS';
export const CREATE_OFFER_API_CALL_FAILURE = 'CREATE_OFFER_API_CALL_FAILURE';

export const RESET_OFFERS = 'RESET_OFFERS';


export const GET_OFFERS_COUNT_API_CALL_REQUEST = 'GET_OFFERS_COUNT_API_CALL_REQUEST';
export const GET_OFFERS_COUNT_API_CALL_SUCCESS = 'GET_OFFERS_COUNT_API_CALL_SUCCESS';
export const GET_OFFERS_COUNT_API_CALL_FAILURE = 'GET_OFFERS_COUNT_API_CALL_FAILURE';

export const SEARCH_OFFERS_API_CALL_REQUEST = 'SEARCH_OFFERS_API_CALL_REQUEST';
export const SEARCH_OFFERS_API_CALL_SUCCESS = 'SEARCH_OFFERS_API_CALL_SUCCESS';
export const SEARCH_OFFERS_API_CALL_FAILURE = 'SEARCH_OFFERS_API_CALL_FAILURE';
export const SEARCH_OFFERS_API_CALL_CANCELLED = 'SEARCH_OFFERS_API_CALL_CANCELLED';
export const RESET_CREATE_UPDATE_OFFER_ERROR = 'RESET_CREATE_UPDATE_OFFER_ERROR';

export const UPDATE_ACTIVE_STEP = 'UPDATE_ACTIVE_STEP';
export const UPDATE_STEPS = 'UPDATE_STEPS';
export const RESET_STEPS = 'RESET_STEPS';

export const PATCH_UPDATE_OFFER_API_CALL_REQUEST = 'PATCH_UPDATE_OFFER_API_CALL_REQUEST';
export const PATCH_UPDATE_OFFER_API_CALL_SUCCESS = 'PATCH_UPDATE_OFFER_API_CALL_SUCCESS';
export const PATCH_UPDATE_OFFER_API_CALL_FAILURE = 'PATCH_UPDATE_OFFER_API_CALL_FAILURE';

export const CREATE_TEMPLATE_API_CALL_REQUEST = 'CREATE_TEMPLATE_API_CALL_REQUEST';
export const CREATE_TEMPLATE_API_CALL_SUCCESS = 'CREATE_TEMPLATE_API_CALL_SUCCESS';
export const CREATE_TEMPLATE_API_CALL_FAILURE = 'CREATE_TEMPLATE_API_CALL_FAILURE';


export const GET_TEMPLATES_API_CALL_REQUEST = 'GET_TEMPLATES_API_CALL_REQUEST';
export const GET_TEMPLATES_API_CALL_SUCCESS = 'GET_TEMPLATES_API_CALL_SUCCESS';
export const GET_TEMPLATES_API_CALL_FAILURE = 'GET_TEMPLATES_API_CALL_FAILURE';

export const UPLOAD_TEMPLATE_API_CALL_REQUEST = 'UPLOAD_TEMPLATE_API_CALL_REQUEST';
export const UPLOAD_TEMPLATE_API_CALL_SUCCESS = 'UPLOAD_TEMPLATE_API_CALL_SUCCESS';
export const UPLOAD_TEMPLATE_API_CALL_FAILURE = 'UPLOAD_TEMPLATE_API_CALL_FAILURE';

export const GET_CHANNELS_API_CALL_REQUEST = 'GET_CHANNELS_API_CALL_REQUEST';
export const GET_CHANNELS_API_CALL_SUCCESS = 'GET_CHANNELS_API_CALL_SUCCESS';
export const GET_CHANNELS_API_CALL_FAILURE = 'GET_CHANNELS_API_CALL_FAILURE';
export const GET_CHANNELS_API_CALL_CANCELLED = 'GET_CHANNELS_API_CALL_CANCELLED';

export const GET_CUSTOMER_COUNT_PER_CHANNEL_API_CALL_REQUEST = 'GET_CUSTOMER_COUNT_PER_CHANNEL_API_CALL_REQUEST';
export const GET_CUSTOMER_COUNT_PER_CHANNEL_API_CALL_SUCCESS = 'GET_CUSTOMER_COUNT_PER_CHANNEL_API_CALL_SUCCESS';
export const GET_CUSTOMER_COUNT_PER_CHANNEL_API_CALL_FAILURE = 'GET_CUSTOMER_COUNT_PER_CHANNEL_API_CALL_FAILURE';

export const DELETE_OFFER_API_CALL_REQUEST = 'DELETE_OFFER_API_CALL_REQUEST';
export const DELETE_OFFER_API_CALL_SUCCESS = 'DELETE_OFFER_API_CALL_SUCCESS';
export const DELETE_OFFER_API_CALL_FAILURE = 'DELETE_OFFER_API_CALL_FAILURE';

export const GET_ATTRIBUTES_API_CALL_REQUEST = 'GET_ATTRIBUTES_API_CALL_REQUEST';
export const GET_ATTRIBUTES_API_CALL_SUCCESS = 'GET_ATTRIBUTES_API_CALL_SUCCESS';
export const GET_ATTRIBUTES_API_CALL_FAILURE = 'GET_ATTRIBUTES_API_CALL_FAILURE';

export const GET_OFFER_BY_SLUG_API_CALL_REQUEST = 'GET_OFFER_BY_SLUG_API_CALL_REQUEST';
export const GET_OFFER_BY_SLUG_API_CALL_SUCCESS = 'GET_OFFER_BY_SLUG_API_CALL_SUCCESS';
export const GET_OFFER_BY_SLUG_API_CALL_FAILURE = 'GET_OFFER_BY_SLUG_API_CALL_FAILURE';

export const PLAY_PAUSE_OFFER_API_CALL_REQUEST = 'PLAY_PAUSE_OFFER_API_CALL_REQUEST';
export const PLAY_PAUSE_OFFER_API_CALL_SUCCESS = 'PLAY_PAUSE_OFFER_API_CALL_SUCCESS';
export const PLAY_PAUSE_OFFER_API_CALL_FAILURE = 'PLAY_PAUSE_OFFER_API_CALL_FAILURE';

export const DELETE_TEMPLATES_API_CALL_REQUEST = 'DELETE_TEMPLATES_API_CALL_REQUEST';
export const DELETE_TEMPLATES_API_CALL_SUCCESS = 'DELETE_TEMPLATES_API_CALL_SUCCESS';
export const DELETE_TEMPLATES_API_CALL_FAILURE = 'DELETE_TEMPLATES_API_CALL_FAILURE';

export const GET_OFFER_METRICS_CALL_REQUEST = 'GET_OFFER_METRICS_CALL_REQUEST';
export const GET_OFFER_METRICS_CALL_SUCCESS = 'GET_OFFER_METRICS_CALL_SUCCESS';
export const GET_OFFER_METRICS_CALL_FAILURE = 'GET_OFFER_METRICS_CALL_FAILURE';

export const GET_OFFER_REVENUE_CALL_REQUEST = 'GET_OFFER_REVENUE_CALL_REQUEST';
export const GET_OFFER_REVENUE_CALL_SUCCESS = 'GET_OFFER_REVENUE_CALL_SUCCESS';
export const GET_OFFER_REVENUE_CALL_FAILURE = 'GET_OFFER_REVENUE_CALL_FAILURE';

export const SET_OFFER_ERROR = 'SET_OFFER_ERROR';
