import axios from 'axios';
import { takeEvery, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';


export function* updateNewPasswordWatcherSaga() {
  yield takeEvery(types.UPDATE_NEW_PASSWORD_REQUEST, workerSaga);
}

export function putData(data) {
  const url = `${config.API_ROOT_URL}/v1/auth/password/change/`;

  return axios.put(url, data)
    .then(val => val)
    .catch(error => error.response);
}

export function* workerSaga(action) {
  try {
    const { data, callback } = action;
    const response = yield call(putData, data);

    if (response && response.status === 200) {
      yield put({
        type: types.UPDATE_NEW_PASSWORD_SUCCESS,
      });
    } else {
      yield put({ type: types.UPDATE_NEW_PASSWORD_FAILURE });
    }
    callback();
  } catch (error) {
    yield put({ type: types.UPDATE_NEW_PASSWORD_FAILURE });
  }
}
