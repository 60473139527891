import axios from 'axios';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { getClient, mapOfferResponceToSelectedOffer } from '../../common/utils';
import offerUrls from './offerUrls';
import { updateSteps } from './reducer';

export function* getOfferBySlugWatcherSaga() {
  yield takeLatest(types.GET_OFFER_BY_SLUG_API_CALL_REQUEST, workerSaga);
}

export function fetchDataBySlug(slug, user) {

  const url = `${offerUrls('fetchData', user)}${slug}/`;

  return axios.get(url)
    .then(val => val)
    .catch(err => err);
}

export function fetchCount(consumerSegmentSlug) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/consumer/${client}/consumer-count/${consumerSegmentSlug}/`;

  return axios.get(url)
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga({ slug, consumerSegmentSlug }) {
  try {
    let segmentCount, selectedOffer;
    const state = yield select();
    const user = state.login.toJS().user;
    const offerState = state.offers.toJS();
    const response = yield call(fetchDataBySlug, slug, user);

    if (consumerSegmentSlug) {
      segmentCount = yield call(fetchCount, consumerSegmentSlug);
    }

    if (response && response.status === 200) {
      const { data } = response;

      const dataWithCount = {
        ...data,
        consumer_segment: {
          ...data.consumer_segment,
          count: consumerSegmentSlug ? segmentCount && segmentCount.data : null,
        }
      };

      if (offerState &&
        offerState.selectedOffer && !offerState.selectedOffer.slug) {
        selectedOffer = mapOfferResponceToSelectedOffer(dataWithCount, user);
        if (selectedOffer && selectedOffer.slug) {
          yield put(updateSteps(1));
        }

        if (selectedOffer && selectedOffer.consumer_segment) {
          yield put(updateSteps(2));
        }

        if (selectedOffer && selectedOffer.buying_moment) {
          const buyingMoments = selectedOffer.buying_moment;
          if ((buyingMoments && buyingMoments[0])) {
            const { day, date, event } = buyingMoments[0];
            if (day || date || event) {
              yield put(updateSteps(3));
            }
          }
        }
        yield put ({ type: types.UPDATE_SELECTED_OFFER, Offer: selectedOffer });

        // if (offer.origin === 'marketplace' && offer.slug) {
        //   yield put ({
        //     type: GET_MP_OFFER_BY_SLUG_API_CALL_REQUEST,
        //     slug: offer.slug,
        //     updateSelectedOfferFields: ['dateLimit']
        //   });
        // }
      }

      yield put({
        type: types.GET_OFFER_BY_SLUG_API_CALL_SUCCESS,
        data: dataWithCount
      });

    } else {
      yield put({
        type: types.GET_OFFER_BY_SLUG_API_CALL_FAILURE,
        error: 'Error occured during get offer by slug' });
    }
  } catch (error) {
    yield put({ type: types.GET_OFFER_BY_SLUG_API_CALL_FAILURE, error });
  }
}
