import axios from 'axios';
import { takeEvery, call, put } from 'redux-saga/effects';
import { ToastsStore } from 'react-toasts';
import * as types from './types';
import * as globalTypes from '../../globalTypes';
import config from '../../config/app_config';
import { getClient } from '../../common/utils';

export function* createBuyingMomentsWatcherSaga() {
  yield takeEvery(types.CREATE_BUYING_MOMENTS_API_CALL_REQUEST, workerSaga);
}

export function fetchData(data) {
  const client = getClient();

  delete data.id;

  const url = `${config.API_ROOT_URL}/v1/offer/${client}/buying-moment/`;

  return axios.post(url, data)
    .then(val => val)
    .catch(err => err.response);
}

export function* workerSaga(action) {
  try {
    const { data } = action;
    const response = yield call(fetchData, data);

    if (response && response.status === 201) {
      const { data } = response;
      yield put({ type: types.CREATE_BUYING_MOMENTS_API_CALL_SUCCESS, data });

      ToastsStore.success('Buying moment has been created successfully.');

      yield put({ type: globalTypes.UPDATE_SIDE_DRAWER_STATE, open: false });
      yield put({ type: types.RESET_BUYING_MOMENT_TO_DEFAULT });
    } else {
      yield put({ type: types.CREATE_BUYING_MOMENTS_API_CALL_FAILURE, error: response.data });
    }
  } catch (error) {
    yield put({ type: types.CREATE_BUYING_MOMENTS_API_CALL_FAILURE, error });
  }
}
