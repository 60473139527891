import axios from 'axios';
import { ToastsStore } from 'react-toasts';
import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from '../types';
import config from '../../../config/app_config';
import { getClient } from '../../../common/utils';

export function* addClientUsersWatcherSaga() {
  yield takeLatest(types.ADD_CLIENT_USER_REQUEST, workerSaga);
}

export function postData(data) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/client/${client}/users/`;


  return axios.post(url, data)
    .then(val => val)
    .catch(error => error.response);
}

export function* workerSaga(action) {
  try {
    const { data } = action;
    const response = yield call(postData, data);

    if (response && response.status === 201) {
      ToastsStore.success(response.data);
      yield put({
        type: types.ADD_CLIENT_USER_SUCCESS,
      });
      yield put({
        type: types.FETCH_CLIENT_USERS_REQUEST,
      });
    } else {
      yield put({ type: types.ADD_CLIENT_USER_FAILURE });
    }
  } catch (error) {
    yield put({ type: types.ADD_CLIENT_USER_FAILURE, error });
  }
}
