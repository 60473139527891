import axios from 'axios';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { getClient, addCountProperty, getCustomerSlugs } from '../../common/utils';

export function* searchCustomerSegmentsWatcherSaga() {
  yield takeLatest(types.SEARCH_CUSTOMER_SEGMENTS_API_CALL_REQUEST, workerSaga);
}

export function fetchData(page, search) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/consumer/${client}/consumer-segment/`;
  const params = {page, search};

  return axios.get(url, { params})
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga({ page, search }) {
  try {
    const state = yield select();
    const pages = state.customerSegments.toJS().customerSegments.searchResultPages;

    if (page && pages[page]) {
      // we already got this data so don't get it again
      yield put({ type: types.SEARCH_CUSTOMER_SEGMENTS_API_CALL_CANCELLED });

      // but we may still need to just get the count
      const pageItems = pages[page];
      const haveNotGotCountYet = pageItems[0].count === undefined;
      if (haveNotGotCountYet) {
        const slugs = getCustomerSlugs(pageItems);
        yield put({ type: types.INDIVIDUAL_CUSTOMER_SEGMENT_COUNT_API_CALL_REQUEST, slugs });
      }
      return;
    }

    const response = yield call(fetchData, page, search);
    if (response && response.status === 200) {
      const { data } = response;
      data['page'] = page;

      data.results = addCountProperty(data.results);

      yield put({ type: types.SEARCH_CUSTOMER_SEGMENTS_API_CALL_SUCCESS, data });

      if (data.results.length !== 0) {
        const firstSlugInResults = data.results[0];

        let haveNotGotCountYet = false;
        if (!firstSlugInResults || firstSlugInResults.count === undefined) {
          haveNotGotCountYet = true;
        }
        if (haveNotGotCountYet) {
          const slugs = getCustomerSlugs(data.results);
          yield put({ type: types.INDIVIDUAL_CUSTOMER_SEGMENT_COUNT_API_CALL_REQUEST, slugs });
        }
      }


    } else {
      yield put({ type: types.SEARCH_CUSTOMER_SEGMENTS_API_CALL_FAILURE, error: 'error occured during search customer segments' });
    }
  } catch (error) {
    yield put({ type: types.SEARCH_CUSTOMER_SEGMENTS_API_CALL_FAILURE, error });
  }
}
