import axios from 'axios';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { getClient } from '../../common/utils';

export function* getChannelsWatcherSaga() {
  yield takeLatest(types.GET_CHANNELS_API_CALL_REQUEST, workerSaga);
}

export function fetchData() {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/client/${client}/channels/`;

  return axios.get(url, {})
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga() {
  try {

    const state = yield select();
    const channels = state.offers.toJS().channels;

    if (channels && channels.length !== 0) {
      // we already got this data so don't get it again
      yield put({ type: types.GET_CHANNELS_API_CALL_CANCELLED });
      return;
    }

    const response = yield call(fetchData);

    if (response && response.status === 200) {
      const { data } = response;

      yield put({ type: types.GET_CHANNELS_API_CALL_SUCCESS, data });

    } else {
      yield put({ type: types.GET_CHANNELS_API_CALL_FAILURE, error: 'error occured during get offer' });
    }
  } catch (error) {
    yield put({ type: types.GET_CHANNELS_API_CALL_FAILURE, error });
  }
}
