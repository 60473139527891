// @flow

import React from 'react';

import './RightSideDrawer.scss';

type Props = {
  open: boolean,
  children?: any,
}

const RightSideDrawer = (props: Props) => {
  const rightSideDrawerStyle = props.open ? 'right-side-drawer-open' : 'right-side-drawer-close';

  return (
    <div>
      <div className={rightSideDrawerStyle}>
        {props.children}
      </div>
      {
        props.open &&
        <div className="right-side-drawer-model-bg">
        </div>
      }
    </div>
  );
};

export default RightSideDrawer;
