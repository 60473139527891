import axios from 'axios';
import { takeEvery, call, put } from 'redux-saga/effects';
import { ToastsStore } from 'react-toasts';
import * as types from './types';

import config from '../../config/app_config';
import { getClient } from '../../common/utils';
import { ERROR_DELETE_TEMPLATE, LP_DELETED_SUCCESSFULLY } from '../../common/i18n';

export function* deleteLandingPageTemplatesWatcherSaga() {
  yield takeEvery(types.DELETE_LP_TEMPLATE_API_CALL_REQUEST, workerSaga);
}

export function deleteData(templateSlug) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/client/${client}/templates/${templateSlug}/`;

  return axios.delete(url, {})
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga(action) {
  try {
    const { templateSlug } = action;
    const response = yield call(deleteData, templateSlug);

    if (response && response.status >= 200 && response.status <= 204) {
      yield put({ type: types.DELETE_LP_TEMPLATE_API_CALL_SUCCESS, 'templateSlug': templateSlug });
      ToastsStore.success(LP_DELETED_SUCCESSFULLY);
    } else {
      yield put({ type: types.DELETE_LP_TEMPLATE_API_CALL_FAILURE, error: ERROR_DELETE_TEMPLATE });
    }
  } catch (error) {
    yield put({ type: types.DELETE_LP_TEMPLATE_API_CALL_FAILURE, error });
  }
}
