import { takeEvery, call, put } from 'redux-saga/effects';

import * as types from './types';
import { ERROR_GET_METRICS } from '../../common/i18n';
import { fetchPartnerConversionMetricsData } from './service';

export function* getConversionMetricsWatcherSaga() {
  yield takeEvery(types.GET_PARTNER_CONVERSION_REQUEST, workerSaga);
}

export function* workerSaga({ entityType, metricType, selectedDateRange }) {
  try {
    const response = yield call(fetchPartnerConversionMetricsData, entityType, metricType, selectedDateRange);

    if (response && response.status === 200) {
      const { data } = response;

      yield put({ type: types.GET_PARTNER_CONVERSION_SUCCESS, data, entityType, metricType});

    } else {
      yield put({ type: types.GET_PARTNER_CONVERSION_FAILURE, entityType, metricType, error: ERROR_GET_METRICS });
    }
  } catch (error) {
    yield put({ type: types.GET_PARTNER_CONVERSION_FAILURE, entityType, metricType, error });
  }
}
