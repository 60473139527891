import React from 'react';
import { Form, ButtonToolbar, Button } from 'react-bootstrap';
import Link from '../../../../components/Link/Link';

import {
  FORGOT_PASSWORD,
  EMAIL,
  REQUEST_RESET_LINK,
  LOGIN,
  FORGOT_PASSWORD_CLASS,
  REQUESTING,
} from '../../../../common/i18n';
import { Props, State } from './flowTypes';
import './style.scss';
import {FormField} from "../../../../components/FormField.new";

class ForgotPassword extends React.PureComponent<Props, State> {
  render() {
    const {
      resetpasswordlinksent,
    } = this.props;

    return resetpasswordlinksent ?
      this.renderConfirmation() : this.renderForgotPasswordForm();
  }

  ///////////////////////////////////////
  ///////////RENDER METHODS/////////////
  //////////////////////////////////////
  renderConfirmation = () => {
    const {
      user: {
        email
      },
      history,
      resetPasswordResetRequest,
    } = this.props;
    return (
      <div className='forgot-password-form'>
          <h1 className="forgot-password-form-title">{FORGOT_PASSWORD}</h1>
        <div className="forgot-password-form-confirmation">
          A link to help you reset your password has been sent to {email}
        </div>
          <ButtonToolbar className="forgot-password-form-btn-container">
        <Button
          className="action-button"
          style={{'width': '150px'}}
          onClick={() => {
            history.push('/login');
            resetPasswordResetRequest();
          }}
        >
          Back to Login
        </Button>
          </ButtonToolbar>
      </div>
    );
  }

  renderForgotPasswordForm = () => {
    const {
      history,
      user,
      isEmailValid,
      onLoginFieldsInput,
      validateEmail,
      onSubmit,
      error,
      loading,
    } = this.props;
    return (
      <Form
        className={'forgot-password-form'}
        onSubmit={() => null}
      >
          <h1 className="forgot-password-form-title">{FORGOT_PASSWORD}</h1>
          <FormField
          label={EMAIL}
          controlId={FORGOT_PASSWORD_CLASS}
          type='email'
          name='email'
          value={user.email}
          placeholder='sadie.boone@gmail.com'
          required={true}
          onChange={(e) => {
              console.log(e);
            onLoginFieldsInput(e);
            validateEmail(e);
          }}
          onBlur={validateEmail}
          error={error?.email}
        />
        <ButtonToolbar className="forgot-password-form-btn-container">
          <Button
            variant="primary"
            type="submit"
            className="action-button"
            disabled={!isEmailValid || !user.email}
            onClick={(event) => {
              event.preventDefault();
              if (loading) {
                return;
              }
              onSubmit(event);
            }}
          >
            {loading ? REQUESTING : REQUEST_RESET_LINK}
          </Button>
        </ButtonToolbar>
          <Link
              className="forgot-password-form-login-link"
              variant='secondary-link'
              onClick={() => history.push('/login')}
          >
              {LOGIN}
          </Link>
      </Form>
    );
  }
}

export default ForgotPassword;
