import axios from 'axios';
import { ToastsStore } from 'react-toasts';
import { takeLatest, call, put } from 'redux-saga/effects';

import * as types from './types';
import config from '../../config/app_config';
import { getClient } from '../../common/utils';

export function* uploadConsumerDataFileWatcherSaga() {
  yield takeLatest(types.UPLOAD_CONSUMER_DATA_FILE_REQUEST, workerSaga);
}

export function getS3PresignedURL(filename) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/client/${client}/generate_csv_presigned_url/consumers-data/?file_name=${filename}`;

  return axios.get(url, {})
    .then(val => val)
    .catch(err => err);
}

export function uploadDataFile(url, fileData) {

  return fetch(url, {
    'method': 'POST',
    'body': fileData
  })
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga({filename, file, callback}) {
  const presignedResponse = yield call(getS3PresignedURL, filename);
  if (presignedResponse && presignedResponse.status === 200) {
    const { data: {csv_url} } = presignedResponse;

    let fileData = new FormData();
    for (let field of Object.keys(csv_url.fields)) {
      fileData.append(field, csv_url.fields[field]);
    }

    fileData.append('file', file, filename);

    const response = yield call(uploadDataFile, csv_url.url, fileData);
    if (response && response.status === 204) {
      yield put({ type: types.UPLOAD_CONSUMER_DATA_FILE_SUCCESS });
      ToastsStore.success('File Uploaded successfully.');
      callback();
    } else {
      yield put({ type: types.UPLOAD_CONSUMER_DATA_FILE_FAILURE });
      ToastsStore.error('File Uploaded failed.');
    }
  }
}
