import axios from 'axios';
import { ToastsStore } from 'react-toasts';
import { takeEvery, call, put } from 'redux-saga/effects';
import * as types from './types';
import * as globalTypes from '../../globalTypes';
import config from '../../config/app_config';
import { getClient } from '../../common/utils';

export function* createCustomerSegmentsWatcherSaga() {
  yield takeEvery(types.CREATE_CUSTOMER_SEGMENTS_API_CALL_REQUEST, workerSaga);
}
export function fetchData(data) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/consumer/${client}/consumer-segment/`;

  return axios.post(url, data)
    .then(val => val)
    .catch(err => err.response);
}

export function* workerSaga(action) {
  try {
    const { data } = action;
    const response = yield call(fetchData, data);

    if (response && response.status === 201) {
      const { data } = response;
      yield put({ type: types.CREATE_CUSTOMER_SEGMENTS_API_CALL_SUCCESS, data });

      if (data && data.slug) {
        yield put({ type: types.INDIVIDUAL_CUSTOMER_SEGMENT_COUNT_API_CALL_REQUEST, 'slugs': [data.slug] });
      }

      ToastsStore.success('Customer segment has been created successfully.');

      yield put({ type: globalTypes.UPDATE_SIDE_DRAWER_STATE, open: false });
      yield put({ type: types.RESET_SELECTED_CUSTOMER_SEGMENT_TO_DEFAULT });
    } else {
      yield put({ type: types.CREATE_CUSTOMER_SEGMENTS_API_CALL_FAILURE, error: response.data });
    }
  } catch (error) {
    yield put({ type: types.CREATE_CUSTOMER_SEGMENTS_API_CALL_FAILURE, error });
  }
}
