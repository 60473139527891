import {
  NEW_PASSWORD,
  CONFIRM_PASSWORD,
  ENTER_PASSWORD,
} from '../../../../common/i18n';

import {
  changeToLowercase,
} from '../../../../common/utils';

export const password = {
  [changeToLowercase(NEW_PASSWORD)]: {
    label: NEW_PASSWORD,
    controlId: changeToLowercase(NEW_PASSWORD),
    fieldClassName: changeToLowercase(NEW_PASSWORD),
    type: 'password',
    name: 'new_password',
    value: changeToLowercase(NEW_PASSWORD),
    placeholder: ENTER_PASSWORD,
    onFieldsInput: () => null,
    error: null,
    disabled: false
  },
  [changeToLowercase(CONFIRM_PASSWORD)]: {
    label: CONFIRM_PASSWORD,
    controlId: changeToLowercase(CONFIRM_PASSWORD),
    fieldClassName: changeToLowercase(CONFIRM_PASSWORD),
    type: 'password',
    name: 'confirm_password',
    value: changeToLowercase(CONFIRM_PASSWORD),
    placeholder: ENTER_PASSWORD,
    onFieldsInput: () => null,
    error: null,
    disabled: false
  },
};
