import axios from 'axios';
import { takeEvery, call, put, select } from 'redux-saga/effects';
import * as types from './types';
import * as segmentTypes from './../CustomerSegments/types';
import offerUrls from './offerUrls';

export function* patchUpdateOfferWatcherSaga() {
  yield takeEvery(types.PATCH_UPDATE_OFFER_API_CALL_REQUEST, workerSaga);
}

export function patchData(data, slug, user) {

  const url = `${offerUrls('patchData', user)}${slug}/`;

  return axios.patch(url, data)
    .then(val => val)
    .catch(err => err.response);
}

export function* workerSaga(action) {
  try {
    const { data, updateStep, saveAndQuit, callback } = action;
    const state = yield select();
    const user = state.login.toJS().user;
    const activeStep = state.offers.toJS().activeStep;
    const slug = (data && data.slug) ||
      (state.offers.toJS().selectedOffer.slug);
    const response = yield call(patchData, data, slug, user);

    if (response && response.status === 200) {
      data.slug = response.data.slug;

      if (data.channels) {
        data.channels = response.data.channels;
      }

      if (saveAndQuit) {
        // TODO can we do this in some better way ?
        data['saveAndQuit'] = true;
      }

      // Call callback first before calling reducer
      if (callback) {
        callback();
      }

      yield put({ type: types.PATCH_UPDATE_OFFER_API_CALL_SUCCESS, data });

      // Reset Customer segment
      if (data.is_published) {
        yield put({ type: segmentTypes.RESET_CUSTOMER_SEGMENTS });
      }

      if (updateStep) {
        yield put({
          type: types.UPDATE_ACTIVE_STEP,
          activeStep: activeStep + 1
        });
        yield put({ type: types.UPDATE_STEPS, activeStep });
      }
    } else {
      yield put({
        type: types.PATCH_UPDATE_OFFER_API_CALL_FAILURE,
        error: response && response.data
      });
    }
  } catch (error) {
    yield put({
      'type': types.PATCH_UPDATE_OFFER_API_CALL_FAILURE,
      'error': error && error.data
    });
  }
}
