import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from './types';
import { UPDATE_SELECTED_OFFER } from '../Offers/types';
import config from '../../config/app_config';
import { getClient, mapOfferResponceToSelectedCampaign } from '../../common/utils';

export function* getMarketplaceOfferBySlugWatcherSaga() {
  yield takeLatest(types.GET_MP_OFFER_BY_SLUG_API_CALL_REQUEST, workerSaga);
}

export function fetchDataBySlug(slug) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/offer/${client}/marketplace/${slug}/`;

  return axios.get(url)
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga({ slug, updateSelectedOfferFields }) {
  try {
    const response = yield call(fetchDataBySlug, slug);

    if (response && response.status === 200) {
      const { data } = response;

      yield put({ type: types.GET_MP_OFFER_BY_SLUG_API_CALL_SUCCESS, data });

      const selectedCampaign = mapOfferResponceToSelectedCampaign(data);

      yield put ({ type: UPDATE_SELECTED_OFFER, Offer: selectedCampaign, updateSelectedOfferFields });

    } else {
      yield put({ type: types.GET_MP_OFFER_BY_SLUG_API_CALL_FAILURE, error: 'Error occured during get offer by slug' });
    }
  } catch (error) {
    yield put({ type: types.GET_MP_OFFER_BY_SLUG_API_CALL_FAILURE, error });
  }
}
