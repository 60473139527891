import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { ERROR_GET_MP_OFFERS } from '../../common/i18n';
import {getClient} from '../../common/utils';

export function* getPerkServiceWatcherSaga() {
  yield takeLatest(types.GET_PERK_SERVICE_CALL_REQUEST, workerSaga);
}

export function fetchData() {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/offer/${client}/perkservice/`;

  return axios.get(url)
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga() {
  try {
    const response = yield call(fetchData);

    if (response && response.status === 200) {
      const { data } = response;

      yield put({ type: types.GET_PERK_SERVICE_CALL_SUCCESS, data });

    } else {
      yield put({ type: types.GET_PERK_SERVICE_CALL_FAILURE, error: ERROR_GET_MP_OFFERS});
    }
  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_PERK_SERVICE_CALL_FAILURE, error });
  }
}
