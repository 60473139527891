import { checkNavigationPermission } from './helpers';
import {
  ALL_PERMISSION,
  ANCILLARY_API,
  ANCILLARY_PERMISSION,
  BOOST_CAMPAIGNS,
  BUYING_MOMENT,
  CAMPAIGN_DESCRIPTION,
  CAMPAIGN_PERMISSION,
  CAMPAIGNS,
  CREATE_CAMPAIGN,
  CUSTOMER_SEGMENT,
  DASHBOARD,
  LOGOUT,
  MARKETPLACE,
  OFFER_DESCRIPTION,
  OFFERS,
  PERFORMANCE,
  PERKS,
  PERKS_PERMISSION,
  SETTINGS,
    ADMIN,
  SUPPORT
} from '../../common/i18n';

import BarChart from "../../icons/bar-chart.svg";
import Marketplace from "../../icons/marketplace.svg";
import PerkPageBuilder from "../../icons/perk-page-builder.svg";
import Campaigns from "../../icons/messaging-and-campaigns.svg";
import Api from "../../icons/api-cloud.svg";
import Settings from "../../icons/settings.svg";
import BoostCampaigns from "../../icons/boost-campaigns.svg";
import Support from "../../icons/support.svg";
import Admin from "../../icons/admin.svg";

// List of all navigation details (name, link, icon, canShow (display) for ARM's
export function getARMNavigationDetails() {
  return [
    {
      'name': DASHBOARD,
      'className': 'list-item-wrapper',
      'linkTo': '/dashboard',
      'iconClassName': 'icon-dashboard list-item-icons',
      'icon': BarChart,
      'display': checkNavigationPermission(DASHBOARD)
    },
    {
      'name': CAMPAIGNS,
      'className': 'list-item-wrapper',
      'linkTo': '/offers',
      'iconClassName': 'icon-offer list-item-icons sm-size-icon',
      'icon': Campaigns,
      'display': checkNavigationPermission(CAMPAIGNS)
    },
    {
      'name': MARKETPLACE,
      'className': 'list-item-wrapper',
      'linkTo': '/marketplace',
      'iconClassName': 'icon-marketplace list-item-icons sm-size-icon',
      'icon': Marketplace,
      'display': checkNavigationPermission(MARKETPLACE)
    },
    {
      'name': PERKS,
      'className': 'list-item-wrapper',
      'linkTo': '/perk-collections',
      'iconClassName': 'icon-perks-star list-item-icons sm-size-icon',
      'icon': PerkPageBuilder,
      'display': checkNavigationPermission(PERKS)
    },
    {
      name: BOOST_CAMPAIGNS,
      className: 'list-item-wrapper',
      linkTo: 'https://app.paylode.com/v2/boost-campaigns',
      icon: BoostCampaigns,
      display: true,
    },
    {
      'name': ANCILLARY_API,
      'className': 'list-item-wrapper',
      'linkTo': '/ancillary-api-details',
      'iconClassName': 'icon-api-icon list-item-icons sm-size-icon',
      'icon': Api,
      'display': checkNavigationPermission(ANCILLARY_API)
    },
    {
      'name': OFFERS,
      'className': 'list-item-wrapper',
      'linkTo': '/landing-pages',
      'iconClassName': 'icon-landingpage list-item-icons sm-size-icon',
      'display': checkNavigationPermission(OFFERS)
    },
    {
      'name': CUSTOMER_SEGMENT,
      'className': 'list-item-wrapper',
      'linkTo': '/customer-segments',
      'iconClassName': 'icon-members list-item-icons lg-size-icon',
      'display': checkNavigationPermission(CUSTOMER_SEGMENT)
    },
    {
      'name': BUYING_MOMENT,
      'className': 'list-item-wrapper',
      'linkTo': '/buying-moments',
      'iconClassName': 'icon-buying-moment list-item-icons',
      'display': checkNavigationPermission(BUYING_MOMENT)
    },
  ];
}

// List of all navigation details (name, link, icon) for PRM's
export function getPRMNavigationDetails() {
  return [
    {
      'name': OFFERS,
      'className': 'list-item-wrapper',
      'linkTo': '/offers',
      'icon': Campaigns,
      'iconClassName': 'icon-marketplace list-item-icons',
    },
    {
      'name': PERFORMANCE,
      'className': 'list-item-wrapper',
      'linkTo': '/performance',
      'iconClassName': 'icon-reports list-item-icons',
    },
    {
      'name': PERKS,
      'className': 'list-item-wrapper',
      'linkTo': '/perk-collections',
      'iconClassName': 'icon-perks-star list-item-icons sm-size-icon',
      'icon': PerkPageBuilder,
      'display': checkNavigationPermission(PERKS)
    },
  ];
}

// List of all common navigation details (for ARM's and PRM's)
export function getCommonNavigationDetails() {
  return [
    {
      'name': SUPPORT,
      'className': 'list-item-wrapper',
      'linkTo': 'https://paylode.atlassian.net/wiki/spaces/PAKB/overview',
      'iconClassName': 'list-item-icons',
      'icon': Support,
    },
    {
      'name': ADMIN,
      'className': 'list-item-wrapper',
      'linkTo': '/settings',
      'iconClassName': 'list-item-icons',
      'icon': Admin,
    }
  ];
}

// Mapping between navigation menu and permission
export const NAVIGATION_PERMISSION_MAPPING = {
  [DASHBOARD]: ALL_PERMISSION,
  [OFFERS]: [CAMPAIGN_PERMISSION],
  [CUSTOMER_SEGMENT]: [CAMPAIGN_PERMISSION],
  [BUYING_MOMENT]: [CAMPAIGN_PERMISSION],
  [CAMPAIGNS]: [CAMPAIGN_PERMISSION],
  [CREATE_CAMPAIGN]: [CAMPAIGN_PERMISSION],
  [CAMPAIGN_DESCRIPTION]: [CAMPAIGN_PERMISSION],
  [PERKS]: [PERKS_PERMISSION],
  [ANCILLARY_API]: [ANCILLARY_PERMISSION],
  [MARKETPLACE]: ALL_PERMISSION,
  [SETTINGS]: ALL_PERMISSION,
  [LOGOUT]: ALL_PERMISSION,
  [OFFER_DESCRIPTION]: ALL_PERMISSION,
};
