import { combineReducers } from 'redux';

import app from './features/App/reducer';
import login from './features/Login/reducer';
import buyingMoments from './features/BuyingMoments/reducer';
import customerSegments from './features/CustomerSegments/reducer';
import offers from './features/Offers/reducer';
import templates from './features/LandingPages/reducer';
import metrics from './features/Dashboard/reducers';
import marketplaceoffers from './features/Marketplace/reducer';
import perks from './features/Perks/reducer';
import ancillaryAPIDetails from './features/AncillaryAPIPage/reducer';
import setting from './features/Settings/reducer';

import * as globalTypes from './globalTypes';

const appReducer = combineReducers({
  app,
  login,
  buyingMoments,
  customerSegments,
  offers,
  templates,
  metrics,
  perks,
  marketplaceoffers,
  ancillaryAPIDetails,
  setting,
});

/**
 * Clearing redux storing on logout
 */
const rootReducer = (state, action) => {
  if (action.type === globalTypes.CLEAR_REDUX_STORE) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
