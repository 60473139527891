export const GET_LP_TEMPLATES_API_CALL_REQUEST = 'GET_LP_TEMPLATES_API_CALL_REQUEST';
export const GET_LP_TEMPLATES_API_CALL_SUCCESS = 'GET_LP_TEMPLATES_API_CALL_SUCCESS';
export const GET_LP_TEMPLATES_API_CALL_FAILURE = 'GET_LP_TEMPLATES_API_CALL_FAILURE';

export const UPLOAD_LP_TEMPLATE_API_CALL_REQUEST = 'UPLOAD_LP_TEMPLATE_API_CALL_REQUEST';
export const UPLOAD_LP_TEMPLATE_API_CALL_SUCCESS = 'UPLOAD_LP_TEMPLATE_API_CALL_SUCCESS';
export const UPLOAD_LP_TEMPLATE_API_CALL_FAILURE = 'UPLOAD_LP_TEMPLATE_API_CALL_FAILURE';

export const DELETE_LP_TEMPLATE_API_CALL_REQUEST = 'DELETE_LP_TEMPLATE_API_CALL_REQUEST';
export const DELETE_LP_TEMPLATE_API_CALL_SUCCESS = 'DELETE_LP_TEMPLATE_API_CALL_SUCCESS';
export const DELETE_LP_TEMPLATE_API_CALL_FAILURE = 'DELETE_LP_TEMPLATE_API_CALL_FAILURE';

export const CREATE_LP_TEMPLATE_API_CALL_REQUEST = 'CREATE_LP_TEMPLATE_API_CALL_REQUEST';
export const CREATE_LP_TEMPLATE_API_CALL_SUCCESS = 'CREATE_LP_TEMPLATE_API_CALL_SUCCESS';
export const CREATE_LP_TEMPLATE_API_CALL_FAILURE = 'CREATE_LP_TEMPLATE_API_CALL_FAILURE';
