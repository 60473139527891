import axios from 'axios';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import { getClient } from '../../common/utils';
import { TEMPLATE_TYPE } from './constants';

export function* getTemplatesWatcherSaga() {
  yield takeLatest(types.GET_TEMPLATES_API_CALL_REQUEST, workerSaga);
}

export function fetchData(template_type) {
  const client = getClient();
  const url = `${config.API_ROOT_URL}/v1/client/${client}/templates/?template_type=${template_type}`;

  return axios.get(url, {})
    .then(val => val)
    .catch(err => err);
}

export function* workerSaga({ setRecentToSelectedOffer }) {
  try {
    const response = yield call(fetchData, TEMPLATE_TYPE);

    if (response && response.status === 200) {
      const { data } = response;

      if(setRecentToSelectedOffer) {
        const state = yield select();
        const { items } = state.offers.toJS().templates;
        const { channels } = state.offers.toJS().selectedOffer;

        // Check if set to recent is required
        // only if new template create is success
        if (items.length < data.length) {
          const firstTemplate = data.find(ele => ele.source === 'custom');
          yield put ({
            type: types.UPDATE_SELECTED_OFFER,
            Offer: {
              'channels': channels.map(ele => {
                ele.template = firstTemplate;
                return ele;
              }),
              'landing_page': data.find(ele => ele.source === 'custom')
            }
          });
        }
      }

      yield put({ type: types.GET_TEMPLATES_API_CALL_SUCCESS, data });

    } else {
      yield put({ type: types.GET_TEMPLATES_API_CALL_FAILURE, error: 'error occured during get offer' });
    }
  } catch (error) {
    yield put({ type: types.GET_TEMPLATES_API_CALL_FAILURE, error });
  }
}
