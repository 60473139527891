import { takeEvery, call } from 'redux-saga/effects';
import * as types from './types';


export function* requestDemoWatcherSaga() {
  yield takeEvery(types.REQUEST_DEMO_INVITATION, workerSaga);

}

export function postData(data) {
  const url = 'https://hooks.slack.com/services/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';

  return fetch(url, {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      console.log('Success:', res);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}

export function* workerSaga(action) {
  try {
    const { email } = action;
    const response = yield call(postData, {'text': `Demo request from ${email}`});
    if (response && response.status === 200) {
      // Dispatch success actions
    }
  } catch (error) {
    console.log('Error:(Post Demo Request) ', error);
  }
}
