export const GET_MP_OFFERS_CALL_REQUEST = 'GET_MP_OFFERS_CALL_REQUEST';
export const GET_MP_OFFERS_CALL_SUCCESS = 'GET_MP_OFFERS_CALL_SUCCESS';
export const GET_MP_OFFERS_CALL_FAILURE = 'GET_MP_OFFERS_CALL_FAILURE';

export const GET_MP_OFFER_BY_SLUG_API_CALL_REQUEST = 'GET_MP_OFFER_BY_SLUG_API_CALL_REQUEST';
export const GET_MP_OFFER_BY_SLUG_API_CALL_SUCCESS = 'GET_MP_OFFER_BY_SLUG_API_CALL_SUCCESS';
export const GET_MP_OFFER_BY_SLUG_API_CALL_FAILURE = 'GET_MP_OFFER_BY_SLUG_API_CALL_FAILURE';

export const GET_MP_OFFERS_LOCATIONS_REQUEST = 'GET_MP_OFFERS_LOCATIONS_REQUEST';
export const GET_MP_OFFERS_LOCATIONS_SUCCESS = 'GET_MP_OFFERS_LOCATIONS_SUCCESS';
export const GET_MP_OFFERS_LOCATIONS_FAILURE = 'GET_MP_OFFERS_LOCATIONS_FAILURE';

export const GET_OFFER_LOCATIONS = 'GET_OFFER_LOCATIONS';
export const GET_OFFER_INDUSTRIES = 'GET_OFFER_INDUSTRIES';
export const GET_OFFER_METRICS = 'GET_OFFER_METRICS';

export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS';
