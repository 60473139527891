import axios from 'axios';
import { takeEvery, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';


export function* changePasswordWatcherSaga() {
  yield takeEvery(types.CHANGE_PASSWORD_REQUEST, workerSaga);
}

export function postData(data) {
  const url = `${config.API_ROOT_URL}/v1/auth/password/change/`;

  return axios.post(url, data)
    .then(val => val)
    .catch(error => error.response);
}

export function* workerSaga(action) {
  try {
    const { data, callback } = action;
    const response = yield call(postData, data);

    if (response && response.status === 200) {
      // ToastsStore.success(response.data.message);
      yield put({
        type: types.CHANGE_PASSWORD_SUCCESS,
      });
    } else {
      yield put({ type: types.CHANGE_PASSWORD_FAILURE, error: response.data});
    }
    callback();
  } catch (error) {
    yield put({ type: types.CHANGE_PASSWORD_FAILURE, error });
  }
}
