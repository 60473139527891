import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from './types';
import config from '../../config/app_config';
import {getClient, getClientData} from '../../common/utils';

export function* getClientEntitiesWatcherSaga() {
  yield takeLatest(types.GET_CLIENT_ENTITIES_REQUEST, workerSaga);
}

export function fetchData() {
  const client = getClient();
  const entitiesUrl = `${config.API_ROOT_URL}/v1/client/${client}/entities/`;
  const perksCentersUrl = `${config.PERKS_CENTERS_API}/perks-centers`;

  const clientData = getClientData();
  const { apiToken } = clientData;

  return Promise.all([axios.get(entitiesUrl), axios.get(perksCentersUrl, {
      headers: {
        'X-Api-Key': apiToken
      },
      params: {
        limit: 10000
      }
  })])
      .then(([entitiesResponse, perksCentersResponse]) => {
        const { data: entitiesData } = entitiesResponse;
        const { data: perksCentersData } = perksCentersResponse;
        const parsedEntities = entitiesData.map(entity => ({
          ...entity,
          id: perksCentersData?.perks_centers?.find(perkCenter => perkCenter.slug === entity.slug)?.id
        }));

        console.log(entitiesData, perksCentersResponse, parsedEntities);

        return ({
            ...entitiesResponse,
            data: parsedEntities
        })
      })
      .catch(err => err);
}

export function* workerSaga() {
  try {
    const response = yield call(fetchData);

    if (response && response.status === 200) {
      const { data } = response;
      yield put({ type: types.GET_CLIENT_ENTITIES_SUCCESS, data });
      // Check for zero entities
      if (data?.length > 0) {
        yield put({ type: types.GET_PERKS_CALL_REQUEST, entitySlug: data[0].slug });
      }
    } else {
      yield put({ type: types.GET_CLIENT_ENTITIES_FAILED, error: 'Failed'});
    }
  } catch (error) {
    console.log(error)
    yield put({ type: types.GET_CLIENT_ENTITIES_FAILED, error });
  }
}
