import React from 'react';

import './style.scss';

const PageSubHeading = (props: Props) => (
  <div className={`page-subheading ${props.className}`}>
    {props.headerText}
  </div>
);

export default React.memo(PageSubHeading);
