import { fromJS } from 'immutable';
import { cloneDeep } from '../../common/utils';
import { SPECIFIC_DAY } from './constants';


export const defaultBuyingMoment = {
  client: '',
  name: '',
  description: '',
  id: '',
  type: SPECIFIC_DAY,
  customerdate: '',
  customeraction: '',
  attributes: [
    { name: '', type: '' }
  ]
};

export const initialState = fromJS({
  createAndUpdateError: false,
  deleteError: false,
  fetching: false,
  error: false,
  buyingMoments: {
    items: [],
    total: 0,
    pages: {},
    activePage: 1,
  },
  days: [],
  actions: [],
  selectedBuyingMoment: cloneDeep(defaultBuyingMoment),
});
